import React from 'react';
import cookie from 'react-cookies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ImageUpload from '../layout/ImageUpload';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ProductPhotoTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business_id: 1,
      isFetching: true,
      data: [],
      isAddingImage: false,
      failedCreation: false
    }
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  fetchDataFromServer = () => {
    this.setState({isFetching: true});
    fetch(BACKEND_URL +  '/businesses/' + this.state.business_id + '/products/' + this.props.id + '/product_photos', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => response.json())
      .then(data => this.setState({ data: data, isFetching: false}))
      .catch(e => console.log(e));
  };

  setAddingImage = () => {
    this.setState({isAddingImage: true})
  };

  unsetAddingImage = () => {
    this.setState({isAddingImage: false})
  };

  deleteImage = (event) => {
    if (window.confirm("Estas removiendo la imagen para este producto. Deseas continuar?")) {
      event.preventDefault();
      const photo_id = event.currentTarget.id;
      fetch(BACKEND_URL +  '/product_photos/' + photo_id, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => window.location = '/productos/' + this.props.id + '/imagenes')
      .catch(e => console.log(e));
    }
  };

  setFailedCreation = () => {
    this.setState({failedCreation: true});
    setTimeout(this.hideFailedCreationMessage, 5000)
  };

  hideFailedCreationMessage = () => {
    this.setState({failedCreation: false})
  };

  render() {
    return(
      <div>
        {this.state.failedCreation === true &&
          <div className="alert alert-danger" role="alert">
            <strong>No se pudo agregar imagen al producto. Error en el sistema.</strong>
          </div>
        }
        { this.state.isAddingImage === true &&
          <ImageUpload unsetAddingImage={() => this.unsetAddingImage()}
                       business_id={this.state.business_id}
                       product_id={this.props.id}
                       setFailedCreation={() => this.setFailedCreation()}/>
        }
        { this.state.isAddingImage === false &&
          <div className="show-header show-product-header">
            <h2>Imagenes del producto:</h2>
            { this.state.isFetching === false && this.state.data['table'].length === 0 &&
              <input onClick={this.setAddingImage.bind(this)} className="float-right" value='+     Agregar imagen' type="submit" />
            }
            { this.state.isFetching === false && this.state.data['table'].length > 0 &&
              <input disabled onClick={this.setAddingImage.bind(this)} className="inactive float-right" value='Agregar imagen' type="submit" />
            }
          </div>
        }
        <div className="mt-50">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>IMAGEN</th>
                <th>NOMBRE</th>
                <th className="text-align-center">ACCION</th>
              </tr>
            </thead>
            <tbody>
              { this.state.isFetching === false && this.state.data['table'].length > 0 &&
                this.state.data['table'].map((obj) =>
                  <tr key={obj.id}>
                    <td>
                      <img className="" alt="foto" src={obj['url']}/>
                    </td>
                    <td>{obj['photo_name']}</td>
                    <td className="text-align-center">
                      <button
                        className="unassign-price-list-button"
                        id={obj['id']}
                        onClick={this.deleteImage}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>

                )
              }
              { this.state.isFetching === false && this.state.data['table'].length === 0 &&
                <tr>
                  <td colSpan="3">No existen imágenes para este producto.</td>
                </tr>
              }
              { this.state.isFetching === true &&
                <tr>
                  <td colSpan="3">Obteniendo datos...</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  };
}
