import React from 'react';

export default class TabWithDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabName: this.props.name,
      activeTab: this.props.activeTab,
      items: this.props.items
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.items, activeTab: nextProps.activeTab, tabName: nextProps.name });
  }

  render() {
    if(this.state.items.length <= 1) {
      return(
        <li className={`${this.state.activeTab === this.state.tabName ? 'active cursor-pointer': 'cursor-pointer'}`}>
          <a id="single-tab" className={this.state.activeTab !== this.state.tabName ? 'inactive-tab cursor-pointer': 'cursor-pointer'}>{this.state.tabName}</a>
        </li>
      )
    } else {
      return(
        <li className="dropdown" className={`${this.state.activeTab === this.state.tabName ? 'active cursor-pointer': 'cursor-pointer'}`}>
          <a className={this.state.activeTab !== this.state.tabName ? 'inactive-tab dropdown-toggle cursor-pointer': 'dropdown-toggle cursor-pointer'} data-toggle="dropdown">
            {this.state.tabName}<span className="caret"></span>
          </a>
          <ul className="dropdown-menu">
            { this.state.items.map((item, index) =>
              <li className="dropdown-tab" key={index}>
                { this.state.tabName == 'Pedido' &&
                  <a id='multiple-tab' className={index}>Pedido #{item.order_db_ref} - Cliente {item.client_db_ref}</a>
                }
                { this.state.tabName == 'Salida' &&
                  <a id='multiple-tab' className={index}>Salida #{item.delivery_db_ref} - Cliente {item.client_db_ref}</a>
                }
                { this.state.tabName == 'Factura' && item.status === 'created' &&
                  <a id='multiple-tab' className={index}>Factura #{item.invoice_db_ref} - Cliente {item.client_db_ref}</a>
                }
                { this.state.tabName == 'Factura' && item.status === 'cancelled' &&
                  <a id='multiple-tab' className={index}>Factura #{item.invoice_db_ref} - Cliente {item.client_db_ref}  (ANULADA)</a>
                }

              </li>
              )
            }
          </ul>
        </li>
      )
    }
  };

}
