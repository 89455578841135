import React from 'react';
import cookie from 'react-cookies';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ShowInvoiceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      invoice_uuid: window.location.href.split('facturas/')[window.location.href.split('facturas/').length -1],
      invoiceDetails: [],
    }
  }

  componentDidMount() {
    this.fetchDetailsFromServer()
  }

  fetchDetailsFromServer = () => {
    this.setState({isFetching: true});
    fetch(BACKEND_URL +  "/invoices/" + this.state.invoice_uuid, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookie.load('token')
      }
      })
      .then(response =>
        {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        }
      )
      .then(data => this.setState({invoiceDetails: data, isFetching: false}))
  };

  getFormattedDate = (createdDate) => {
    let d = new Date(createdDate);
    return d.toLocaleString()
  }

  render() {
    if (this.state.isFetching === false) {
      return(
        <div className="main-view">
          <div className="main-show-header">
            <h2>Factura #{this.state.invoiceDetails.invoice_db_ref}</h2>
          </div>
          <div className="index-table">
            <div className='show-area'>
              <div>
                <div className="order-status"><b>ESTADO:
                  <span className="order-status-invoiced"> FACTURADO</span>
                </b></div>
                <div>
                  <div>
                    <div className="show-product-header show-header">
                      <h2>Detalles de factura:</h2>
                    </div>
                    <hr/>
                    <div>
                      <div className="show-product-header show-header">
                        <div className="order-client-name">{this.state.invoiceDetails['client_name']}</div>
                        <div className="order-client-db_ref">Código: {this.state.invoiceDetails['client_db_ref']}</div>
                      </div>
                      <hr/>
                      <div>
                        <div className="show-data">
                          <div><b>Número de factura:</b></div>
                          <div>{this.state.invoiceDetails['invoice_db_ref']}</div>
                        </div>
                        <div className="show-data">
                          <div><b>Fecha de creación:</b></div>
                          <div>{this.getFormattedDate(this.state.invoiceDetails['created_date'])}</div>
                        </div>
                        <div className="show-data">
                          <div><b>Entregador/Repartidor/Conductor</b></div>
                          <div>{this.state.invoiceDetails['invoicer_name']}</div>
                        </div>
                        <div className="show-data">
                          <div><b>Productos Facturados:</b></div>
                        </div>
                        <div className="index-table">
                          <table className="table table-hover order-table">
                            <thead>
                              <tr>
                                <th>CÓDIGO</th>
                                <th>NOMBRE DEL PRODUCTO</th>
                                <th>CANTIDAD</th>
                                <th>UNIDAD DE PRECIO</th>
                                <th>PESO</th>
                                <th>PRECIO DE EMPAQUE</th>
                                <th>TOTAL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.invoiceDetails['details'].map((model, index) =>
                                <tr key={index}>
                                  <td>{model['product_db_ref']}</td>
                                  <td>{model['product_name']}</td>
                                  <td>{model['quantity']}</td>
                                  <td>{model['price_unit']}</td>
                                  <td>{model['weight']}</td>
                                  <td>${Number(model['unit_price']).toFixed(2)}</td>
                                  <td>${Number(model['extended_price']).toFixed(2)}</td>
                                </tr>
                              )}
                              <tr>
                                <td colSpan="5"></td>
                                <td><b>PESO TOTAL</b></td>
                                <td>{Number(this.state.invoiceDetails['total_weight']).toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td colSpan="5"></td>
                                <td><b>SUB-TOTAL</b></td>
                                <td><b>${Number(this.state.invoiceDetails.sub_total).toFixed(2)}</b></td>
                              </tr>
                              <tr>
                                <td colSpan="5"></td>
                                <td><b>Total ITBMS</b></td>
                                <td><b>${Number(this.state.invoiceDetails.tax_total).toFixed(2)}</b></td>
                              </tr>
                              <tr>
                                <td colSpan="5"></td>
                                <td className="fs-20"><b>TOTAL</b></td>
                                <td className="fs-20"><b>${(parseFloat(this.state.invoiceDetails.tax_total) + parseFloat(this.state.invoiceDetails.sub_total)).toFixed(2)}</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main-view">
          <div className="show-title">
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    }
  };
}
