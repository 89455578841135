import React from 'react';
import cookie from 'react-cookies';
import { DispatchConsumer } from "../context/OrderContext";
import ConvertOrderInput from "../component/protected_component/ConvertOrderInput";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class OrderDetailsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: this.props.orderDetails || [],
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({orderDetails: nextProps.orderDetails});
  }

  getFormattedDate = (createdDate) => {
    let d = new Date(createdDate);
    return d.toLocaleString()
  };

  cancelOrder = () => {
    const response = window.confirm("¿Esta seguro que quiere anular el pedido?");
    if (response === true) {
      fetch(BACKEND_URL + '/orders/' + this.state.orderDetails.uuid, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
        .then(data => window.location = '/pedidos/' + this.props.transactionUuid)
        .catch(e => console.log(e));
    }
  };


  calculateTaxRate = () => {
    let total = 0
    this.state.orderDetails.details.map((obj) => {
      return total += parseFloat(obj.extended_price) * parseFloat(obj.tax_rate)
    });
    return total
  }

  calculateTotal = () => {
    let total = 0
    this.state.orderDetails.details.map((obj) => {
      const calculatedTaxPrice = obj.calculated_tax_price ? obj.calculated_tax_price : obj.extended_price
      return total += parseFloat(calculatedTaxPrice)
    });
    return total
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {this.props.transactionUuid &&
          <>
            <DispatchConsumer>
              {({handleDispatchState, isDispatch}) => (
                <div>
                  <div>
                    <div className="row show-product-header">
                      <div className="col-sm-6">
                        <h3 className="fs-18">Detalles del pedido:</h3>
                      </div>
                      {!isDispatch &&
                      <div className="col-sm-6">
                        {this.state.orderDetails.status === 'created' &&
                        <div className="show-buttons">
                          <ConvertOrderInput
                            handleDispatchState={handleDispatchState}
                            cancelOrder={this.cancelOrder}
                          />
                        </div>
                        }
                      </div>
                      }
                    </div>
                    <hr/>
                  </div>
                </div>
              )}
            </DispatchConsumer>
          </>
          }
          <div>
            {this.state.orderDetails['status'] === 'cancelled' &&
            <div className="anulado-label">ANULADO</div>
            }
            <div className="show-product-header show-header">
              <div className="order-client-name">{this.state.orderDetails['client_name']}</div>
              <div className="order-client-db_ref">Código: {this.state.orderDetails['client_db_ref']}</div>
            </div>
            <hr/>
            <div>
              <div className="show-data">
                <div><b>Número de pedido:</b></div>
                <div>{this.state.orderDetails['order_db_ref']}</div>
              </div>
              <div className="show-data">
                <div><b>Fecha de creación:</b></div>
                <div>{this.getFormattedDate(this.state.orderDetails['created_date'])}</div>
              </div>
              <div className="show-data">
                <div><b>Vendedor</b></div>
                <div>{this.state.orderDetails['salesman_name']} - {this.state.orderDetails['salesman_db_ref']}</div>
              </div>
              <div className="show-data">
                <div><b>Comentarios generales:</b></div>
                <div>{this.state.orderDetails['comments']}</div>
              </div>
              <div className="show-data">
                <div><b>Productos en Pedido:</b></div>
              </div>

              <table className="table table-hover">
                <thead>
                <tr>
                  <th>CÓDIGO</th>
                  <th>NOMBRE DEL PRODUCTO</th>
                  <th>CANTIDAD</th>
                  <th>PESO NETO</th>
                  <th>UNIDAD DE VENTA</th>
                  <th>PRECIO</th>
                  <th>SUBTOTAL</th>
                </tr>
                </thead>
                <tbody>
                {this.state.orderDetails['details'].map((model, index) =>
                  <tr key={index}>
                    <td>{model['product_db_ref']}</td>
                    <td>{model['product_name']}</td>
                    <td>{Number(model['quantity']).toFixed(2)}</td>
                    <td>{Number(model['weight']).toFixed(2)}</td>
                    <td>{model['price_unit']}</td>
                    <td>${Number(model['unit_price']).toFixed(2)}</td>
                    <td>${Number(model['extended_price']).toFixed(2)}</td>
                  </tr>
                )}
                <tr>
                  <td colSpan="5"/>
                  <td><b>PESO TOTAL</b></td>
                  <td>{Number(this.state.orderDetails['total_weight']).toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="5"/>
                  <td><b>SUB-TOTAL</b></td>
                  <td>${Number(this.state.orderDetails['total']).toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="5"/>
                  <td><b>TOTAL ITBMS</b></td>
                  <td>${Number(this.calculateTaxRate()).toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="5"/>
                  <td className="fs-20"><b>TOTAL</b></td>
                  <td className="fs-20"><b>${Number(this.calculateTotal()).toFixed(2)}</b></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  };
}
