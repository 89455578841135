import React, { useContext } from 'react'
import SelectInput from "../../components/inputs/SelectInput";
import { UserFlagCreateContext } from "../context/UserFlagCreateProvider";

const CreateUserConfigDetailStep = () => {

  const {configFormControls, businessData, changeConfigHandler} = useContext(UserFlagCreateContext)

  const isEditingUserConfigTab = true

  const newConfigRow = (title, salesman_flag_value, description, business_flag = true, isEditing = false) => {
    return (
      <tr className={business_flag ? "cursor-pointer-list" : "font-color-disable cursor-pointer-list"}>
        <td className="col-md-2">
          {title}
        </td>
        <td className="col-md-1">
          {
            isEditing ? configUserConfigTemplate(business_flag, salesman_flag_value) :
              configUserInfoTemplate(business_flag, salesman_flag_value)
          }
        </td>
        <td className="col-md-4">
          {description}
        </td>
      </tr>
    )
  }


  const configUserInfoTemplate = (business_flag, salesman_flag_value) => {
    return (
      business_flag ?
        (
          <>
            {salesman_flag_value ? "Si" : "No"}
          </>
        ) :
        <>
          *No Disponible
        </>
    )
  }

  const configUserConfigTemplate = (business_flag = true, salesman_flag_value) => {
    return (
      business_flag ?
        (
          <>
            {salesman_flag_value}
          </>
        ) :
        <>
          *No Disponible
        </>
    )
  }
  return (
    <>
      <div className=''>
        <form>
          <div className="inline-block full-width">
            <div className="float-left">
              <h4>Ingrese las configuraciones del usuario nuevo</h4>
            </div>
          </div>
          <hr/>
          <h5>Actividades</h5>
          <>
            <div className="">
              <table className="table">
                <thead className="list-table">
                <tr className="th-list-table">
                  <th>NOMBRE</th>
                  <th>ACTIVO</th>
                  <th>DESCRIPCIÓN</th>
                </tr>
                </thead>
                <tbody>
                {newConfigRow('Crear pedidos', 'Si', 'El usuario puede crear pedidos a los clientes.', true, isEditingUserConfigTab)}

                {newConfigRow('Crear cotizaciones', businessData.can_make_quotes,
                  'El usuario puede crear cotizaciones a los clientes.', businessData.can_make_quotes)}

                {newConfigRow('Crear devoluciones', <SelectInput name="can_access_refunds_app"
                                                                 value={configFormControls.can_access_refunds_app.value}
                                                                 onChange={changeConfigHandler}
                                                                 options={configFormControls.can_access_refunds_app.options}
                                                                 touched={configFormControls.can_access_refunds_app.touched}
                                                                 formClass="-"
                                                                 valid={configFormControls.can_access_refunds_app.valid}/>,
                  'El usuario puede crear devoluciones a los clientes.', businessData.can_access_refunds_app, isEditingUserConfigTab)}

                {newConfigRow('Crear reportes de visita', businessData.can_report_no_sales, 'El usuario puede escoger una incidencia y enviar un reporte de visita.', businessData.can_report_no_sales)}


                {newConfigRow('Ubicación del cliente', businessData.can_see_client_location, 'El usuario puede dar clic en ubicacion del cliente y utilizar una aplicacion como Google Maps para llegar a la ubicación del cliente.', businessData.can_see_client_location)}

                </tbody>
              </table>
            </div>
          </>
          <hr className="mt-25"/>
          <h5>Permisos de precios</h5>
          <>
            <div className="">
              <table className="table">
                <thead className="list-table">
                <tr className="th-list-table">
                  <th>NOMBRE</th>
                  <th>ACTIVO</th>
                  <th>DESCRIPCIÓN</th>
                </tr>
                </thead>
                <tbody>
                {newConfigRow('Editar precios de pedido', <SelectInput name="can_change_prices"
                                                                       value={configFormControls.can_change_prices.value}
                                                                       onChange={changeConfigHandler}
                                                                       options={configFormControls.can_change_prices.options}
                                                                       touched={configFormControls.can_change_prices.touched}
                                                                       formClass="-"
                                                                       valid={configFormControls.can_change_prices.valid}/>,
                  'El usuario puede editar los precios de todos los productos al momento de crear un pedido.', businessData.can_change_prices, isEditingUserConfigTab)}

                {newConfigRow('Editar precios de devolución', businessData.can_change_refund_prices, 'Si el usuario tiene permitido crear devoluciones, entonces el usuario puede editar los precios de los productos al crear una devolución.', businessData.can_change_refund_prices)}


                {newConfigRow('Editar precios de cotización', <SelectInput
                    name="can_set_prices_on_quote_items"
                    value={configFormControls.can_set_prices_on_quote_items.value}
                    onChange={changeConfigHandler}
                    options={configFormControls.can_set_prices_on_quote_items.options}
                    touched={configFormControls.can_set_prices_on_quote_items.touched}
                    valid={configFormControls.can_set_prices_on_quote_items.valid}/>,
                  'El usuario puede editar los precios de todos los productos al momento de crear una cotización.',
                  businessData.can_set_prices_on_quote_items, isEditingUserConfigTab)}
                </tbody>
              </table>
            </div>
          </>

          <hr className="mt-25"/>
          <h5>Propiedades de usuario</h5>
          <>
            <div className="">
              <table className="table">
                <thead className="list-table">
                <tr className="th-list-table">
                  <th>NOMBRE</th>
                  <th>ACTIVO</th>
                  <th>DESCRIPCIÓN</th>
                </tr>
                </thead>
                <tbody>
                {newConfigRow('Ruta fija', <SelectInput name="has_fixed_route"
                                                        value={configFormControls.has_fixed_route.value}
                                                        onChange={changeConfigHandler}
                                                        options={configFormControls.has_fixed_route.options}
                                                        touched={configFormControls.has_fixed_route.touched}
                                                        formClass="-"
                                                        valid={configFormControls.has_fixed_route.valid}/>,
                  'El usuario puede ver unicamente los clientes asignados para la ruta del dia de hoy.', businessData.has_fixed_route, isEditingUserConfigTab)}

                {newConfigRow('Consolida pedidos', <SelectInput name="can_consolidate_orders"
                                                                value={configFormControls.can_consolidate_orders.value}
                                                                onChange={changeConfigHandler}
                                                                options={configFormControls.can_consolidate_orders.options}
                                                                touched={configFormControls.can_consolidate_orders.touched}
                                                                formClass="-"
                                                                valid={configFormControls.can_consolidate_orders.valid}/>,
                  'El usuario consolida pedidos al final del día.', businessData.can_consolidate_orders, isEditingUserConfigTab)}

                {newConfigRow('Tipo de cliente e infaltables', <SelectInput name="can_see_customer_segment"
                                                                            value={configFormControls.can_see_customer_segment.value}
                                                                            onChange={changeConfigHandler}
                                                                            options={configFormControls.can_see_customer_segment.options}
                                                                            touched={configFormControls.can_see_customer_segment.touched}
                                                                            formClass="-"
                                                                            valid={configFormControls.can_see_customer_segment.valid}/>,
                  'El usuario puede ver información del tipo de cliente y el listado de productos infaltables asignados a un cliente.',
                  businessData.can_see_customer_segment, isEditingUserConfigTab)}
                {newConfigRow('Impresión Fiscal', <SelectInput name="enable_fiscal_printer"
                                                                            value={configFormControls.enable_fiscal_printer.value}
                                                                            onChange={changeConfigHandler}
                                                                            options={configFormControls.enable_fiscal_printer.options}
                                                                            touched={configFormControls.enable_fiscal_printer.touched}
                                                                            formClass="-"
                                                                            valid={configFormControls.enable_fiscal_printer.valid}/>,
                  'El usuario puede imprimir una factura fiscal en base a un pedido.',
                  businessData.enable_fiscal_printer, isEditingUserConfigTab)}
                {newConfigRow('Descuentos manuales', <SelectInput name="can_select_discount"
                                                               value={configFormControls.can_select_discount.value}
                                                               onChange={changeConfigHandler}
                                                               options={configFormControls.can_select_discount.options}
                                                               touched={configFormControls.can_select_discount.touched}
                                                               formClass="-"
                                                               valid={configFormControls.can_select_discount.valid}/>,
                  'El usuario puede manualmente aplicar promociones de descuentos porcentuales.',
                  businessData.can_select_discount, isEditingUserConfigTab)}
                </tbody>
              </table>
            </div>
          </>
          <h5 className="mt-33">
            Las configuraciones que están en estado de activo “Si” pero no se pueden editar, están activadas
            para todos los usuarios por política de empresa.
          </h5>
          <h5 className="font-color-disable">*Aviso: Si desea activar alguna de las funcionalidades
            que
            dice “No Disponible” por favor comunicarse con el equipo de Soporte Ubiqua a
            soporte@ubiqua.me</h5>
        </form>
      </div>
    </>
  )
}

export default CreateUserConfigDetailStep
