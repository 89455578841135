import React from 'react';
import cookie from 'react-cookies';
import ProductsPriceListTab from './ProductsPriceListTab';
import ClientsPriceListTab from './ClientsPriceListTab';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ShowPriceListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Clientes',
      id: window.location.href.split('/')[window.location.href.split('/').length - 1],
      db_ref: '',
      description: ''
    }
  }

  // in order to change active state for tabs
  handleTabClick = event => {
    // if tab was clicked (tab is an <a> element)
    if (event.target.tagName === 'A') {
      this.setState({activeTab: event.target.innerText}, this.render)
    }
  };

  componentDidMount() {
    this.fetchPriceListDetails();
  }

  fetchPriceListDetails = () => {

    fetch(BACKEND_URL +  '/price_lists/' + this.state.id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response =>
        {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        }
      )
      .then(data => this.setState({ db_ref: data['db_ref'], description: data['description']}))
      .catch(e => console.log(e));
  };

  render() {
    return(
      <div className="main-view">
        <div className="main-show-header">
          <h2>Lista de Precio: {this.state.db_ref} - {this.state.description}</h2>
        </div>
        <div className="index-table">
          <div className='show-area'>
            <div>
              <div onClick={this.handleTabClick}>
                <ul className="nav nav-tabs">
                  <li className={`${this.state.activeTab === 'Clientes' ? 'active':''}`}>
                    <a className={this.state.activeTab !== 'Clientes' ? 'inactive-tab cursor-pointer': 'cursor-pointer'} >Clientes</a>
                  </li>
                  <li className={this.state.activeTab === 'Productos' ? 'active':''}>
                    <a className={this.state.activeTab !== 'Productos' ? 'inactive-tab cursor-pointer': 'cursor-pointer'}>Productos</a>
                  </li>
                </ul>
              </div>
              <div>
                { this.state.activeTab === 'Clientes' &&
                  <div>
                    <ClientsPriceListTab id={this.state.id}/>
                  </div>
                }
                { this.state.activeTab === 'Productos' &&
                  <div>
                    <ProductsPriceListTab id={this.state.id}/>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };
}
