import React from 'react';
import cookie from 'react-cookies';
import ReactDOM from 'react-dom';
import Select from 'react-select'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ProductDetailsTab extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      isEditing: false,
      productEditDetailsData: null,
      active: this.props.data['active'],
      price_unit_id: this.props.data['price_unit'] ? this.props.data['price_unit']['id'] : '',
      business_id: 1,
      barcode: this.props.data['barcode'],
      name: this.props.data['name'],
      alternative_name: this.props.data['alternative_name'],
      description: this.props.data['description'],
      tax: this.props.data['tax'] ? this.props.data['tax']['id'] : '',
      brand_id: this.props.data['brand'] ? this.props.data['brand']['id'] : '',
      goal_category_id: this.props.data['goal_category'] ? this.props.data['goal_category']['id'] : '',
      package_id: this.props.data['package'] ? this.props.data['package']['id'] : '',
      quantity_per_package: this.props.data['quantity_per_package'],
      max_weight: this.props.data['max_weight'],
      min_weight: this.props.data['min_weight'],
      average_weight: this.props.data['average_weight'],
      expire_time: this.props.data['expire_time'],
      lead_time: this.props.data['lead_time'],
      product_type_id: this.props.data['product_type'] ? this.props.data['product_type']['id'] : '',
      product_subtype_id: this.props.data['product_subtype'] ? this.props.data['product_subtype']['id'] : '',
      category_ids: this.props.data['categories'] ? this.props.data['categories'].map((obj) => obj.id) : [],
      subcategory_ids: this.props.data['subcategories'] ? this.props.data['subcategories'].map((obj) => obj.id) : [],
      selectedCategories: [],
      selectedSubCategories: [],
      notSelectedCategories: [],
      notSelectedSubCategories: [],
      is_consumable: this.props.data['is_consumable']
    }
  }

  setEditMode = event => {
    event.preventDefault();
    this.setState({isEditing: true})
  };

  unsetEditMode = event => {
    event.preventDefault();
    this.setState({isEditing: false})
  };

  handleSubmitEditProduct = (event) => {
    event.preventDefault();
    fetch(BACKEND_URL +  '/products/' + this.props.id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        active: this.state.active,
        price_unit_id: this.state.price_unit_id,
        business_id: 1,
        barcode: this.state.barcode,
        name: this.state.name,
        alternative_name: this.state.alternative_name,
        description: this.state.description,
        brand_id: this.state.brand_id,
        goal_category_id: this.state.goal_category_id,
        package_id: this.state.package_id,
        quantity_per_package: this.state.quantity_per_package,
        max_weight: this.state.max_weight,
        min_weight: this.state.min_weight,
        average_weight: this.state.average_weight,
        expire_time: this.state.expire_time,
        lead_time: this.state.lead_time,
        tax_id: this.state.tax,
        product_type_id: this.state.product_type_id,
        product_subtype_id: this.state.product_subtype_id,
        is_consumable: this.state.is_consumable,
        category_ids: this.state.category_ids,
        subcategory_ids: this.state.subcategory_ids
      })
    })
    .then(response => response.json())
    .then(data => window.location =  '/productos/' + this.props.id + '/detalles')
    .catch(e => console.log(e));
  };

  componentDidMount() {
    this.fetchDataForEdit();
  }

  setInitialCategoriesAndSubCategories = () => {
    this.setOptionsForCategories();
    this.setOptionsForSubCategories()
  };

  setOptionsForCategories = () => {
    this.setState({selectedCategories: this.getCategories(),
                   notSelectedCategories: this.getNotSelectedCategories()})
  };

  setOptionsForSubCategories = () => {
    this.setState({selectedSubCategories: this.getSubcategories(),
                   notSelectedSubCategories: this.getNotSelectedSubCategories()})
  };

  fetchDataForEdit = () => {
    fetch(BACKEND_URL +  '/businesses/1/products/new', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => response.json())
      .then(data => this.setState({ productEditDetailsData: data}, this.setInitialCategoriesAndSubCategories))
      .catch(e => console.log(e));
  };

  handleChange (event) {
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  handleCategoryMultiSelect (event) {
    let values = event.map((obj) => obj['value']);
    this.setState({category_ids: values}, this.setOptionsForCategories)
  }

  handleSubCategoryMultiSelect (event) {
    let values = event.map((obj) => obj['value']);
    this.setState({subcategory_ids: values}, this.setOptionsForSubCategories)
  }

  getNotSelectedCategories = () => {
    const a = [];
    const notSelected = this.state.productEditDetailsData['categories'].filter((obj) => this.state.category_ids.indexOf(obj.id) < 0);
    notSelected.map((obj) =>
      a.push({value: obj.id, label: obj.description, name: 'category'})
    );
    return a
  };

  getCategories = () => {
    const a = [];
    const selected = this.state.productEditDetailsData['categories'].filter((obj) => this.state.category_ids.indexOf(obj.id) >= 0);
    selected.map ((obj) =>
      a.push({value: obj.id, label: obj.description, name: 'category'})
    );
    return a
  };

  getSubcategories = () => {
    const a = [];
    const selected = this.state.productEditDetailsData['subcategories'].filter((obj) => this.state.subcategory_ids.indexOf(obj.id) >= 0);
    selected.map ((obj) =>
      a.push({value: obj.id, label: obj.description, name: 'subcategory'})
    );
    return a
  };

  getNotSelectedSubCategories = () => {
    const a = [];
    const notSelected = this.state.productEditDetailsData['subcategories'].filter((obj) => this.state.subcategory_ids.indexOf(obj.id) < 0);
    notSelected.map((obj) =>
      a.push({value: obj.id, label: obj.description, name: 'subcategory'})
    );
    return a
  };

  getFormattedDate = (date) => {
    let d = new Date(date);
    return d.toLocaleString()
  };

  render() {
    let data = this.props.data;
    let active = 'No';
    if (data['active'] === true) {
      active = 'Si'
    }
    let is_consumable = 'No';
    if (data['is_consumable'] === true) {
      is_consumable = 'Si'
    }
    return(
      <div>
        { this.state.isEditing === false &&
          <div>
            <div className="show-product-header show-header">
              <h2>Detalles de producto:</h2>
              <div>
                <div className="show-buttons">
                  <input onClick={this.setEditMode.bind(this)} value='Actualizar detalles' type="submit" />
                </div>
              </div>
            </div>
            <hr/>
            <div>
              <div>
                <div className="show-data">
                  <div>Activo</div>
                  <div>{active}</div>
                </div>
                <div className="show-data">
                  <div>Unidad de Precios</div>
                  <div>{data['price_unit']['description']}</div>
                </div>
                <div className="show-data">
                  <div>Identificador Unico</div>
                  <div>{data['db_ref'] + '-' + data['business']['name']}</div>
                </div>
                <div className="show-data">
                  <div>Codigo</div>
                  <div>{data['db_ref']}</div>
                </div>
                <div className="show-data">
                  <div>Codigo de barra</div>
                  <div>{data['barcode']}</div>
                </div>
                <div className="show-data">
                  <div>Nombre</div>
                  <div>{data['name']}</div>
                </div>
                <div className="show-data">
                  <div>Nombre Alternativo</div>
                  <div>{data['alternative_name']}</div>
                </div>
                <div className="show-data">
                  <div>Descripción Comercial</div>
                  <div className="initial-height">{data['description']}</div>
                </div>
                <div className="show-data">
                  <div>ITBMS</div>
                  <div className="initial-height">{data['tax']['name']}</div>
                </div>
                <div className="show-data">
                  <div>Categoria para Cuota</div>
                  <div>{data['goal_category']['name']}</div>
                </div>
                <div className="show-data">
                  <div>Marca</div>
                  <div>{data['brand']['description']}</div>
                </div>
                <div className="show-data">
                  <div>Empaque de Venta</div>
                  <div>{data['package']['description']}</div>
                </div>
                <div className="show-data">
                  <div>Cantidad por Empaque de Venta</div>
                  <div>{data['quantity_per_package']}</div>
                </div>
                <div className="show-data">
                  <div>Peso Maximo</div>
                  <div>{data['max_weight']}</div>
                </div>
                <div className="show-data">
                  <div>Peso Promedio</div>
                  <div>{data['average_weight']}</div>
                </div>
                <div className="show-data">
                  <div>Peso Minimo</div>
                  <div>{data['min_weight']}</div>
                </div>
                <div className="show-data">
                  <div>Tiempo de expiración (dias)</div>
                  <div>{data['expire_time']}</div>
                </div>
                <div className="show-data">
                  <div>Tiempo de producción</div>
                  <div>{data['lead_time']}</div>
                </div>
                <div className="show-data">
                  <div>Tipo de Producto</div>
                  <div>{data['product_type']['description']}</div>
                </div>
                <div className="show-data">
                  <div>Sub-tipo de Producto</div>
                  <div>{data['product_subtype']['description']}</div>
                </div>
                <div className="show-data">
                  <div>Categoria de ventas</div>
                  <div>{data['categories'].map((obj) => obj['description']).join(', ')}</div>
                </div>
                <div className="show-data">
                  <div>Sub-categoria de ventas</div>
                  <div>{data['subcategories'].map((obj) => obj['description']).join(', ')}</div>
                </div>
                <div className="show-data">
                  <div>Insumo</div>
                  <div>{is_consumable}</div>
                </div>
                <div className="show-data">
                  <div>Fecha de creación</div>
                  <div>{this.getFormattedDate(data['created_at'])}</div>
                </div>
                <div className="show-data">
                  <div>Fecha de actualización</div>
                  <div>{this.getFormattedDate(data['updated_at'])}</div>
                </div>
              </div>
            </div>
          </div>
        }
        { this.state.isEditing === true &&
          <form onSubmit={this.handleSubmitEditProduct.bind(this)}>
            <div className="show-product-header show-header">
              <h2>Detalles de producto:</h2>
              <div className="show-buttons">
                <input onClick={this.unsetEditMode.bind(this)} className="cancel-edit-button" value='Cancelar' type="submit" />
                <input value='Guardar' type="submit" />
              </div>
            </div>
            <hr/>
            <div>
              <div className="show-data">
                <div>Activo</div>
                <select name="active" onChange={this.handleChange.bind(this)}>
                  <option hidden>{active}</option>
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="show-data">
                <div>Unidad de Precios<span className="red">*</span></div>
                <select required name="price_unit_id" onChange={this.handleChange.bind(this)}>
                  <option hidden>{data['price_unit']['description']}</option>
                  { this.state.productEditDetailsData['price_unit'].filter((v) => v['description'] != 'DINERO').map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="show-data">
                <div>Identificador Unico</div>
                <div>{data['db_ref'] + '-' + data['business']['name']}</div>
              </div>
              <div className="show-data">
                <div>Codigo</div>
                <div>{data['db_ref']}</div>
              </div>
              <div className="show-data">
                <div>Codigo de barra</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="barcode" defaultValue={data['barcode']} />
              </div>
              <div className="show-data">
                <div>Nombre<span className="red">*</span></div>
                <input onChange={this.handleChange.bind(this)} type="text" name="name" defaultValue={data['name']} required/>
              </div>
              <div className="show-data">
                <div>Nombre Alternativo</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="alternative_name" defaultValue={data['alternative_name']} />
              </div>
              <div className="show-data">
                <div>Descripción Comercial<span className="red">*</span></div>
                <textarea autoComplete="off" onChange={this.handleChange.bind(this)}
                          className="textArea" name="description"
                          maxLength="500" defaultValue={data['description']} required/>
              </div>
              <div className="show-data">
                <div>ITBMS<span className="red">*</span></div>
                <select required name="tax" onChange={this.handleChange.bind(this)}>
                  <option hidden>{data['tax']['name']}</option>
                  { this.state.productEditDetailsData['taxes'].filter((v) => v['name']).map((obj) =>
                  {
                    return <option key={obj.id} value={obj.id}>{`ITBMS - ${obj['name']}`}</option>
                  }
                  )}
                </select>
              </div>
              <div className="show-data">
                <div>Categoria para Cuota</div>
                <select name="goal_category_id" onChange={this.handleChange.bind(this)}>
                  <option hidden>{data['goal_category']['name']}</option>
                  { this.state.productEditDetailsData['goal_categories'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['name']}</option>
                  )}
                </select>
              </div>
              <div className="show-data">
                <div>Marca<span className="red">*</span></div>
                <select required name="brand_id" onChange={this.handleChange.bind(this)}>
                  <option hidden>{data['brand']['description']}</option>
                  { this.state.productEditDetailsData['brand'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="show-data">
                <div>Empaque de Venta<span className="red">*</span></div>
                <select required name="package_id" onChange={this.handleChange.bind(this)}>
                  <option hidden>{data['package']['description']}</option>
                  { this.state.productEditDetailsData['package'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="show-data">
                <div>Cantidad por Empaque de Venta</div>
                <input onChange={this.handleChange.bind(this)} type="number" name="quantity_per_package" defaultValue={data['quantity_per_package']} />
              </div>
              <div className="show-data">
                <div>Peso Maximo</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="max_weight" defaultValue={data['max_weight']} />
              </div>
              <div className="show-data">
                <div>Peso Promedio</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="average_weight" defaultValue={data['average_weight']} />
              </div>
              <div className="show-data">
                <div>Peso Minimo</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="min_weight" defaultValue={data['min_weight']} />
              </div>
              <div className="show-data">
                <div>Tiempo de expiración (dias)</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="expire_time" defaultValue={data['expire_time']} />
              </div>
              <div className="show-data">
                <div>Tiempo de producción</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="lead_time" defaultValue={data['lead_time']} />
              </div>
              <div className="show-data">
                <div>Tipo de Producto</div>
                <select name="product_type_id" onChange={this.handleChange.bind(this)}>
                  <option hidden>{data['product_type']['description']}</option>
                  { this.state.productEditDetailsData['product_type'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="show-data">
                <div>Sub-tipo de Producto</div>
                <select name="product_subtype_id" onChange={this.handleChange.bind(this)}>
                  <option hidden>{data['product_subtype']['description']}</option>
                  { this.state.productEditDetailsData['product_subtype'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="show-data-multi-select">
                <div className="multi-select-label">Categoria de ventas</div>
                <Select onChange={this.handleCategoryMultiSelect.bind(this)} defaultValue={this.state.selectedCategories} name="category_ids" isMulti options={this.state.notSelectedCategories} className="multi-select-input" closeMenuOnSelect={false}/>
              </div>
              <div className="show-data-multi-select">
                <div className="multi-select-label">Sub-categoria de ventas</div>
                <Select onChange={this.handleSubCategoryMultiSelect.bind(this)} defaultValue={this.state.selectedSubCategories} name="subcategory_ids" isMulti options={this.state.notSelectedSubCategories} className="multi-select-input" closeMenuOnSelect={false} />
              </div>
              <div className="show-data">
                <div>Insumo</div>
                <select name="is_consumable" onChange={this.handleChange.bind(this)}>
                  <option hidden>{is_consumable}</option>
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
          </form>
        }
      </div>
    )
  };
}
