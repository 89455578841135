import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDoubleRight, faAngleLeft, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'

export default class Pagination extends React.Component {

  render() {
    return (
      <ul className="pagination">
        <li className="page-item">
          <a id="first-page" className="page-link cursor-pointer"><FontAwesomeIcon icon={faAngleDoubleLeft} id="first-page"/></a>
        </li>
        <li className="page-item">
          <a id="previous-page" className="page-link cursor-pointer"><FontAwesomeIcon icon={faAngleLeft} id="previous-page"/></a>
        </li>
        <li className="page-item">
          <a id="next-page" className="page-link cursor-pointer"><FontAwesomeIcon icon={faAngleRight} id="next-page"/></a>
        </li>
        <li className="page-item">
          <a id="last-page" className="page-link cursor-pointer"><FontAwesomeIcon icon={faAngleDoubleRight} id="last-page"/></a>
        </li>
        <div className="pagination-label"><b>{this.props.current_page} de {this.props.total_pages} páginas</b></div>
      </ul>
    );
  };
}
