import React, { useContext, useState } from 'react';
import NavBar from "../layout/NavBar";
import UserConfigHeader from "./component/UserConfigHeader";
import { UserStepTabsContext } from "./context/UserStepTabsProvider";
import UserInfoDetailTab from "./tabs/UserInfoDetailTab";
import { UserInfoContext } from "./context/UserInfoProvider";
import UserConfigInfoDetailTab from "./tabs/UserConfigDetailTab";
import UserAuditTab from "./tabs/UserAuditTab";
import { UserConfigContext, UserConfigProvider } from "./context/UserConfigProvider";
import { UserAuditProvider } from "./context/UserAuditProvider";
import SuccessCreateUserModal from "./component/modal/SuccessCreateUserModal";

const TabsUserConfig = () => {

  const {
    isEditingUserInfoTab,
    userInfoData,
    isFetching,
    activeTab,
    handleTabClickDetails,
    failedCreationInfo,
    createUserParams
  } = useContext(UserInfoContext)

  const {
    isEditingUserConfigTab,
    handleTabClickConfig
  } = useContext(UserConfigContext)

  const handleTabClick = event => {
    handleTabClickDetails(event)
    handleTabClickConfig(event)
  };

  const isEditing = isEditingUserInfoTab !== isEditingUserConfigTab

  return (
    <>
      <div>
        <UserConfigHeader isFormEditing={isEditing}/>
        <div className="main-view">
          {createUserParams != null &&
          <SuccessCreateUserModal userInfo={createUserParams}/>
          }
          {failedCreationInfo != null &&
          <div className="alert alert-danger" role="alert">
            <strong>{failedCreationInfo}</strong>
          </div>
          }
          {isFetching ? (
            <div className="main-show-header">
              <h2/>
            </div>) : (
            <>
              {isEditing === true &&
              <div className="main-show-header">
                <h2>Actualizar detalles del usuario: {userInfoData.db_ref + " - " + userInfoData.name}</h2>
              </div>
              }
              {isEditing === false &&
              <div className="main-show-header">
                {userInfoData !== '' ?
                  (<h2>{`Usuario: ${userInfoData.db_ref} - ${userInfoData.name}`}</h2>) : (<h2>{`Usuario`}</h2>)
                }
              </div>
              }
            </>)
          }
          <div>
            <div className="index-table">
              <div className='show-area-tab'>
                <div>
                  <div onClick={handleTabClick}>
                    <ul className="nav nav-tabs">
                      <li id="details_tab" className={`${activeTab === 'Detalles' ? 'active' : ''}`}>
                        <a
                          className={activeTab !== 'Detalles' ? 'inactive-tab cursor-pointer' : 'cursor-pointer'}>Detalles</a>
                      </li>

                      <li id="price_lists_tab" className={activeTab === 'Configuraciones' ? 'active' : ''}>
                        <a
                          className={activeTab !== 'Configuraciones' ? 'inactive-tab cursor-pointer' : 'cursor-pointer'}>Configuraciones</a>
                      </li>
                      <li id="price_lists_tab" className={activeTab === 'Historial' ? 'active' : ''}>
                        <a
                          className={activeTab !== 'Historial' ? 'inactive-tab cursor-pointer' : 'cursor-pointer'}>Historial</a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {
                      activeTab === 'Detalles' &&
                      <div>
                        <UserInfoDetailTab activeTab/>
                      </div>
                    }
                    {
                      activeTab === 'Configuraciones' &&
                      <div>
                        <UserConfigInfoDetailTab activeTab/>

                      </div>
                    }
                    {
                      activeTab === 'Historial' &&
                      <div>
                        <UserAuditProvider userInfoData>
                          <UserAuditTab activeTab/>
                        </UserAuditProvider>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <NavBar activeItem='Usuarios' isEditing={isEditing}/>
        </div>
        )}
      </div>
    </>
  )
}

export default TabsUserConfig