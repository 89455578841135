import React, { Component } from 'react';
import NavBar from '../layout/NavBar';
import TopBar from '../layout/TopBar';
import CreateNewClientView from './CreateNewClientView';
import * as keyboardCodes from '../constants/keyboardCodes';
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages';

export default class CreateNewClientApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      searchPlaceholder: "Buscar clientes por código o nombre",
      title: "Cliente nuevo",
    };
  }

  componentDidMount() {
    this.setState({ isFetching: false });
  }

  passEditState = (editStateVal) => {
    this.setState({ isEditing: editStateVal })
  };

  handleSearch = event => {
    if (event.keyCode === keyboardCodes.ENTER_KEY && window.confirm(DISCARD_CHANGES_CONFIRMATION)) {
      event.preventDefault();
      const query = event.target.value;
      if (query !== '') {
        window.location = '/clientes?query=' + query
      }
    }
  };

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
            />
          </div>
          <div onClick={this.handlePaginationClick}>
            <CreateNewClientView
              data={this.state.data}
              title={this.state.title}
            />
          </div>
        </div>
        <div>
          <NavBar activeItem='Clientes' isEditing={true}/>
        </div>
      </div>
    )
  }
}
