import React from 'react';
import ReactDOM from 'react-dom';
import NavBar from '../layout/NavBar';
import TopBar from '../layout/TopBar';
import ShowSalesmanView from '../salesman/ShowSalesmanView';

export default class ShowSalesmanApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: 'Ana Sofia Lau Hou',
      searchPlaceholder: "Buscar vendedores por código o nombre",
      isEditing: false
    };
  }

  handleSearch = event => {
    // search action occurs after ENTER is pressed
    if(event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault();
      let query = event.target.value;
      if (query !== '') {
        if (this.state.isEditing) {
          let val = window.confirm("Esta seguro que quiere continuar? Los cambios editados no seran guardados");
          if (val === true) {
            window.location = '/vendedores?query=' + query
          }
        } else {
          window.location = '/vendedores?query=' + query
        }
        event.target.value = ''
      }

    }
  };

  changeEditMode = (val) => {
    this.setState({ isEditing: val })
  }

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
          </div>
          <div>
            <ShowSalesmanView isEditing={this.state.isEditing}
                              useAve={this.props.variants}
              changeEditMode={this.changeEditMode} successCreateForNewSalesman={this.props.successCreateForNewSalesman}/>
          </div>
        </div>
        <div>
          <NavBar activeItem='Equipo de campo' isEditing={this.state.isEditing}/>
        </div>
      </div>
    )
  }
}
