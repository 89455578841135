import React from 'react';
import ReactDOM from 'react-dom';
import NavBar from '../layout/NavBar';
import TopBar from '../layout/TopBar';
import ShowLiquidacionView from '../liquidacion/ShowLiquidacionView';

export default class ShowLiquidacionApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: 'Ana Sofia Lau Hou',
      searchPlaceholder: "Buscar liquidación por código o nombre de entregador"
    };
  }

  handleSearch = event => {
    // search action occurs after ENTER is pressed
    if(event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault();
      let query = event.target.value;
      if (query !== '') {
        window.location = '/liquidaciones?query=' + query
        event.target.value = ''
      }

    }
  };

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
          </div>
          <div>
            <ShowLiquidacionView />
          </div>
        </div>
        <div>
          <NavBar activeItem='Liquidaciones' isEditing={false}/>
        </div>
      </div>
    )
  }
}
