import React from 'react';
import cookie from 'react-cookies';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import AddPriceListBlock from './AddPriceListBlock'
import Select from 'react-select'
import history from "../helpers/history";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class CreateProductModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showWarningPage1: false,
      showWarningPage2: false,
      failedCreation: false,
      isFetching: true,
      data: null,
      numberOfPage: 1,
      business_id: 1,
      active: true,
      price_unit_id: '',
      tax_id: '',
      db_ref: '',
      barcode: '',
      name: '',
      alternative_name: '',
      description: '',
      brand_id: '',
      goal_category_id: '',
      package_id: '',
      quantity_per_package: '',
      max_weight: '',
      min_weight: '',
      average_weight: '',
      expire_time: '',
      lead_time: '',
      product_type_id: '',
      product_subtype_id: '',
      category_ids: [],
      subcategory_ids: [],
      selectedCategories: [],
      selectedSubCategories: [],
      notSelectedCategories: [],
      notSelectedSubCategories: [],
      is_consumable: false,
      price_lists: [{ price_list_id: '', price: '' }]
    }
  }

  getInitialState = () => {
    return {
      showWarningPage1: false,
      showWarningPage2: false,
      failedCreation: false,
      numberOfPage: 1,
      business_id: 1,
      active: true,
      price_unit_id: '',
      tax_id: '',
      db_ref: '',
      barcode: '',
      name: '',
      alternative_name: '',
      description: '',
      brand_id: '',
      goal_category_id: '',
      package_id: '',
      quantity_per_package: '',
      max_weight: '',
      min_weight: '',
      average_weight: '',
      expire_time: '',
      lead_time: '',
      product_type_id: '',
      product_subtype_id: '',
      category_ids: [],
      subcategory_ids: [],
      selectedCategories: [],
      selectedSubCategories: [],
      notSelectedCategories: [],
      notSelectedSubCategories: [],
      is_consumable: false,
      price_lists: [{ price_list_id: '', price: '' }]
    };
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  componentDidMount() {
    this.fetchDataToCreate()
  }

  fetchDataToCreate = () => {
    fetch(BACKEND_URL + '/businesses/1/products/new', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then(response => response.json())
      .then(data => this.setState({ data: data, isFetching: false }, this.setInitialCategoriesAndSubCategories))
      .catch(e => console.log(e));
  };

  handleNextPage = (event) => {
    if (event.target.innerText === 'Atras') {
      this.setState({ numberOfPage: 1, showWarningPage2: false })
    } else {
      if (this.state.name !== '' && this.state.db_ref !== '' &&
      this.state.description !== ''&& this.state.price_unit_id !== '' &&
      this.state.tax_id !== '' && this.state.brand_id !== ''&&
      this.state.package_id !== '') {
        this.setState({numberOfPage: 2, showWarningPage1: false})
      } else {
        this.setState({ showWarningPage1: true })
      }
    }
  };

  validatePriceListsInputs = () => {
    var incomplete = this.state.price_lists.filter((obj) =>
      obj.price_list_id === '' || obj.price === ''
    );
    return incomplete.length <= 0;
  };

  handleCreateProduct = (event) => {
    event.preventDefault();
    const canCreate = this.validatePriceListsInputs();
    if (canCreate === true) {
      this.setState({ showWarningPage2: false });
      fetch(BACKEND_URL + '/products', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: this.state.business_id,
          active: this.state.active,
          price_unit_id: this.state.price_unit_id,
          db_ref: this.state.db_ref,
          barcode: this.state.barcode,
          name: this.state.name,
          alternative_name: this.state.alternative_name,
          description: this.state.description,
          tax_id: this.state.tax_id,
          brand_id: this.state.brand_id,
          goal_category_id: this.state.goal_category_id,
          package_id: this.state.package_id,
          quantity_per_package: this.state.quantity_per_package,
          max_weight: this.state.max_weight,
          min_weight: this.state.min_weight,
          average_weight: this.state.average_weight,
          expire_time: this.state.expire_time,
          lead_time: this.state.lead_time,
          product_type_id: this.state.product_type_id,
          product_subtype_id: this.state.product_subtype_id,
          category_ids: this.state.category_ids,
          subcategory_ids: this.state.subcategory_ids,
          is_consumable: this.state.is_consumable,
          price_lists: this.state.price_lists
        })
      })
        .then(r => r.json().then(data => ({ status: r.status, body: data })))
        .then(obj => this.evaluateResponse(obj))
        .catch(e => console.log(e));
    } else {
      this.setState({ showWarningPage2: true })
    }
  };

  evaluateResponse = (obj) => {
    if (obj.status === 422) {
      this.setState({ failedCreation: true });
      setTimeout(this.hideFailedCreationMessage, 5000)
    } else {
      let data = obj.body;
      history.push({
          pathname: '/productos/' + data['id'] + '/detalles',
          params: { successCreateForNewProduct: true }
        }
      );
    }
  };

  hideFailedCreationMessage = () => {
    this.setState({ failedCreation: false })
  };

  handleChange(event) {
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  addNewPriceListBlock(event) {
    const obj = { price_list_id: '', price: '' };
    this.setState({ price_lists: [...this.state.price_lists, obj] });
  }

  deletePriceListBlock(row) {
    var a = this.state.price_lists;
    a.splice(row, 1);
    this.setState({ price_lists: a })
  }

  updatePriceListId(row, event) {
    const targetName = event.target.name;
    let targetValue = event.target.value;
    let element = document.getElementsByName(targetValue)[0];
    if (element) {
      let price_list_id = element.innerText;
      if (price_list_id) {
        targetValue = price_list_id
      } else {
        targetValue = ''
      }
      const list = this.state.price_lists;
      const obj = list[row];
      obj[targetName] = targetValue;
      this.setState({ price_lists: list });
    }
  }

  updatePriceListPrice(row, event) {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    const list = this.state.price_lists;
    const obj = list[row];
    obj[targetName] = targetValue;
    list[row] = obj;
    this.setState({ price_lists: list });
  }

  setOptionsForMissingPriceLists = () => {
    let items = [];
    let selectedPriceLists = this.state.price_lists.map((obj) => obj.price_list_id);
    this.state.data['price_lists'].map((obj) =>
      selectedPriceLists.indexOf(String(obj.id)) === -1 ?
        items.push(<option key={obj.id} value={obj.description + ' - ' + obj.db_ref}
                           name={obj.description + ' - ' + obj.db_ref}>{obj.id}</option>) : items.push(null)
    );
    return items.filter((obj) => obj != null);
  };

  passEditState = () => {
    let resp = window.confirm('No has terminado de guardar tus cambios. Desea salir de esta pagina?');
    if (resp === true) {
      this.resetState();
      this.props.shouldCloseForX();
    }
  };

  handleCategoryMultiselect(event) {
    let values = event.map((obj) => obj['value']);
    this.setState({ category_ids: values }, this.setOptionsForCategories)
  }

  handleSubCategoryMultiselect(event) {
    let values = event.map((obj) => obj['value']);
    this.setState({ subcategory_ids: values }, this.setOptionsForSubCategories)
  }

  setInitialCategoriesAndSubCategories = () => {
    this.setOptionsForCategories();
    this.setOptionsForSubCategories()
  };

  setOptionsForCategories = () => {
    this.setState({
      selectedCategories: this.getCategories(),
      notSelectedCategories: this.getNotSelectedCategories()
    })
  };

  setOptionsForSubCategories = () => {
    this.setState({
      selectedSubCategories: this.getSubcategories(),
      notSelectedSubCategories: this.getNotSelectedSubCategories()
    })
  };

  getNotSelectedCategories = () => {
    var a = [];
    var notSelected = this.state.data['categories'].filter((obj) => this.state.category_ids.indexOf(obj.id) < 0);
    notSelected.map((obj) =>
      a.push({ value: obj.id, label: obj.description, name: 'category' })
    );
    return a
  };

  getCategories = () => {
    var a = [];
    var selected = this.state.data['categories'].filter((obj) => this.state.category_ids.indexOf(obj.id) >= 0);
    selected.map((obj) =>
      a.push({ value: obj.id, label: obj.description, name: 'category' })
    );
    return a
  };

  getSubcategories = () => {
    var a = [];
    var selected = this.state.data['subcategories'].filter((obj) => this.state.subcategory_ids.indexOf(obj.id) >= 0);
    selected.map((obj) =>
      a.push({ value: obj.id, label: obj.description, name: 'subcategory' })
    );
    return a
  };

  getNotSelectedSubCategories = () => {
    var a = [];
    var notSelected = this.state.data['subcategories'].filter((obj) => this.state.subcategory_ids.indexOf(obj.id) < 0);
    notSelected.map((obj) =>
      a.push({ value: obj.id, label: obj.description, name: 'subcategory' })
    );
    return a
  };

  render() {
    return (
      <div className="modal-wrapper">
        {this.state.failedCreation === true &&
        <div className="alert alert-danger" role="alert">
          <strong>No se pudo crear el producto. Por favor, verifique los datos!</strong>
        </div>
        }
        {this.state.numberOfPage === 1 &&
        <div>
          <div>
            <span className="modal-close-btn" onClick={this.passEditState.bind(this)}>&times;</span>
          </div>
          <div className="modal-header">
            <h1>Crear producto nuevo</h1>
            <input onClick={this.handleNextPage.bind(this)} className="save-modal" value='Siguiente' type="submit"/>
          </div>
          {this.state.isFetching === false &&
          <div className="scroll-section">
            <div>
              <div><span><b>Paso 1 de 2: </b></span>Agregue la informacion del producto nuevo que va crear</div>
              <div className="red">Obligatorios *</div>
            </div>
            {this.state.showWarningPage1 &&
            <div className="red">Por favor llene los campos obligatorios con * para poder proceder</div>
            }
            <div>
              <div className="data-row">
                <div>Activo</div>
                <select name="active" onChange={this.handleChange.bind(this)}>
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="data-row">
                <div>Unidad de Precios<span className="red">*</span></div>
                <select name="price_unit_id" onChange={this.handleChange.bind(this)}>
                  <option hidden value={this.state.price_unit_id}>
                    {this.state.price_unit_id !== '' ?
                      this.state.data['price_unit'].filter((obj) => parseInt(obj.id) === parseInt(this.state.price_unit_id))[0]['description'] : ''
                    }
                  </option>
                  {this.state.data['price_unit'].filter((v) => v['description'] != 'DINERO').map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="data-row">
                <div>Codigo<span className="red">*</span></div>
                <input onChange={this.handleChange.bind(this)} type="text" name="db_ref" value={this.state.db_ref}
                       required/>
              </div>
              <div className="data-row">
                <div>Codigo de barra</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="barcode" value={this.state.barcode}/>
              </div>
              <div className="data-row">
                <div>Nombre<span className="red">*</span></div>
                <input onChange={this.handleChange.bind(this)} type="text" name="name" value={this.state.name}
                       required/>
              </div>
              <div className="data-row">
                <div>Nombre Alternativo</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="alternative_name"
                       value={this.state.alternative_name}/>
              </div>
              <div className="data-row">
                <div>Descripción Comercial<span className="red">*</span></div>
                <textarea autoComplete="off" onChange={this.handleChange.bind(this)}
                          className="textArea" name="description"
                          maxLength="500" value={this.state.description} required/>
              </div>
              <div className="data-row">
                <div>ITBMS<span className="red">*</span></div>
                <select name="tax_id" onChange={this.handleChange.bind(this)}>
                  <option hidden value={this.state.tax_id}>
                    {this.state.tax_id !== '' ?
                      this.state.data['taxes'].filter((obj) => parseInt(obj.id) === parseInt(this.state.tax_id))[0]['name'] : ''
                    }
                  </option>
                  { this.state.data['taxes'] != null ? this.state.data['taxes'].filter((v) => v['name']).map((obj) =>
                    <option key={obj.id} value={obj.id}>{ `ITBMS - ${obj['name']}`}</option>
                  ) : []}
                </select>
              </div>
              <div className="data-row">
                <div>Categoria para Cuota</div>
                <select name="goal_category_id" onChange={this.handleChange.bind(this)}>
                  <option value={this.state.goal_category_id}>
                    {this.state.data['goal_categories'].filter((obj) => obj.id === this.state.goal_category_id)[0] ?
                      this.state.data['goal_categories'].filter((obj) => obj.id === this.state.goal_category_id)[0]['description'] : ''}
                  </option>
                  {this.state.data['goal_categories'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['name']}</option>
                  )}
                </select>
              </div>
              <div className="data-row">
                <div>Marca<span className="red">*</span></div>
                <select name="brand_id" onChange={this.handleChange.bind(this)}>
                  <option hidden value={this.state.brand_id}>
                    {this.state.brand_id !== '' ?
                      this.state.data['brand'].filter((obj) => parseInt(obj.id) === parseInt(this.state.brand_id))[0]['description'] : ''
                    }
                  </option>
                  {this.state.data['brand'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="data-row">
                <div>Empaque de Venta<span className="red">*</span></div>
                <select name="package_id" onChange={this.handleChange.bind(this)}>
                  <option hidden value={this.state.package_id}>
                    {this.state.package_id !== '' ?
                      this.state.data['package'].filter((obj) => parseInt(obj.id) === parseInt(this.state.package_id))[0]['description'] : ''
                    }
                  </option>
                  {this.state.data['package'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="data-row">
                <div>Cantidad por Empaque de Venta</div>
                <input onChange={this.handleChange.bind(this)} type="number" name="quantity_per_package"
                       value={this.state.quantity_per_package}/>
              </div>
              <div className="data-row">
                <div>Peso Maximo</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="max_weight"
                       value={this.state.max_weight}/>
              </div>
              <div className="data-row">
                <div>Peso Promedio</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="average_weight"
                       value={this.state.average_weight}/>
              </div>
              <div className="data-row">
                <div>Peso Minimo</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="min_weight"
                       value={this.state.min_weight}/>
              </div>
              <div className="data-row">
                <div>Tiempo de expiración (dias)</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="expire_time"
                       value={this.state.expire_time}/>
              </div>
              <div className="data-row">
                <div>Tiempo de producción</div>
                <input onChange={this.handleChange.bind(this)} type="text" name="lead_time"
                       value={this.state.lead_time}/>
              </div>
              <div className="data-row">
                <div>Tipo de Producto</div>
                <select name="product_type_id" onChange={this.handleChange.bind(this)}>
                  <option value={this.state.product_type_id}>
                    {this.state.data['product_type'].filter((obj) => obj.id === this.state.product_type_id)[0] ?
                      this.state.data['product_type'].filter((obj) => obj.id === this.state.product_type_id)[0]['description'] : ''}
                  </option>
                  {this.state.data['product_type'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="data-row">
                <div>Sub-tipo de Producto</div>
                <select name="product_subtype_id" onChange={this.handleChange.bind(this)}>
                  <option value={this.state.product_subtype_id}>
                    {this.state.data['product_subtype'].filter((obj) => obj.id === this.state.product_subtype_id)[0] ?
                      this.state.data['product_subtype'].filter((obj) => obj.id === this.state.product_subtype_id)[0]['description'] : ''}
                  </option>
                  {this.state.data['product_subtype'].map((obj) =>
                    <option key={obj.id} value={obj.id}>{obj['description']}</option>
                  )}
                </select>
              </div>
              <div className="show-data-multi-select modal-multi">
                <div className="modal-multi-label">Categoria de ventas</div>
                <Select onChange={this.handleCategoryMultiselect.bind(this)}
                        defaultValue={this.state.selectedCategories}
                        name="category_ids" isMulti options={this.state.notSelectedCategories}
                        className="multi-select-input"
                        closeMenuOnSelect={false}/>
              </div>
              <div className="show-data-multi-select modal-multi">
                <div className="modal-multi-label">Sub-categoria de ventas</div>
                <Select onChange={this.handleSubCategoryMultiselect.bind(this)}
                        defaultValue={this.state.selectedSubCategories}
                        name="subcategory_ids" isMulti options={this.state.notSelectedSubCategories}
                        className="multi-select-input"
                        closeMenuOnSelect={false}/>
              </div>
              <div className="data-row">
                <div>Insumo</div>
                <select name="is_consumable" onChange={this.handleChange.bind(this)}>
                  <option value={this.state.is_consumable}>{this.state.is_consumable === true ? 'Si' : 'No'}</option>
                  <option value="false">No</option>
                  <option value="true">Si</option>
                </select>
              </div>
            </div>
          </div>
          }
          {this.state.isFetching === true &&
          <div className="scroll-section">
            <p>Obteniendo datos</p>
          </div>
          }
        </div>
        }
        {this.state.numberOfPage === 2 &&
        <div>
          <div>
            <span className="modal-close-btn" onClick={this.passEditState.bind(this)}>&times;</span>
          </div>
          <div className="modal-header">
            <h1>Crear producto nuevo</h1>
            <input onClick={this.handleCreateProduct.bind(this)} className="save-modal" value='Guardar' type="submit"/>
            <button className="back-modal" onClick={this.handleNextPage.bind(this)} type="button">
              <FontAwesomeIcon className="white float-left" icon={faArrowLeft}/>Atras
            </button>
          </div>
          {this.state.isFetching === false &&
          <div className="scroll-section">
            <div>
              <div><span><b>Paso 2 de 2: </b></span>Seleccione la(s) lista(s) de precio que desea asignarle al producto.
              </div>
            </div>
            {this.state.showWarningPage2 &&
            <div className="red">Por favor llene todos los campos precio y nombre de lista de precio para crear
              producto.</div>
            }
            <div id="price-lists-block">
              {this.state.price_lists.map((data, index) =>
                <AddPriceListBlock key={index} options={this.setOptionsForMissingPriceLists()}
                                   row={index} totalVisibleRows={this.state.price_lists.length}
                                   deleteRow={this.deletePriceListBlock.bind(this)} data={data}
                                   updatePriceListPrice={this.updatePriceListPrice.bind(this)}
                                   updatePriceListId={this.updatePriceListId.bind(this)}
                                   price_list={this.state.data['price_lists'].filter((obj) => parseInt(data['price_list_id']) === parseInt(obj.id))[0]}/>
              )}
            </div>
            <button onClick={this.addNewPriceListBlock.bind(this)} className="mt-33 float-left add-price-list-button"
                    type="button">
              <span className="fs-20">+</span> Agregar lista de precio
            </button>
          </div>
          }
          {this.state.isFetching === true &&
          <div className="scroll-section">
            <p>Obteniendo datos</p>
          </div>
          }
        </div>
        }
      </div>
    )
  }
}
