import React from 'react';
import {DeliveryDetailConsumer} from '../context/DeliveryDetailContext'
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import Email from "../../components/inputs/EmailInput";

class DeliveryUserDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertState: 'alert alert-success'
    }
  }

  getFormattedDate = (createdDate) => {
    let d = new Date(createdDate);
    return d.toLocaleString()
  };

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  };

  render() {
    return (
      <DeliveryDetailConsumer>
        {({deliverer, isFetching, isEditing, handleUpdateDeliverer, formSubmitUpdate, formControl, changeHandler, failedCreation}) => (
          <div>
            {isEditing === false && !isFetching &&
            <div className='show-area-tab'>
              <div className="show-header">
                <h2>Detalles del Entregador:</h2>
                <div className="show-buttons">
                  <input type="submit" onClick={handleUpdateDeliverer} value='Actualizar detalles'/>
                </div>
              </div>
              <hr className="mt-70"/>
              <div>
                <div className="show-data">
                  <div>Activo</div>
                  <div>{deliverer.active ? "Si" : "No"}</div>
                </div>
                <div className="show-data">
                  <div>Nombre de entregador:</div>
                  <div>{deliverer.db_ref} - {deliverer.name}</div>
                </div>
                <div className="show-data">
                  <div>Número de rutero/Código:</div>
                  <div>{deliverer.db_ref}</div>
                </div>
                <div className="show-data">
                  <div>Correo Electrónico:</div>
                  <div>{deliverer.email}</div>
                </div>
                <div className="show-data">
                  <div>Celular:</div>
                  <div>{deliverer.mobile}</div>
                </div>
                <div className="show-data">
                  <div>Creado</div>
                  <div>{this.getFormattedDate(deliverer.created_at)}</div>
                </div>
                <div className="show-data">
                  <div>Actualizado</div>
                  <div>{this.getFormattedDate(deliverer.updated_at)}</div>
                </div>
              </div>
            </div>
            }
            {isEditing === true &&
            <div className="">
              <div className='show-area-tab'>
                <div className="create-promo-wrapper">
                  <form
                    onSubmit={formSubmitUpdate}>
                    <div className="inline-block full-width">
                      <div className="float-left">
                        <h4>Detalles del entregador</h4>
                        <p style={{color: 'red'}}>Campos obligatorios*</p>
                      </div>
                      <div className="float-right">
                        <button className="cancel-button"
                                value='Cancelar'
                                onClick={handleUpdateDeliverer}> Cancelar
                        </button>
                        <input className="save-button" value="Guardar" type="submit"/>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Activo:</label>
                      <div className="col-sm-8">
                        <SelectInput name="active"
                                     value={formControl.active.value}
                                     onChange={changeHandler}
                                     options={formControl.active.options}
                                     touched={formControl.active.touched}
                                     valid={formControl.active.valid}
                        />
                      </div>
                    </div>


                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-3 col-form-label">Nombre de entregador<span
                        className="red">*</span>:</label>
                      <div className="col-sm-8">
                        <TextInput name="name"
                                   inputstyle="form-control"
                                   placeholder={formControl.name.placeholder}
                                   value={formControl.name.value}
                                   touched={formControl.name.touched.toString()}
                                   valid={formControl.name.valid.toString()}
                                   onChange={changeHandler}
                        />
                      </div>
                    </div>

                    <div className="form-group row show-data-form">
                      <label htmlFor="inputCode" className="col-sm-3 col-form-label">Número de
                        rutero/Código:</label>
                      <div className="col-sm-8">
                        <div>
                          {formControl.code.value}
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="inputCode" className="col-sm-3 col-form-label">Correo electrónico<span
                        className="red">*</span>:</label>
                      <div className="col-sm-8">
                        <Email name="email"
                               inputstyle="form-control"
                               placeholder={formControl.email.placeholder}
                               value={formControl.email.value}
                               touched={formControl.email.touched.toString()}
                               valid={formControl.email.valid.toString()}
                               onChange={changeHandler}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="inputCode" className="col-sm-3 col-form-label">Celular (opcional)</label>
                      <div className="col-sm-8">
                        <TextInput name="phone"
                                   inputstyle="form-control"
                                   type="number"
                                   placeholder={formControl.phone.placeholder}
                                   value={formControl.phone.value}
                                   touched={formControl.phone.touched.toString()}
                                   valid={formControl.phone.valid.toString()}
                                   onChange={changeHandler}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            } {isFetching === true &&
          <div className="index-table">
            <div className='show-area-tab'>
              <p style={{textAlign: 'center'}}>Obteniendo datos ...</p>
            </div>
          </div>

          }
          </div>

        )}
      </DeliveryDetailConsumer>
    )
  };
}

export default DeliveryUserDetailView;
