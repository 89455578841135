import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faLock } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import SuccessResetPasswordModal from "./SuccessResetPasswordModal";
import { resetPassword } from "../../services/apiUserService";

const ResetPasswordModal = (props) => {

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .required('El campo es requerido'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'El valor en los campos de Contraseña y Reescribir Contraseña deben de ser iguales')
      .required('El campo es requerido'),
  });

  const {
    register, handleSubmit, reset, formState: {errors},
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [showModal, setShowModal] = useState(true);
  const [showSuccessResetPasswordModal, setShowSuccessResetPasswordModal] = useState(false);
  const [resetPasswordData, setResetPasswordData] = useState({});

  const handleCloseResetModal = () => {
    setShowModal(false);
    props.closeMainModal()
  }

  function onSubmit(data) {
    const user = {
      email: props.user.salesman_email,
      password: data.password,
      password_confirmation: data.confirmPassword
    }
    resetPassword(user)
      .then(r => r.json().then(data => {
        setShowModal(false)
      }))
      .then(obj => console.log(obj))
      .catch(e => {
        setShowModal(false)
        console.log(e)
      });
    setResetPasswordData(data)
    setShowModal(false);
    setShowSuccessResetPasswordModal(true)
  }

  return (
    <div>
      {showSuccessResetPasswordModal &&
      <SuccessResetPasswordModal password={resetPasswordData.password}
                                 email={props.user.salesman_email}
                                 id={props.user.id}
                                 handleCloseResetModal={handleCloseResetModal}
      />
      }
      <Modal
        onHide={handleCloseResetModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        show={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="title-green-blue-icon text-align-center"><FontAwesomeIcon icon={faLock}/>
              <b className="ft-20">Cambiar contraseña a usuario</b>
            </p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{padding: '30px'}}>
          <div>
            <div className="container-fluid modal-container">
              <div className="form-row">
                <p><b>Aviso:</b> Al cambiar la contraseña va a sacar al usuario de la aplicación inmediatamente.</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Nueva contraseña:</label>
                    <input name="password" type="password" {...register('password')}
                           style={{width: '200px'}}
                           className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
                    <div className="invalid-feedback">{errors.password?.message}</div>
                  </div>
                  <div className="form-group col">
                    <label>Reescribir contraseña:</label>
                    <input name="confirmPassword" type="password" {...register('confirmPassword')}
                           style={{width: '200px'}}
                           className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}/>
                    <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                  </div>
                </div>
                <br/>
                <div className="form-group">
                  <div className="col-12 float-right">
                    <button
                      onClick={handleCloseResetModal}
                      className="shadow-cancel-button">Cancelar
                    </button>
                    <input type="submit" className="btn float-right ubq-modal-button"
                           value="Cambiar contraseña"/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ResetPasswordModal