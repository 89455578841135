import React, { createContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { getBusiness, getQueryState, handleErrors } from "../services/apiUserService";
import validate from "../../helpers/validation";
import * as apiStatusCodes from "../../constants/apiStatusCodes";
import cookie from "react-cookies";

const initialState = {}

const UserFlagCreateContext = createContext(initialState)

const UserFlagCreateProvider = (props) => {

  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [formIsValid, setFormIsValid] = useState(false)

  const [isFetching, setFetching] = useState(true)
  const [businessData, setBusinessData] = useState({})
  const [formMessage, setFormMessage] = useState('')

  const [configFormControls, setConfigFormControls] = useState(
    {
      can_access_refunds_app: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      can_report_no_sales: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      can_see_client_location: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      can_change_prices: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      can_change_refund_prices: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      can_set_prices_on_quote_items: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      has_fixed_route: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      can_consolidate_orders: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      can_see_customer_segment: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      enable_fiscal_printer: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
      can_select_discount: {
        value: "false",
        placeholder: '',
        valid: true,
        touched: false,
        options: [
          {value: true, displayValue: 'Si'},
          {value: false, displayValue: 'No'}
        ]
      },
    }
  )

  useEffect(() => {
    getBusinessConfig()
  }, [])

  const getBusinessConfig = () => {
    setFetching(true)
    let queryParams = getQueryState()
    getBusiness()
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setBusinessData(data)
      })
      .catch((error) => {
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', {path: '/'})
          window.location = '/'
        }
      })
  }

  const changeConfigHandler = event => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedControls = {
      ...configFormControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };

    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    setConfigFormControls(updatedControls)
    setFormIsValid(formIsValid)

  };

  return (
    <UserFlagCreateContext.Provider
      value={{
        configFormControls,
        failedCreationInfo,
        formIsValid,
        businessData,
        formMessage,
        changeConfigHandler,
      }}
    >
      {props.children}
    </UserFlagCreateContext.Provider>
  )
}
export { UserFlagCreateContext, UserFlagCreateProvider }
