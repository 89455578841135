import React, { useContext, useState } from 'react'
import NavBar from "../layout/NavBar";
import UserStepProgressBar from "./component/UserStepProgressBar";
import CreateUserInfoDetailStep from "./steps/CreateUserInfoDetailStep";
import CreateUserConfigDetailStep from "./steps/CreateUserConfigDetailStep";
import { SubmitCreateUserContext } from "./context/SubmitCreateUserProvider";

const CreateUserConfigStep = (props) => {

  const {failedCreationInfo} = useContext(SubmitCreateUserContext)

  const step1Content = <CreateUserInfoDetailStep/>;
  const step2Content = <CreateUserConfigDetailStep/>

  function onFormSubmit() {
  }

  return (
    <>
      <div>
        <div className="main-view">
          {failedCreationInfo !== null &&
          <div className="alert alert-danger" role="alert">
            <strong>{failedCreationInfo}</strong>
          </div>
          }
          <div className="main-show-header">
            <h2>Crear Usuario</h2>
          </div>
          <div>
            <div className="index-table">
              <div className='show-area-tab'>
                <UserStepProgressBar
                  startingStep={0}
                  onSubmit={onFormSubmit}
                  steps={[
                    {
                      label: 'Detalles',
                      name: 'Detalles',
                      content: step1Content,
                    },
                    {
                      label: 'Configuraciones',
                      name: 'Configuraciones',
                      content: step2Content,
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <NavBar activeItem='Usuarios' isEditing={true}/>
        </div>
        )}
      </div>
    </>

  )
}
export default CreateUserConfigStep