import React from 'react';
import {DispatchConsumer} from "../context/OrderContext";
import DispatchDetails from "../component/DispatchDetails";
import DispatchUpdateDetails from "../component/DispatchUpdateDetails";
import CreateDispatchProvider from "../context/CreateDispatchProvider";

export default class DeliveryDetailsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryDetails: this.props.deliveryDetails,
      orderDetails : this.props.orderDetails
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({deliveryDetails: nextProps.deliveryDetails});
  }

  getFormattedDate = (createdDate) => {
    let d = new Date(createdDate);
    return d.toLocaleString()
  };

  render() {
    return (
      <DispatchConsumer>
        {({dispatchDetailState}) => (
          <div>

            {dispatchDetailState &&
            <DispatchDetails deliveryDetails={this.state.deliveryDetails} transactionUuid={this.props.transactionUuid}/>
            }

            {!dispatchDetailState &&
            <div>
              <CreateDispatchProvider
                position={this.props.position}
                deliveryDetails={this.state.deliveryDetails}
                orderDetails={this.state.orderDetails}>
                <DispatchUpdateDetails/>
              </CreateDispatchProvider>
            </div>
            }

          </div>
        )}
      </DispatchConsumer>
    )
  };
}
