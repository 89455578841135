import React from "react";

const ProductQuantityInput = (props) => {

  const productQuantity = (e) => {
    props.handleProductQuantity(e);
  };

  const keyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  return (
    <input required onChange={productQuantity}
           className="goal-quantity-input"
           value={props.quantity}
           name="quantity" id={props.id} step="1" min="1" type="number" placeholder="0"
           autoComplete="off"
           onKeyPress={keyPress}/>
  )
};

export default ProductQuantityInput
