import React from 'react';
import cookie from 'react-cookies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      failedAuthentication: false,
      hasLoggedOut: false,
      hasResetPassword: false,
      showPassword: false,
    };
  }

  componentDidMount() {
    this.isUserLoggedIn()
    this.setSuccessfulMessage()
  }

  isUserLoggedIn = () => {
    fetch(BACKEND_URL + '/users/current', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookie.load('token')
      }
    })
      .then(response => response.json())
      .then(data => {
          // if current delivery_user is already logged in server returns {"delivery_user":{"id":2,"email":"test@example.com"}}
          if (Object.keys(data).indexOf('user') >= 0) {
            window.location = '/productos'
          }
        }
      )
      .catch(e => console.log(e));
  };

  // check if delivery_user has logged out in order to display alert message of successful logout
  setSuccessfulMessage = () => {
    if (window.location.href.indexOf('logout') >= 0) {
      this.setState({hasLoggedOut: true, failedAuthentication: false, hasResetPassword: false});
      setTimeout(this.hideMessages, 7000)
    } else if (window.location.href.indexOf('reset_done') >= 0) {
      this.setState({hasLoggedOut: false, failedAuthentication: false, hasResetPassword: true});
      setTimeout(this.hideMessages, 7000)
    }
  };

  hideMessages = () => {
    this.setState({hasLoggedOut: false, failedAuthentication: false, hasResetPassword: false})
  };

  handleChange(event) {
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  login = event => {
    event.preventDefault()
    fetch(BACKEND_URL + '/users/sign_in', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: {
          email: this.state.email,
          password: this.state.password
        }
      })
    })
      .then(response => {
          if (response.status === 401 || response.status === 500) {
            this.setState({failedAuthentication: true, hasLoggedOut: false, hasResetPassword: false});
            setTimeout(this.hideMessages, 7000)
          } else {
            this.setState({failedAuthentication: false, hasLoggedOut: false, hasResetPassword: false});
            setTimeout(this.hideMessages, 7000)
          }
          return response.json()
        }
      ).then(json => this.evaluateResponse(json))
      .catch(e => console.log(e));
  };

  evaluateResponse = (json) => {
    // save token
    console.log(json)
    if (json['token']) {
      cookie.save('business_tenant_uid', json['business_tenant_uid'], {path: '/'});
      cookie.save('token', json['token'], {path: '/'});
      cookie.save('business_name', json['business_name'], {path: '/'});
      cookie.save('business_url', json['business_url'], {path: '/'});
      cookie.save('user_name', json['user_name'], {path: '/'});
      cookie.save('user_email', this.state.email, {path: '/'});
      cookie.save('user_role', json['user_role'], {path: '/'});
      window.location = '/productos'
    }
  };

  toggleEye = event => {
    this.setState({showPassword: !this.state.showPassword});
  }

  render() {
    return (
      <div>
        {this.state.failedAuthentication === true &&
        <div className="alert alert-danger" role="alert">
          <strong>Correo electrónico o contraseña inválidos.</strong>
        </div>
        }
        {this.state.hasLoggedOut === true &&
        <div className="alert alert-success" role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <strong>Cerraste sesión correctamente</strong>
        </div>
        }
        {this.state.hasResetPassword === true &&
        <div className="alert alert-success" role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <strong>Su contraseña ha sido cambiada exitosamente. Ya puede iniciar sesión.</strong>
        </div>
        }
        <div className="login-container">
          <div className="login-section">
            <img alt="UBIQUA" src="/ubiqua-logo-top.png"/>
            <h2 className="login-header">Bienvenidos! Por favor ingresar a su perfil</h2>
            <form onSubmit={this.login.bind(this)}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-input">
                  <input onChange={this.handleChange.bind(this)} type="email" className="form-control" name="email"
                         placeholder="Correo Electronico" required/>
                </div>
                <div className="login-input">
                  <input onChange={this.handleChange.bind(this)} type={this.state.showPassword ? "text" : "password"}
                         className="form-control" name="password" placeholder="Contraseña" autoComplete="off" required/>
                  {this.state.showPassword === false &&
                  <span onClick={this.toggleEye.bind(this)} className="icon-eye"><FontAwesomeIcon className="icon-eye"
                                                                                                  icon={faEye}/></span>
                  }
                  {this.state.showPassword === true &&
                  <span onClick={this.toggleEye.bind(this)} className="icon-eye"><FontAwesomeIcon className="icon-eye"
                                                                                                  icon={faEyeSlash}/></span>
                  }
                </div>
                <div className="form-check">
                  <input className="login-button" value="Iniciar sesión" type="submit"/>
                </div>
                <br/>
                <br/>
                <a className="change-password-link" href="/cambiar-contrasena">Cambiar contraseña</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
