import React, {useState, useContext} from 'react'
import {CreateDispatchContext} from "../context/CreateDispatchContext";
import ConvertOrderForm from "../component/ConvertOrderForm";
import {DispatchConsumer, DispatchProvider} from "../context/OrderContext";
import {GlobalContext} from "../../global_context/GlobalContext";

function DispatchOrderTab( {isPosting, createText} ) {
  const {onCreateDispatch} = useContext(CreateDispatchContext);
  const {variants} = useContext(GlobalContext);
  return (
    <div>
      <DispatchConsumer>
        {({cancelCreate}) => (
          <div className="create-promo-wrapper">
            <form>
              <div className="row show-product-header">
                <div className="col-sm-6">
                  <h4>Ingrese los siguientes datos para crear un despacho</h4>
                  <p className="red">Campos Obligatorios *</p>
                </div>

                <div className="col-sm-6">
                  <div className="show-buttons">
                    { isPosting === false &&
                      <button className="cancel-button" onClick={cancelCreate} value='Cancelar'>
                        Cancelar
                      </button>
                    }
                    { isPosting === true &&
                      <input disabled onClick={(e) => onCreateDispatch(e, variants.isUseWeight)}
                        className="disabled-button save-button" value={createText} type="submit"/>
                    }
                    { isPosting === false &&
                      <input onClick={(e) => onCreateDispatch(e, variants.isUseWeight)}
                        className="save-button" value={createText} type="submit"/>
                    }
                  </div>
                </div>
              </div>
              <hr/>
            </form>
            <ConvertOrderForm/>
          </div>
        )}
      </DispatchConsumer>
    </div>
  )
}

export default DispatchOrderTab
