import React, {useState} from 'react';

import ConsolidationIndexView from "./ConsolidationIndexView";
import TopBar from "../../layout/TopBar";
import NavBar from "../../layout/NavBar";

function ConsolidationIndexApp() {

    const [userName] = useState('');
    const [searchPlaceholder] = useState('');
    const [title] = useState("Consolidaciones",);

    return (
        <div>
            <div>
                <div>
                    <TopBar searchPlaceholder={searchPlaceholder} userName={userName}/>
                </div>
                <div>
                    <ConsolidationIndexView title={title}/>
                </div>
            </div>
            <div>
                <NavBar activeItem='Reportes' isEditing={false}/>
            </div>
        </div>
    )
}

export default ConsolidationIndexApp
