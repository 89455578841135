import React from 'react';
import { motion, AnimatePresence } from "framer-motion";

const MapPoint = (props) => {
  const { color, name, isReport } = props;
  return (
    <div>
      { isReport &&
      <div>
        <div
          className="pin-client bounce"
          style={ { backgroundColor: color, cursor: 'pointer' } }
          title={ name }
        />
        <div className="dot"/>
        <span>
      <AnimatePresence exitBeforeEnter>
        { (props.$hover || props.hover) && <MarkerClientLocation { ...props } /> }
      </AnimatePresence>
        <motion.span whileTap={ { scale: 1.1 } } className="marker-indicator"/>
        </span>
      </div>
      }
      { !isReport &&
      <div>
        <div
          className="pin-activity bounce"
          style={ { backgroundColor: color, cursor: 'pointer' } }
          title={ name }
        />
        <div className="pulse"/>
        <span>
      <AnimatePresence exitBeforeEnter>
        { (props.$hover || props.hover) && <MarkerActivityLocation { ...props } /> }
      </AnimatePresence>
        <motion.span whileTap={ { scale: 1.1 } } className="marker-indicator"/>
        </span>

      </div>
      }
    </div>
  );
};


const markerVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
    transformOrigin: "center center",
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 0.1,
      delay: 0.2
    }
  },
  exit: {
    opacity: 0,
    scale: 0,
    transformOrigin: "center center",
    transition: {
      when: "afterChildren",
      staggerChildren: 0.05,
      staggerDirection: -1,
      delay: 0.1
    }
  }
};

export function MarkerActivityLocation() {
  return (
    <motion.div variants={ markerVariants } initial="hidden" animate="visible" exit="exit"
                className="sale-marker-info-wrapper">
      <motion.div className="place-marker-redirect-link">Captura de actividad</motion.div>

    </motion.div>
  );
}

export function MarkerClientLocation() {

  return (
    <motion.div variants={ markerVariants } initial="hidden" animate="visible" exit="exit"
                className="sale-marker-info-wrapper">
      <motion.div className="place-marker-redirect-link">Ubicación del cliente</motion.div>
    </motion.div>
  );
}

export default MapPoint;
