import React from 'react';
import cookie from 'react-cookies';
import ProductPriceListRow from './ProductPriceListRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faDollarSign } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ProductPriceListsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceListsData: null,
      isFetching: true,
      addNewPriceList: false,
      missingPriceLists: null,
      price: null,
      priceListId: null,
      invalidPriceListId: false
    }
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  fetchDataFromServer = () => {
    this.setState({isFetching: true});
    fetch(BACKEND_URL +  '/products/' + this.props.id + '/product_pricelist', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => response.json())
      .then(data => this.setState({ priceListsData: data}))
      .catch(e => console.log(e));
    fetch(BACKEND_URL +  '/products/' + this.props.id + '/product_pricelist/missing_pricelists', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => response.json())
      .then(data => this.setState({ missingPriceLists: data, isFetching: false}))
      .catch(e => console.log(e));
  };

  setOptionsForMissingPriceLists = () => {
    let items = [];
    this.state.missingPriceLists.map((obj) =>
      items.push(<option key={obj.id} value={obj.description + ' - ' + obj.db_ref} name={obj.description + ' - ' + obj.db_ref}>{obj.id}</option>)
    );
    return items;
  };

  startAddingNewPriceList = () => {
    this.setState({addNewPriceList: true})
  };

  cancelAddNewPriceList = () => {
    this.setState({addNewPriceList: false})
  };

  sendAddNewPriceList = (event) => {
    event.preventDefault();
    if (this.state.priceListId == null) {
      this.setState({invalidPriceListId: true})
    } else {
      this.setState({invalidPriceListId: false})
      fetch(BACKEND_URL + '/product_pricelist/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          price: this.state.price,
          product_id: this.props.id,
          price_list_id: this.state.priceListId
        })
      })
      .then(response => response.json())
      .then(data => window.location = '/productos/' + this.props.id + '/listas_de_precios')
      .catch(e => console.log(e));
    }
  }

  handleInputPriceChange(event){
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  handleSearchPriceList(event) {
    let element = document.getElementsByName(event.target.value)[0]
    if (element) {
      let price_list_id = element.innerText
      if (price_list_id) {
        this.setState({priceListId: price_list_id});
      }
    }
  }

  render() {
    return(
      <div>
        { this.state.addNewPriceList === true &&
          <form onSubmit={this.sendAddNewPriceList.bind(this)}>
            <div className="show-header show-product-header">
              <h2>Listas de precios asignados a producto:</h2>
              <div className="cancel-save-block">
                <input id="cancel-add" onClick={this.cancelAddNewPriceList.bind(this)} className="cancel-edit-button" value='Cancelar' type="submit" />
                <input value="Guardar" className="save-button" type="submit"/>
              </div>
            </div>
            <div className="add-price-list-block">
              <h2>Escoja la lista de precio y coloque el precio para asignarla a su producto:</h2>
              <div className="add-price-list-inputs">
                <div className="price-list-select">
                  <div>Lista de precio</div>
                  <input onChange={this.handleSearchPriceList.bind(this)} list="all-price-lists" placeholder="Escribir nombre o codigo" type="text" required/>
                  <span className="icon-search"><FontAwesomeIcon className="icon-search" icon={faSearch} /></span>
                  <datalist id="all-price-lists">
                    {this.state.isFetching === false && this.setOptionsForMissingPriceLists()}
                  </datalist>
                  {this.state.invalidPriceListId === true &&
                    <div className="red">Por favor selecione una lista de precio dentro de las opciones.</div>
                  }
                </div>
                <div className="price-select">
                  <div>Precio</div>
                  <span className="icon-dollar"><FontAwesomeIcon icon={faDollarSign} /></span>
                  <input required name="price" placeholder="0.00" step="0.000001" onChange={this.handleInputPriceChange.bind(this)} type="number"/>
                </div>
              </div>
            </div>
          </form>
        }
        { this.state.addNewPriceList === false &&
          <div className="show-header show-product-header">
            <h2>Listas de precios asignados a producto:</h2>
            <input onClick={this.startAddingNewPriceList.bind(this)} className="add-price-list-button" value='+  Agregar lista de precio' type="submit" />
          </div>
        }
        <div className="mt-50">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>CODIGO</th>
                <th>NOMBRE</th>
                <th >PRECIO</th>
                <th className="text-align-center">ACCION</th>
              </tr>
            </thead>
            <tbody>
              { this.state.isFetching === false && this.state.priceListsData['table'].length === 0 &&
                <tr>
                  <td colSpan="4">No hay listas de precio asignada a este producto.</td>
                </tr>
              }
              { this.state.isFetching === false && this.state.priceListsData['table'].length > 0 &&
                this.state.priceListsData['table'].map((obj) =>
                  <ProductPriceListRow key={obj.id} obj={obj} id={this.props.id}/>
                )
              }
              { this.state.isFetching === true &&
                <tr>
                  <td colSpan="4">Obteniendo datos...</td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>
    )
  };
}
