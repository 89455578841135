import React from 'react';
import NavBar from '../../layout/NavBar';
import TopBar from '../../layout/TopBar';
import ShowSalesmanVisitsView from './ShowSalesmanVisitsView';
import { MapModalProvider } from "../hook/MapModalProvider";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ShowSalesmanVisitsApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: 'Soporte',
      searchPlaceholder: "",
    };
  }

  render() {
    return (
      <div>
        <div>
          <div>
            <TopBar searchPlaceholder={ this.state.searchPlaceholder } userName={ this.state.userName }/>
          </div>
          <div>
            <ShowSalesmanVisitsView/>
          </div>
        </div>
        <div>
          <NavBar activeItem='Reportes' isEditing={ false }/>
        </div>
      </div>
    )
  }
}
