import React, { useContext, useState } from 'react'
import { UserInfoContext } from "../context/UserInfoProvider";
import { getFormattedDate } from "../services/apiUserService";
import ResetPasswordModal from "../component/modal/ResetPasswordModal";

const UserInfoDetailTab = (props) => {

  const {
    userInfoData,
    isEditingUserInfoTab,
    isFetching,
    handleUpdateUser,
    handleSubmit,
    errors,
    register,
    onSubmit
  } = useContext(UserInfoContext)

  const [showModal, setShowModal] = useState(false);

  const showResetPasswordModal = () => {
    setShowModal(true)
  }

  const closeMainModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <div>
        { showModal &&
        <ResetPasswordModal user={userInfoData} showModal={true} closeMainModal={closeMainModal}/>
        }
        {isEditingUserInfoTab === false && !isFetching &&
        <div className='show-area-tab'>
          <div className="show-header">
            <h2>Detalles de Usuario:</h2>
            <div className="show-buttons">
              <button
                onClick={showResetPasswordModal}
                className="shadow-cancel-button">Cambiar contraseña
              </button>
              <input type="submit" onClick={handleUpdateUser} value='Actualizar detalles'/>

            </div>
          </div>
          <hr className="mt-70"/>
          <div>
            <div className="show-data">
              <div>Activo</div>
              <div>{userInfoData.active ? "Si" : "No"}</div>
            </div>
            <div className="show-data">
              <div>Nombre de usuario:</div>
              <div>{userInfoData.name}</div>
            </div>
            <div className="show-data">
              <div>Tipo de usuario:</div>
              <div>App de Vendedor</div>
            </div>
            <div className="show-data">
              <div>Número de rutero/Código::</div>
              <div>{userInfoData.db_ref}</div>
            </div>
            <div className="show-data">
              <div>Correo Electrónico:</div>
              <div>{userInfoData.salesman_email}</div>
            </div>
            <div className="show-data">
              <div>Celular (opcional):</div>
              <div>{userInfoData.mobile}</div>
            </div>
            <div className="show-data">
              <div>Creado</div>
              <div>{getFormattedDate(userInfoData.created_at)}</div>
            </div>
            <div className="show-data">
              <div>Actualizado</div>
              <div>{getFormattedDate(userInfoData.updated_at)}</div>
            </div>
          </div>
        </div>
        }
        {isEditingUserInfoTab === true &&
        <div className="">
          <div className='show-area-tab'>
            <div className="">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="inline-block full-width">
                  <div className="float-left">
                    <h4>Detalles de Usuario</h4>
                    <p style={{color: 'red'}}>Campos obligatorios*</p>
                  </div>
                  <div className="float-right">
                    <button className="shadow-cancel-button"
                            value='Cancelar'
                            onClick={handleUpdateUser}> Cancelar
                    </button>
                    <input className="save-button" value="Guardar" type="submit"/>
                  </div>
                </div>
                <hr/>


                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Activo:</label>
                  <div className="col-sm-8">
                    <select name="active" {...register('active')}
                            className={`form-control ${errors.active ? 'is-invalid' : ''}`}>
                      <option value="true">Si</option>
                      <option value="false">No</option>
                    </select>
                    <div className="invalid-feedback">{errors.active?.message}</div>
                  </div>
                </div>


                <div className="form-group row show-data-form">
                  <label htmlFor="inputName" className="col-sm-3 col-form-label">Nombre de usuario<span
                    className="red">*</span>:</label>
                  <div className="col-sm-8">
                    <input name="name" type="text" {...register('name')}
                           className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                    <div className="invalid-feedback">{errors.name?.message}</div>
                  </div>
                </div>

                <div className="form-group row show-data-form">
                  <label htmlFor="inputCode" className="col-sm-3 col-form-label">Tipo de usuario:</label>
                  <div className="col-sm-8">
                    <div>
                      App de Vendedor
                    </div>
                  </div>
                </div>

                <div className="form-group row show-data-form">
                  <label htmlFor="inputCode" className="col-sm-3 col-form-label">Número de
                    rutero/Código:</label>
                  <div className="col-sm-8">
                    <div>
                      {userInfoData.db_ref}
                    </div>
                  </div>
                </div>

                <div className="form-group row show-data-form">
                  <label htmlFor="inputCode" className="col-sm-3 col-form-label">Correo electrónico<span
                    className="red">*</span>:</label>
                  <div className="col-sm-8">
                    <input name="email" type="text" {...register('email')}
                           className={`form-control ${errors.email ? 'is-invalid' : ''}`}/>
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </div>
                </div>


                <div className="form-group row">
                  <label htmlFor="inputCode" className="col-sm-3 col-form-label">Celular (opcional)</label>
                  <div className="col-sm-8">
                    <input name="mobile" type="number" {...register('mobile')}
                           className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}/>
                    <div className="invalid-feedback">{errors.mobile?.message}</div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
        } {isFetching === true &&
      <div className="index-table">
        <div className='show-area-tab'>
          <p style={{textAlign: 'center'}}>Obteniendo datos ...</p>
        </div>
      </div>

      }
      </div>
    </>
  )


}

export default UserInfoDetailTab