import React, { useEffect, useState } from 'react'
import validate from "../../helpers/validation";
import cookie from "react-cookies";
import * as apiService from "../../services/apiService";
import * as apiStatusCodes from "../../constants/apiStatusCodes";
import history from "../../helpers/history";

const { Provider, Consumer } = React.createContext();
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ClientEcommerceProvider = (props) => {
  const ecommerceFormValue = {
    name: {
      value: '',
      placeholder: '',
      valid: true,
      touched: false,
    },
    clientName: {
      value: '',
      placeholder: '',
      valid: true,
      touched: false,
      validationRules: {
        minLength: 1,
        isRequired: true
      }
    },
    active: {
      value: true,
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    code: {
      value: '',
      placeholder: '',
      valid: true,
      touched: false,
    },
    client: {
      value: '',
      placeholder: '',
      valid: true,
      touched: false,
    },
    email: {
      value: '',
      placeholder: '',
      valid: false,
      validationRules: {
        isRequired: true,
        isEmail: true
      },
      touched: false
    },
    phone: {
      value: '',
      placeholder: '',
      valid: true,
      touched: false,
    }
  }
  const ecommerceUpdateFormValue = {
    name: {
      value: '',
      placeholder: '',
      valid: true,
      touched: false,
      validationRules: {
        minLength: 1,
        isRequired: true
      }
    },
    phone: {
      value: '',
      placeholder: '',
      valid: true,
      touched: false,
    }
  }

  const [ eCommerceUserData, setECommerceUserData ] = useState([])
  const [ eCommerceUserCount, setECommerceUserCount ] = useState(0)
  const [ failedCreationInfo, setFailedCreationInfo ] = useState(null)
  const [ failedCreationState, setFailedCreationState ] = useState(false)
  const [ isFormCreateEditing, setFormCreateEditing ] = useState(false)
  const [ formIsValid, setFormIsValid ] = useState(false)
  const [ isFetching, setFetchingState ] = useState(true)
  const [ formControls, setFormControls ] = useState(ecommerceFormValue);
  const [ formUpdateControls, setUpdateFormControls ] = useState(ecommerceUpdateFormValue);

  const state = {
    eCommerceUserCount: 0,
    count: 0,
    clientDbRef: '',
    clientName: '',
    clientId: '',
    eCommerceUserData: [],
    fetchIsCommit: false,
    activeTab: 'Detalles',
    searchPlaceholder: "Buscar clientes por código o nombre",
    editingEcommerce: false,
    addingEcommerce: false,
    successCreationInfo: null,
    headerOrder: {
      title: 'Detalles de E-Commerce del cliente:',
      detail: '',
      optional: 'Campos obligatorios *'
    },
  };

  useEffect(() => {
    fetchDetailsFromServer()
  }, []);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (formIsValid) {
      fetch(BACKEND_URL + '/ecommerce_users/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: 1,
          client_id: props.id,
          email: formControls.email.value,
          name: formControls.name.value,
          mobile: formControls.phone.value,
          active: formControls.active.value
        })
      })
        .then(r => r.json().then(data => ({
          status: r.status,
          body: data,
        })))
        .then(obj => evaluateResponse(obj, true))
        .catch(e => console.log(e));
    } else {
      setFailedCreationInfo('Por favor introduzca los campos obligatorios')
      setFailedCreationState(true);
    }
  };

  const formSubmitUpdate = (event, user) => {
    event.preventDefault();
    if (formUpdateControls.name.valid) {
      fetch(BACKEND_URL + '/ecommerce_users/' + user.id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          client_id: props.id,
          name: formUpdateControls.name.value,
          email: user.email,
          mobile: formUpdateControls.phone.value,
          active: user.active,
        })
      })
        .then(r => r.json().then(data => ({ status: r.status, body: data })))
        .then(obj => evaluateResponse(obj, false))
        .catch(e => console.log(e));
    }
  };

  const formSubmitSuspend = (event, user) => {
    event.preventDefault();
    let val = window.confirm("Estas seguro que quieres proseguir con la suspension del usuario ecommerce? El usuario no podra acceder su cuenta.")
    if (val === true) {
      fetch(BACKEND_URL + '/ecommerce_users/' + user.id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          client_id: props.id,
          name: user.name,
          email: user.email,
          mobile: user.mobile,
          active: false,
        })
      })
        .then(r => r.json().then(data => ({ status: r.status, body: data })))
        .then(obj => evaluateResponse(obj, false))
        .catch(e => console.log(e));
    } else {
      event.preventDefault()
    }
  }

  const evaluateResponse = (obj, newClient) => {
    switch (obj.status) {
      case 422:
        setFailedCreationState(true);
        setFailedCreationInfo("EL Email ya esta en uso para otro cliente")
        break;
      case 409:
        setFailedCreationInfo(obj.body.message)
        setFailedCreationState(true);
        break;
      case 404:
        setFailedCreationInfo('Servidor fuera de servicio')
        setFailedCreationState(true);
        break;
      default:
        setFormCreateEditing(false)
        fetchDetailsFromServer()
        if (newClient) {
          history.push({
              pathname: '/clientes/' + props.id + '/ecommerce',
              params: { successCreate: true }
            }
          );
        } else {
          history.push({
              pathname: '/clientes/' + props.id + '/ecommerce',
            }
          );
        }
        break;
    }
  };

  const changeCreateHandler = event => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedControls = {
      ...formControls
    };
    const formElement = updateFormElements(name, value, updatedControls)

    setFormControls(formElement.updatedControls)
    setFormIsValid(formElement.formIsValid)
  };

  const changeUpdateHandler = event => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedControls = {
      ...formUpdateControls
    };
    const formElement = updateFormElements(name, value, updatedControls)

    setUpdateFormControls(formElement.updatedControls)
    setFormIsValid(formElement.formIsValid)
  };

  const updateFormElements = (name, value, updatedControls) => {

    const updatedFormElement = {
      ...updatedControls[name]
    };

    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }
    return {
      formIsValid,
      updatedControls
    }
  }

  const fetchDetailsFromServer = (dbRef, clientName, id) => {
    setFetchingState(true)
    apiService.getEcommerceUserDetails(props.id)
      .then(response => apiService.handleErrors(response))
      .then(response => response.json())
      .then(data => {
        setECommerceUserData(data.result)
        setECommerceUserCount(data.count)
        setFetchingState(false)
      })
      .catch(e => {
        console.log(e);
        if (e.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' });
          window.location = '/'
        }
      });
  };

  const handleEditCancelForm = (event, state) => {
    if (state === false) {
    } else {
      let val = window.confirm("Esta seguro que quiere continuar? Los cambios editados no seran guardados");
      if (val === true) {
        setFormControls(ecommerceFormValue)
        setFormCreateEditing(false)
      } else {
        event.preventDefault()
      }
    }
  };

  const handleCreateNewEcommerceClient = (event) => {
    event.preventDefault()
    setFormCreateEditing(true)
  };

  const handleUpdateState = (event, value) => {
    event.preventDefault()
    const formValues = { ...ecommerceUpdateFormValue }
    formValues.name.value = value.name
    formValues.phone.value = value.mobile
    setUpdateFormControls(formValues)
  }

  return (
    <Provider
      value={ {
        headerOrder: state.headerOrder,
        clientId: props.id,
        formControl: formControls,
        eCommerceUserCount: eCommerceUserCount,
        eCommerceUserData: eCommerceUserData,
        isFormCreateEditing: isFormCreateEditing,
        isFetching: isFetching,
        editForm: props.editForm,
        failedCreationInfo: failedCreationInfo,
        successCreationInfo: state.successCreationInfo,
        formUpdateControls: formUpdateControls,
        formSubmitHandler: formSubmitHandler,
        handleEditCancelForm: handleEditCancelForm,
        formSubmitUpdate: formSubmitUpdate,
        fetchDetailsFromServer: fetchDetailsFromServer,
        handleCreateNewEcommerceClient: handleCreateNewEcommerceClient,
        changeCreateHandler: changeCreateHandler,
        changeUpdateHandler: changeUpdateHandler,
        handleUpdateState: handleUpdateState,
        formSubmitSuspend: formSubmitSuspend,
        clientName: props.clientName,
        clientDbRef: props.dbRef,
      } }
    >
      { props.children }
    </Provider>
  )
}

export { ClientEcommerceProvider, Consumer as ClientEcommerceConsumer };
