import React from 'react';
import cookie from 'react-cookies';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class GlobalGoalSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      globalGoal: null,
      isFetching: false,
      isEditing: false,
      business_id: 1,
      selected_cycle_id: this.props.selected_cycle_id,
      salesman_id: this.props.salesman_id,
      goal: null
    }
  }

  // whenever cycle changes it does not render again but resetting editing mode
  shouldComponentUpdate(nextProps) {
    if ((this.props.selected_cycle_id !== nextProps.selected_cycle_id)) {
      this.setState({isEditing: false})
      return false
    } else {
      return true
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selected_cycle_id: nextProps.selected_cycle_id}, this.fetchDataFromServer);
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  fetchDataFromServer = () => {
    if (this.state.selected_cycle_id) {
      this.setState({isFetching: true});
      fetch(BACKEND_URL + '/businesses/' + this.state.business_id + '/cycles/' +
            this.state.selected_cycle_id + '/salesmen/' + this.state.salesman_id + '/global_goals',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookie.load('token')
          }
        })
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(obj => this.evaluateResponse(obj))
        .catch(e => console.log(e));
    }
  };

  evaluateResponse = (obj) => {
    let results = obj.body['table']
    let status = obj.status
    if (results.length === 0) {
      this.setState({globalGoal: null, isFetching: false});
    } else if (status === 401) {
      window.location = '/'
    } else {
      this.setState({globalGoal: results[0], isFetching: false});
    }
  };

  startEditing = (event) => {
    this.props.changeEditMode(true)
    this.setState({isEditing: true})
  };

  cancelEditing = (event) => {
    this.props.changeEditMode(false)
    this.setState({isEditing: false})
  };

  deleteGlobalGoal = (event) => {
    event.preventDefault();
    let val = window.confirm("Estás removiendo la cuota de venta global. Deseas continuar?");
    if (val === true) {
      fetch(BACKEND_URL + '/global_goals/' + this.state.globalGoal.uuid, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => response.json())
      .then(data => window.location = '/vendedores/' + this.state.salesman_id + '/cuotas/' + this.state.selected_cycle_id)
      .catch(e => console.log(e));
    }
  };

  updateGlobalGoal = (event) => {
    event.preventDefault();
    fetch(BACKEND_URL + '/global_goals/' + this.state.globalGoal.uuid, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        goal: this.state.goal
      })
    })
    .then(response => response.json())
    .then(data =>  window.location = '/vendedores/' + this.state.salesman_id + '/cuotas/' + this.state.selected_cycle_id)
    .catch(e => console.log(e));
  };

  createGlobalGoal = (event) => {
    event.preventDefault();
    fetch(BACKEND_URL + '/global_goals', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        business_id: this.state.business_id,
        cycle_id: this.state.selected_cycle_id,
        salesman_id: this.state.salesman_id,
        goal: this.state.goal
      })
    })
    .then(response => response.json())
    .then(data =>  window.location = '/vendedores/' + this.state.salesman_id + '/cuotas/' + this.state.selected_cycle_id)
    .catch(e => console.log(e));
  };

  handleChange = (event) => {
    event.preventDefault()
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  render() {
    return(
      <div>
        { this.state.globalGoal && this.state.isFetching == false &&
          <div>
            <div className="subheader">
              <b>Cuota de venta global:</b>
            </div>
            <div className="mt-33">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>CATEGORIA</th>
                    <th>CUOTA</th>
                    <th>UNIDAD</th>
                    <th className="text-align-center">ACCION</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.isEditing === false &&
                    <tr>
                      <td>Venta global</td>
                      <td>{Number(this.state.globalGoal['goal']).toFixed(0)}</td>
                      <td>DINERO</td>
                      <td className="text-align-center">
                        <div>
                          <input onClick={this.startEditing.bind(this)} className="edit-price-button" value='Actualizar' type="submit" />
                          <button onClick={this.deleteGlobalGoal.bind(this)} className="unassign-price-list-button" type="button"><FontAwesomeIcon icon={faTrash} /></button>
                        </div>
                      </td>
                    </tr>
                  }
                  {this.state.isEditing === true &&
                    <tr>
                      <td>Venta global</td>
                      <td>
                        <input required onChange={this.handleChange.bind(this)} className="goal-quantity-input"
                          name="goal" step="1" type="number" placeholder={Number(this.state.globalGoal['goal']).toFixed(0)} form="form1"/>
                      </td>
                      <td>
                        <select required form="form1" className="select-price-unit" onChange={this.handleChange.bind(this)}>
                          <option value="money">DINERO</option>
                        </select>
                      </td>
                      <td className="text-align-center">
                        <form id="form1" onSubmit={this.updateGlobalGoal.bind(this)}>
                          <div>
                            <input onClick={this.cancelEditing.bind(this)} id="cancel-edit" className="cancel-edit-price-button" value='Cancelar' type="submit" />
                            <input className="edit-price-button" type="submit" value="Guardar"/>
                          </div>
                        </form>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        }
        { this.state.globalGoal == null && this.state.isFetching == false &&
          <div>
            { this.state.isEditing == false &&
              <div className="show-data">
                <div className="subheader"><b>Cuota de venta global:</b></div>
                <div>No existe cuota actual</div>
                { this.state.selected_cycle_id &&
                  <input onClick={this.startEditing.bind(this)} value="Crear cuota" className="create-goal-button" type="submit"/>
                }
                { !this.state.selected_cycle_id &&
                  <input onClick={this.startEditing.bind(this)} value="Crear cuota" className="create-goal-button inactive" type="submit" disabled />
                }
              </div>
            }
            { this.state.isEditing == true &&
              <div>
                <div className="subheader">
                  <b>Cuota de venta global:</b>
                </div>
                <div className="mt-33">
                  <form onSubmit={this.createGlobalGoal.bind(this)}>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>CATEGORIA</th>
                          <th>CUOTA</th>
                          <th>UNIDAD</th>
                          <th className="text-align-center">ACCION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Venta global</td>
                          <td>
                            <input required onChange={this.handleChange.bind(this)}
                              className="goal-quantity-input" name="goal" step="1"
                              type="number" placeholder="0" />
                          </td>
                          <td>
                            <select required className="select-price-unit" onChange={this.handleChange.bind(this)}>
                              <option value="money">DINERO</option>
                            </select>
                          </td>
                          <td className="text-align-center">
                            <div>
                              <input onClick={this.cancelEditing.bind(this)} id="cancel-edit" className="cancel-edit-price-button" value='Cancelar' type="submit" />
                              <input className="edit-price-button" type="submit" value="Guardar"/>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  };
}
