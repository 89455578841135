import React from 'react';
import NavBar from '../layout/NavBar';
import ShowClientTab from "./tabs/ShowClientTab";
import ShowEcommerceClientTab from "./tabs/ShowEcommerceClientTab";
import { ClientConsumer } from "./context/ClientContext";
import { GlobalConsumer, GlobalProvider } from "./context/GlobalContext";
import UserHeader from "./components/UserHeader";
import { Link } from "react-router-dom";
import { Route } from "react-router-dom";
import { ClientEcommerceProvider } from "./context/ClientEcommerceContext";

export default class ShowClientApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dbRef: '',
      clientName: '',
      alertState: 'alert alert-success',
      id: this.props.client,
      activeTab: 'Detalles',
      searchPlaceholder: "Buscar clientes por código o nombre",
      isEditing: false,
      editingClient: false,
      addingClient: false,
      editGlobal: false
    };
  }

  handleSearch = event => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault();
      let query = event.target.value;
      if (query !== '') {
        if (this.state.isEditClient) {
          let val = window.confirm("Esta seguro que quiere continuar? Los cambios editados no seran guardados");
          if (val === true) {
            // redirecting to index accroding to clicked
            window.location = '/clientes?query=' + query
          }
        } else {
          window.location = '/clientes?query=' + query
        }
        event.target.value = ''
      }
    }
  };

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  };

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.editForm !== prevProps.editForm) {
      this.setState({ editGlobal: this.props.editForm });
    }
  }

  render() {
    const { match, client, editForm, successCreate, successCreateForNewClient } = this.props;
    const location = window.location.href.split('/')[window.location.href.split('/').length - 1];
    return (
      <ClientConsumer>
        { ({ clientData, isFetching, isEditClient }) => (
          <div>
            <div>
              <GlobalProvider
                stateEdit={ this.state.editGlobal }
                globalEdit={ isEditClient }
                location={ location }
              >
                <GlobalConsumer>
                  { ({ handleTabClick, isEditing, globalEdit, activeTab }) => {
                    return (
                      <div>
                        <div>
                          <div onKeyDown={ this.handleSearch }>
                            <UserHeader isFormEditing={ globalEdit }/>
                          </div>

                          <div className="main-view">
                            {
                              successCreate &&
                              <div className={ this.state.alertState } role="alert">
                                <button type="button" className="close" onClick={ this.hiddenAlert }
                                        aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <strong>El usuario fue creado exitosamente. Un correo ha sido enviado al usuario
                                  creado.</strong>
                              </div>
                            }

                            {
                              successCreateForNewClient &&
                              <div className={ this.state.alertState } role="alert">
                                <button type="button" className="close" onClick={ this.hiddenAlert }
                                        aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <strong>El cliente nuevo fue creado exitosamente.</strong>
                              </div>
                            }

                            <div className="main-show-header">
                              <h2>{ isFetching ? '' : `Client: ${ clientData.db_ref } - ${ clientData.customer_name_db_ref }` }  </h2>
                            </div>
                            {
                              !isFetching && (
                                <div className="index-table">
                                  <div className='show-area'>
                                    <div>

                                      <ul className="nav nav-tabs">
                                        <li
                                          className={ `${ activeTab === 'Detalles' ? 'active' : '' }` }>
                                          <Link onClick={ (event) => {
                                            handleTabClick(event, clientData)
                                          } } to={ `${ match }${ client }/detalles` }
                                                className={ activeTab !== 'Detalles' ? 'inactive-tab cursor-pointer' : 'cursor-pointer' }>Detalles</Link>
                                        </li>

                                        <li
                                          className={ activeTab === 'E-Commerce' ? 'active' : '' }>
                                          <Link onClick={ (event) => {
                                            handleTabClick(event, clientData)
                                          } } to={ `${ match }${ client }/ecommerce` }
                                                className={ activeTab !== 'E-Commerce' ? 'inactive-tab cursor-pointer' : 'cursor-pointer' }>E-Commerce</Link>
                                        </li>
                                      </ul>

                                      <div>
                                        <Route exact path={ `${ match }${ client }/detalles` }
                                               component={ ShowClientTab }/>
                                        <Route exact path={ `${ match }${ client }/ecommerce` }
                                               render={ () => {
                                                 return (
                                                   <ClientEcommerceProvider id={ clientData.id }
                                                                            clientName={ clientData.customer_name_db_ref }
                                                                            dbRef={ clientData.db_ref }>
                                                     <ShowEcommerceClientTab/>
                                                   </ClientEcommerceProvider>
                                                 )
                                               } }/>
                                      </div>

                                    </div>

                                  </div>
                                </div>)
                            }
                            {
                              isFetching &&
                              <div className="index-table">
                                <div className="mt-70">
                                  <div className="text-center">
                                    <br/>
                                    <p>Obteniendo datos de clientes ...</p>
                                  </div>
                                </div>
                              </div>

                            }
                          </div>
                        </div>
                        < div>
                          <NavBar activeItem='Clientes' isEditing={ this.state.editGlobal }/>
                        </div>
                      </div>
                    )
                  } }
                </GlobalConsumer>
              </GlobalProvider>
            </div>
          </div>
        ) }
      </ClientConsumer>
    )
  }
}
