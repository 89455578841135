import React, {Component} from 'react';
import cookie from "react-cookies";
import NavBar from "../layout/NavBar";
import DeliveryUserIndexView from "./component/DeliveryUserIndexView";
import RealPagination from "../layout/RealPagination";
import TopBar from "../layout/TopBar";
import * as apiService from "../services/apiService";
import * as apiStatusCodes from "../constants/apiStatusCodes";
import * as keyboardCodes from "../constants/keyboardCodes";
import {withRouter} from 'react-router-dom';

const DeliveryData = React.createContext();
export const DeliveryConsumer = DeliveryData.Consumer;

const PaginationData = React.createContext();
export const PaginationConsumer = PaginationData.Consumer;

class DeliveryUserIndex extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      per_page: 25,
      current_page: 1,
      total_pages: 1,
      isFetching: true,
      data: [],
      failedCreationState: false,
      failedCreationInfo: null,
      searchPlaceholder: "Buscar entregador por código o nombre",
      title: "Entregadores",
      query: '',
      paginationParams: {
        initLoad: [],
        getCurrentPage: this.getCurrentPage,
        totalCount: 0,
      }
    };
  };

  getCurrentPage = (page) => {
    this.myRef.current.scrollTo(0, 0);
    this.fetchDeliverers(page)
  };

  componentDidMount() {
    this.fetchDeliverers()
  }

  handleSearch = event => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === keyboardCodes.ENTER_KEY) {
      // to disbale submit form
      event.preventDefault();
      let query = event.target.value;
      if (query !== '') {
        window.location = '/entregadores?query=' + query;
      } else {
        window.location = '/entregadores'
      }
    }
  };

  fetchDeliverers = (page = 1) => {
    this.setState({isFetching: true});
    let queryParams = this.getQueryState();
    apiService.getAllDeliverersUsers(queryParams, page, this.state.per_page)
      .then(response => apiService.handleErrors(response))
      .then(response => response.json())
      .then(data => {
        this.setState({
          data: data['table'],
          totalCount: data['count'],
          isFetching: false,
          title: queryParams ? `Resultados de la búsqueda: ${queryParams}` : `Entregadores`
        })
      })
      .catch(error => {
        this.evaluateResponse(error);
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', {path: '/'});
          window.location = '/'
        }
      });
  };

  evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        this.setState({
          failedCreationState: true
        });
        setTimeout(this.hideFailedCreationMessage, 5000);
        break;
      case 404:
        this.setState({
          failedCreationInfo: 'Servidor fuera de servicio',
          failedCreationState: true
        });
        break;
      default:
        break;
    }
  };
  getQueryState = () => {
    if (window.location.href.indexOf('query') >= 0) {
      return decodeURI(window.location.href.split('query=')[window.location.href.split('query=').length - 1])
    } else {
      return ''
    }
  };

  handleCreateNewDeliverUser = () => {
    this.props.history.push('/crear_entregadores');
  };

  render() {
    return (
      <div>
        <div onKeyDown={this.handleSearch}>
          <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
        </div>
        <DeliveryData.Provider value={this.state}>
          <DeliveryConsumer>
            {({failedCreationState, failedCreationInfo}) => (
              <div>
                {
                  failedCreationState ? (
                      <div className="main-view">
                        <div className="alert alert-danger" role="alert">
                          <strong>{failedCreationInfo}</strong>
                        </div>
                      </div>) :
                    <div ref={this.myRef} className="main-view">
                      <div className="index-header">
                        <h2>{this.state.title}</h2>
                        <div className="index-buttons">
                          <button onClick={() => this.handleCreateNewDeliverUser()}>Crear entregador nuevo</button>
                        </div>
                      </div>
                      <DeliveryUserIndexView/>
                      <div className="container">
                        <div className="row">
                          <div className="center-block" style={{marginLeft: 70}}>
                            <PaginationData.Provider
                              value={this.state.paginationParams}>
                              <RealPagination
                                totalCount={this.state.totalCount}/>
                            </PaginationData.Provider>
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </div>
            )}
          </DeliveryConsumer>
        </DeliveryData.Provider>
        <NavBar activeItem='Equipo de campo' isEditing={false}/>
      </div>
    )
  }
}

export default withRouter(DeliveryUserIndex)