import React from 'react';
import cookie from 'react-cookies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faSearch } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class CategoryGoalRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      business_id: 1,
      isEditing: false,
      goal_category_id: this.props.goal_category_id,
      price_unit_id: this.props.price_unit_id,
      goal: null,
      category_goal: this.props.goal,
      salesman_id: this.props.salesman_id,
      selected_cycle_id: this.props.selected_cycle_id,
      priceUnits: this.props.priceUnits,
      goalCategories: this.props.goalCategories,
      showInvalidCategoryWarning: false
    }
  }

  // shouldComponentUpdate(nextProps) {
  //   if ((this.props.selected_cycle_id !== nextProps.selected_cycle_id)) {
  //     this.setState({isEditing: false})
  //     return false
  //   } else {
  //     return true
  //   }
  //
  // }

  componentWillReceiveProps(nextProps) {
    this.setState({ selected_cycle_id: nextProps.selected_cycle_id,
                    priceUnits: nextProps.priceUnits,
                    goalCategories: nextProps.goalCategories,
                  },
                  this.fetchDataFromServer);
  }

  startEditing = (event) => {
    this.props.changeEditMode(true)
    this.setState({isEditing: true})
  };

  cancelEditing = (event) => {
    this.props.changeEditMode(false)
    this.setState({isEditing: false, showInvalidCategoryWarning: false})
  };

  deleteGlobalGoal = (event) => {
    event.preventDefault();
    let val = window.confirm("Estás removiendo una cuota de venta por categoría. Deseas continuar?");
    if (val === true) {
      fetch(BACKEND_URL + '/category_goals/' + this.state.category_goal.uuid, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => response.json())
      .then(data => window.location = '/vendedores/' + this.state.salesman_id + '/cuotas/' + this.state.selected_cycle_id)
      .catch(e => console.log(e));
    }
  };

  updateGlobalGoal = (event) => {
    event.preventDefault();
    if (this.state.goal_category_id === null || this.state.price_unit_id === null || this.state.goal === null) {
      this.setState({showInvalidCategoryWarning: true})
    } else {
      this.setState({showInvalidCategoryWarning: false})
      fetch(BACKEND_URL + '/category_goals/' + this.state.category_goal.uuid, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          goal: this.state.goal,
          price_unit_id: this.state.price_unit_id,
          category_id: this.state.goal_category_id
        })
      })
      .then(response => response.json())
      .then(data =>  window.location = '/vendedores/' + this.state.salesman_id + '/cuotas/' + this.state.selected_cycle_id)
      .catch(e => console.log(e));
    }
  };

  createGlobalGoal = (event) => {
    event.preventDefault();
    if (this.state.goal_category_id === null || this.state.price_unit_id === null || this.state.goal === null) {
      this.setState({showInvalidCategoryWarning: true})
    } else {
      this.setState({showInvalidCategoryWarning: false})
      fetch(BACKEND_URL + '/category_goals', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: this.state.business_id,
          cycle_id: this.state.selected_cycle_id,
          salesman_id: this.state.salesman_id,
          goal: this.state.goal,
          price_unit_id: this.state.price_unit_id,
          category_id: this.state.goal_category_id
        })
      })
      .then(response => response.json())
      .then(data =>  window.location = '/vendedores/' + this.state.salesman_id + '/cuotas/' + this.state.selected_cycle_id)
      .catch(e => console.log(e));
    }
  };

  handleChange = (event) => {
    event.preventDefault()
    const obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  handleCategoriesSearch(event) {
    let name = event.target.value.split('-')[0].trim();
    let db_ref = event.target.value.split('-')[event.target.value.split('-').length - 1].trim();
    let obj = this.state.goalCategories.filter((obj) => obj.db_ref === db_ref && obj.name === name )[0];
    if (obj) {
      this.setState({goal_category_id: obj.id});
    }
  }

  cancelCreating = () => {
    this.props.cancelCreatingMode();
  }

  setOptionsForMissingCategories = () => {
    let items = [];
    this.state.goalCategories.map((obj) =>
      items.push(<option key={obj.id} value={obj.name + ' - ' + obj.db_ref}>{obj.id}</option>)
    );
    return items;
  };

  render() {
    if (this.state.category_goal && this.state.isEditing) {
      return(
        <tr>
          <td>{this.state.category_goal['goal_category']['name']} - {this.state.category_goal['goal_category']['db_ref']}
          {this.state.showInvalidCategoryWarning == true &&
            <div className="red">Por favor selecione una categoria dentro de las opciones.</div>
          }
          </td>
          <td>
            <input required onChange={this.handleChange.bind(this)} className="goal-quantity-input"
             name="goal" step="1" type="number" placeholder={Number(this.state.category_goal['goal']).toFixed(0)} form="form1"/>
          </td>
          <td>
            <select required name="price_unit_id" className="select-price-unit"
              onChange={this.handleChange.bind(this)} form="form1">
              <option value={this.state.price_unit_id}>
                {this.state.category_goal['price_unit']['description']}
              </option>
              { this.state.priceUnits.map((obj) =>
                <option key={obj.id} value={obj.id}>{obj['description']}</option>
              )}
            </select>
          </td>
          <td className="text-align-center">
            <form id="form1" onSubmit={this.updateGlobalGoal.bind(this)}>
              <div>
                <input onClick={this.cancelEditing.bind(this)} id="cancel-edit" className="cancel-edit-price-button" value='Cancelar' type="submit" />
                <input className="edit-price-button" type="submit" value="Guardar"/>
              </div>
            </form>
          </td>
        </tr>
      )
    } else if (this.state.category_goal && this.state.isEditing == false) {
      return(
        <tr>
          <td>{this.state.category_goal['goal_category']['name']} - {this.state.category_goal['goal_category']['db_ref']}</td>
          <td>{Number(this.state.category_goal['goal']).toFixed(0)}</td>
          <td>{this.state.category_goal['price_unit']['description']}</td>
          <td className="text-align-center">
            <div>
              <input onClick={this.startEditing.bind(this)} className="edit-price-button" value='Actualizar' type="submit" />
              <button onClick={this.deleteGlobalGoal.bind(this)} className="unassign-price-list-button" type="button">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </td>
        </tr>
      )
    } else if (this.state.category_goal == null) {
      return(
        <tr>
          <td>
            <input onChange={this.handleCategoriesSearch.bind(this)} list="missing-categories"
              placeholder="Escribir categoria" type="text" className="select-category" form="form1" required/>
            <span className="icon-dollar"><FontAwesomeIcon className="icon-search" icon={faSearch} /></span>
            <datalist id="missing-categories">
              {this.setOptionsForMissingCategories()}
            </datalist>
            {this.state.showInvalidCategoryWarning == true &&
              <div className="red">Por favor selecione una categoria dentro de las opciones.</div>
            }
          </td>
          <td>
            <input required onChange={this.handleChange.bind(this)} className="goal-quantity-input"
             name="goal" step="1" type="number" placeholder="0" form="form1"/>
          </td>
          <td>
            <select required name="price_unit_id" className="select-price-unit"
              onChange={this.handleChange.bind(this)} form="form1">
              <option hidden value="">Escoger...</option>
              { this.state.priceUnits.map((obj) =>
                <option key={obj.id} value={obj.id}>{obj['description']}</option>
              )}
            </select>
          </td>
          <td className="text-align-center">
            <form id="form1" onSubmit={this.createGlobalGoal.bind(this)}>
              <div>
                <input onClick={this.cancelCreating.bind(this)} id="cancel-edit" className="cancel-edit-price-button" value='Cancelar' type="submit" />
                <input className="edit-price-button" type="submit" value="Guardar"/>
              </div>
            </form>
          </td>
        </tr>
      )
    }
  }
}
