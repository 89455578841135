import React, { useContext, useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faArrowCircleDown, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import history from "../../helpers/history";
import Pagination from '../../layout/Pagination';
import { CSVLink } from "react-csv";
import { getCurrentDate, getEndDate, getStartDate, getTimestampFromDateTime } from "../../helpers/formatDate";
import { MapModalProvider, MapModalStore } from "../hook/MapModalProvider";
import { Button, Modal } from "react-bootstrap";
import ShowMapModalContent from "../charges/map/ShowMapModalContent";
import moment from "moment";
import { GlobalContext } from "../../global_context/GlobalContext";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default function ShowSalesmanVisitsView(props) {

  const [ start_date, setStartDate ] = useState(getStartDate())
  const [ end_date, setEndDate ] = useState(getEndDate)
  const [ data, setData ] = useState([])
  const [ isFetching, setFetching ] = useState(false)
  const [ salesmanName, setSalesmanName ] = useState('')
  const [ invalidRangeLimit, setInvalidRangeLimit ] = useState(false)
  const [ invalidRangeFormat, setInvalidRangeFormat ] = useState(false)
  const [ currentPage, setPageInfo ] = useState(1)
  const [ total_pages, setTotalPages ] = useState(1)
  const [ per_page, setPerPages ] = useState(40)
  const [ downloadData, setDownloadData ] = useState([])
  const [ show, setShow ] = useState(false);
  const [ dataSelected, setDataSelected ] = useState(false);

  const [ latitude, setLatitude ] = useState(false);
  const [ longitude, setLongitude ] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (model, event) => {
    console.log(model)
    event.preventDefault()
    setDataSelected(model);
    setLatitude(model.latitude);
    setLongitude(model.longitude);
    setShow(true);
  };

  useEffect(() => {
    async function fetchData() {
      const page = await setPaginationParamsState();
      await fetchDetailsFromServer(page);
    }

    fetchData().then(() => {
      setFetching(false);
    });
  }, [])

  const getSalesmanReferenceId = () => {
    return window.location.href.split('salesman_uuid=')[window.location.href.split('salesman_uuid=').length - 1].split('&')[0]
  }

  const salesman_uuid = getSalesmanReferenceId()

  const headers = [ 'Hora', 'Código', 'Cliente', 'Actividad', 'Monto', 'Ubicación Lat.', 'Ubicación Long.' ];

  const createText = 'Descargar reporte';

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = Math.floor(mins % 60);
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${ h }:${ m }`;
  };

  const setPaginationParamsState = async () => {
    if (window.location.href.indexOf('pagina') >= 0) {
      const page = window.location.href.split('pagina=')[window.location.href.split('pagina=').length - 1]
      setPageInfo(parseInt(page))
      return parseInt(page)
    } else {
      setPageInfo(1)
      return 1
    }
  }

  const fetchDownloadData = () => {
    fetch(BACKEND_URL + "/businesses/1/reports/visit_effectiveness/salesman?salesman_uuid="
      + salesman_uuid
      + "&start_date=" + start_date
      + "&end_date=" + end_date
      + "&business_tenant_uid=" + cookie.load('business_tenant_uid')
      + "&page=1"
      + "&per_page=10000"
      , {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        }
      )
      .then(data => {
        setDownloadData(data['details'])
      })
      .catch(e => console.log(e));
  };

  const fetchDetailsFromServer = async (page) => {
    setFetching(true)
    fetch(BACKEND_URL + "/businesses/1/reports/visit_effectiveness/salesman?salesman_uuid="
      + salesman_uuid
      + "&start_date=" + start_date
      + "&end_date=" + end_date
      + "&business_tenant_uid=" + cookie.load('business_tenant_uid')
      + "&page=" + page
      + "&per_page=" + per_page
      , {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response => {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        }
      )
      .then(data => {
        setData(data['details'])
        setSalesmanName(data.salesman_name)
        setTotalPages(setTotalPage(data))
        fetchDownloadData()
      })
      .catch(e => console.log(e));
  };

  const setTotalPage = (data) => {
    if (data['details'].length > 0) {
      return Math.ceil(data['details'][0]['total_count'] / per_page)
    }
  }

  const handlePaginationClick = event => {
    // <a> = when pagination elements were clicked
    console.log(event.target)
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  };

  const handlePagination = target => {
    if (target.id === 'first-page') {
      if (parseInt(currentPage) !== 1) {
        window.location = window.location + '&pagina=1'
      }
    } else if (target.id === 'last-page') {
      if (parseInt(currentPage) !== total_pages) {
        window.location = window.location + '&pagina=' + total_pages
      }
    } else if (target.id === 'previous-page') {
      if (parseInt(currentPage) !== 1) {
        window.location = window.location + '&pagina=' + (parseInt(currentPage) - 1)
      }
    } else if (target.id === 'next-page') {
      if (parseInt(currentPage) !== total_pages) {
        window.location = window.location + '&pagina=' + (parseInt(currentPage) + 1)
      }
    }
  };

  const showModel = (model, event) => {
    event.preventDefault()
    history.push({
        pathname: "/mapa",
        params: {
          salesmanName: salesmanName, latitude: model['latitude'],
          longitude: model['longitude'], hasAllowedLocation: model['has_allowed_location']
        }
      }
    );
  };

  const {} = useContext(MapModalStore)

  const formatActivityName = (activity_name, motive_text) => {
    if (activity_name === 'NO VENTA') {
      return activity_name + ': ' + motive_text;
    } else {
      return activity_name;
    }
  }

  const buildData = () => {
    let array = downloadData.map((a) => [ a.created_date, a.client_db_ref, a.client_name, formatActivityName(a.activity_name, a.motive_text), a.subtotal, a.latitude, a.longitude ])
    array.splice(0, 0, headers)
    array.splice(0, 0, [])
    array.splice(0, 0, [ 'Fecha', start_date + " - " + end_date ])
    array.splice(0, 0, [ 'Vendedor', salesmanName ])
    array.splice(0, 0, [ 'Supervisor', cookie.load('user_name') ])
    array.splice(0, 0, [ 'Reporte:', 'Efectividad de Visitas' ])
    return array
  }

  const searchFromDates = (event) => {
    event.preventDefault()
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const diff_in_time = endDate.getTime() - startDate.getTime();
    // To calculate the no. of days between two dates
    const diff_in_days = diff_in_time / (1000 * 3600 * 24);
    // Validations
    if (startDate > endDate) {
      setInvalidRangeFormat(true)
      setInvalidRangeLimit(false)
    } else if (diff_in_days > 90) {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(true)
    } else {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      window.location.href = '/visitas_por_vendedor?salesman_uuid=' + salesman_uuid + "&start_date=" + start_date + "&end_date=" + end_date
    }
  }

  const selectStartDate = (event) => {
    event.preventDefault()
    setStartDate(event.target.value)
  };

  const selectEndDate = (event) => {
    event.preventDefault()
    setEndDate(event.target.value)
  };

  const {
    shouldViewTransactionInfo,
  } = useContext(GlobalContext);


  const showTransactionModel = (event, orderId, activityName) => {
    event.preventDefault();
     const isOrder = activityName === 'PEDIDO' || activityName === 'PEDIDO ANULADO'
    if (shouldViewTransactionInfo && isOrder) {
      window.location = '/orden/' + orderId
    }
  };

  if (isFetching === false) {
    return (
      <MapModalProvider salesmanVisitData={ data }>
        <div className="main-view">
          { invalidRangeLimit &&
          <div className="alert alert-danger" role="alert">
            <strong>ERROR: Rango de fechas escogidas pasan de los 90 días. Por favor escoger otra fecha.</strong>
          </div>
          }
          { invalidRangeFormat &&
          <div className="alert alert-danger" role="alert">
            <strong>ERROR: Fecha de inicio debe ser antes que la fecha de fin.</strong>
          </div>
          }
          <div className="index-header">
            <h2 className="mb-20 float-unset">Reporte: Efectividad de visitas - { salesmanName }</h2>
          </div>
          <div className="index-table">
            <div className='show-area'>
              <div className="range-dates">
                <span style={ { color: '#4D4845', fontSize: '18px', marginRight: '15px' } }>Desde</span>
                <input max={ getCurrentDate() } onChange={ selectStartDate }
                       defaultValue={ start_date } className="select-date-report" type="date"
                       name="start_date" required/>
                <span style={ { color: '#4D4845', fontSize: '18px', marginRight: '15px' } }> - Hasta</span>
                <input max={ getCurrentDate() } onChange={ selectEndDate }
                       defaultValue={ end_date } className="select-date-report" type="date"
                       name="end_date" required/>
                <button onClick={ searchFromDates } className="search-range-dates-btn">Buscar</button>
                <CSVLink className="btn btn-default download-report-button" data={ buildData() }
                         filename={ "ReporteEfectividadVisita_" + cookie.load('business_name') + "_" + salesmanName + "_" + getCurrentDate() + ".csv" }>
                  { createText }<FontAwesomeIcon icon={ faArrowCircleDown }/>
                </CSVLink>
              </div>
              <h5><FontAwesomeIcon icon={ faExclamationCircle }/>Nota: Solo puedes escoger un rango de fechas de un
                maximo de 90 dias.</h5>
              <hr/>
              <div className="show-header">
                <div className="col-xs-6">
                  <h2>Detalles de reporte:</h2>
                </div>
              </div>
              <hr className="mt-90"/>
              <div>
                <table className="table table-hover">
                  <thead>
                  <tr>
                    <th>HORA</th>
                    <th>CODIGO</th>
                    <th>CLIENTE</th>
                    <th>ACTIVIDAD</th>
                    <th>MONTO</th>
                    <th>UBICACIÓN</th>
                  </tr>
                  </thead>
                  <tbody>
                  { data.length > 0 &&
                  data.map((model, index) =>
                    <tr key={ index }>
                      <td onClick={ (e) => {
                        showTransactionModel(e, model['order_id'], model['activity_name'])
                      } }>{ getTimestampFromDateTime(model['created_date']) }</td>
                      <td onClick={ (e) => {
                        showTransactionModel(e, model['order_id'], model['activity_name'])
                      } }>{ model['client_db_ref'] }</td>
                      <td onClick={ (e) => {
                        showTransactionModel(e, model['order_id'], model['activity_name'])
                      } }>{ model['client_name'] }</td>
                      <td onClick={ (e) => {
                        showTransactionModel(e, model['order_id'], model['activity_name'])
                      } } style={ model['activity_name'] === 'PEDIDO ANULADO' || model['activity_name'] === 'DEVOLUCIÓN ANULADA' ? {
                        maxWidth: '300px',
                        color: '#FF2323'
                      } : { maxWidth: '300px' } }>
                        { formatActivityName(model['activity_name'], model['motive_text']) }
                      </td>
                      { model['total'] != null &&
                      <td onClick={ (e) => {
                        showTransactionModel(e, model['order_id'], model['activity_name'])
                      } }>{ model['subtotal'] == null ? '$' + parseFloat(model['total']).toFixed(2) :'$' + parseFloat(model['subtotal']).toFixed(2) }</td>
                      }
                      { model['total'] == null &&
                      <td/>
                      }
                      <td>
                        <button onClick={ (e) => {
                          handleShow(model, e)
                        } } className="unassign-price-list-button" type="button">
                          <FontAwesomeIcon icon={ faMapMarkedAlt }/>
                        </button>
                      </td>
                    </tr>
                  )
                  }
                  { data.length === 0 &&
                  <tr>
                    <td colSpan="7">No hubo resultados</td>
                  </tr>
                  }
                  </tbody>
                </table>
                <nav onClick={ handlePaginationClick }>
                  { total_pages > 1 &&
                  <Pagination current_page={ currentPage } total_pages={ total_pages }/>
                  }
                </nav>
              </div>
              <>
                <Modal
                  show={ show }
                  dialogClassName="modal-90w"
                  onHide={ handleClose }
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      <p>Reporte de efectividad de visita - { salesmanName }</p>
                      <p>Detalles de captura de actividad</p>
                      <p style={ { marginLeft: 10, fontSize: 14 } }>Cliente: { dataSelected.client_name }</p>
                      <p style={ { marginLeft: 10, fontSize: 14 } }>Fecha y
                        hora: { moment(dataSelected.created_date).format('L') } - { moment(dataSelected.created_date).format('LT') }</p>
                      <p style={ { marginLeft: 10, fontSize: 14 } }>Actividad: { dataSelected.activity_name }</p>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div id="map-canvas">
                      <ShowMapModalContent longitude={ longitude } latitude={ latitude }
                                           clientLatitude={ dataSelected.client_latitude }
                                           clientLongitude={ dataSelected.client_longitude }
                                           salesmanVisitData={ dataSelected } hasAllowedLocation={ dataSelected.has_allowed_location }
                                           errorCoordinatesCode={dataSelected.error_coordinates_code}/>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose }>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </div>
          </div>
        </div>
      </MapModalProvider>
    )
  } else {
    return (
      <div className="main-view">
        <div className="show-title">
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  }
}
