import React from 'react'
import { ClientEcommerceConsumer } from "../context/ClientEcommerceContext";
import TextInput from "../../components/inputs/TextInput";
import Email from "../../components/inputs/EmailInput";

const CreateEcommerceForm = () => {

  return (
    <ClientEcommerceConsumer>
      { ({ formControl, failedCreationInfo, changeCreateHandler }) => {
        return (
          <div>
            {
              failedCreationInfo !== null &&
              <div className="container-fluid">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <div className="alert alert-danger" role="alert">
                      <p>{ failedCreationInfo }</p>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="container-fluid font-w-normal">
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmailEc">Correo Electronico
                      <span
                        className="red">*</span></label>
                    <Email name="email"
                           inputstyle="form-control"
                           placeholder={ formControl.email.placeholder }
                           value={ formControl.email.value }
                           touched={ formControl.email.touched.toString() }
                           valid={ formControl.email.valid.toString() }
                           onChange={ changeCreateHandler }
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputNameEc">Nombre
                      <span
                        className="red">*</span></label>
                    <TextInput name="name"
                               inputstyle="form-control"
                               placeholder={ formControl.name.placeholder }
                               value={ formControl.name.value }
                               touched={ formControl.name.touched.toString() }
                               valid={ formControl.name.valid.toString() }
                               onChange={ changeCreateHandler }
                    />
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputAddress">Número de contácto</label><span> (opcional)</span>
                  <TextInput name="phone"
                             type="number"
                             inputstyle="form-control"
                             placeholder={ formControl.phone.placeholder }
                             value={ formControl.phone.value }
                             touched={ formControl.phone.touched.toString() }
                             valid={ formControl.phone.valid.toString() }
                             onChange={ changeCreateHandler }
                  /></div>
              </form>
            </div>
            <div className="mb-20"/>

          </div>
        )
      } }
    </ClientEcommerceConsumer>
  )
}

export default CreateEcommerceForm
