import React from 'react';
import Pagination from '../layout/Pagination';

export default class PriceListIndexView extends React.Component {

  showModel = event => {
    event.preventDefault();
    let id = event.target.id;
    window.location = '/listas_de_precios/' + id
  };

  render() {
    let data = this.props.data;

    if (this.props.isFetching) {
      return (
        <div className="main-view">
          <div className="index-header">
            <br/>
            <p>Obteniendo datos ....</p>
          </div>
        </div>)
    } else {
      return (
        <div className="main-view">
          <div className="index-header">
            <h2 className="mb-20">{this.props.title}</h2>
          </div>
          <div className="index-table">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>CÓDIGO</th>
                  <th>NOMBRE DE LISTA DE PRECIO</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((model, index) =>
                    <tr className="cursor-pointer" key={index}>
                      <td id={model['id']} onClick={this.showModel}>{model['db_ref']}</td>
                      <td id={model['id']} onClick={this.showModel}>{model['name']}</td>
                    </tr>
                  )
                }
                {data.length === 0 &&
                  <tr>
                    <td>No hubo resultados</td>
                  </tr>
                }
              </tbody>
            </table>
              <nav>
                { this.props.total_pages >  1 &&
                  <Pagination current_page={this.props.current_page} total_pages={this.props.total_pages} />
                }
              </nav>
          </div>
        </div>
      );
    }
  };
}
