import React from 'react';
import Pagination from '../layout/Pagination';

export default class SalesmanIndexView extends React.Component {
  handleShowSalesmanDetails = event => {
    event.preventDefault();
    let id = event.target.id;
    window.location = '/vendedores/' + id + '/detalles';
  };

  handleCreateNewSalesmanClick() {
    window.location = '/crear_vendedor';
  }

  render() {
    let salesmenData = this.props.data;

    if (this.props.isFetching) {
      return (
        <div className="main-view">
          <div className="index-header">
            <br/>
            <p>Obteniendo datos ....</p>
          </div>
        </div>)
    } else {
      return (
        <div className="main-view">
          <div className="index-header">
            <h2>{this.props.title}</h2>
            <div className="index-buttons">
              <button onClick={() => this.handleCreateNewSalesmanClick()}>Crear vendedor nuevo</button>
            </div>
          </div>
          <div className="index-table">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>CÓDIGO</th>
                  <th>NOMBRE</th>
                  <th>CORREO ELECTRONICO</th>
                </tr>
              </thead>
              <tbody>
                {salesmenData.length > 0 ? (
                  salesmenData.map(salesman =>
                    <tr className="cursor-pointer" key={salesman.id}>
                      <td id={salesman['id']} onClick={this.handleShowSalesmanDetails}>
                        {salesman['db_ref']}
                      </td>
                      <td id={salesman['id']} onClick={this.handleShowSalesmanDetails}>
                        {salesman['name']}
                      </td>
                      <td id={salesman['id']} onClick={this.handleShowSalesmanDetails}>
                        {salesman['email']}
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td>No hubo resultados</td>
                  </tr>
                )}
              </tbody>
            </table>
              <nav>
                { this.props.total_pages >  1 &&
                  <Pagination current_page={this.props.current_page} total_pages={this.props.total_pages} />
                }
              </nav>
          </div>
        </div>
      );
    }
  };
}
