import React, { useContext, useState } from 'react';
import GoogleMapReact from "google-map-react";
import MapPoint from "../../map/MapPoint";
import { MapModalStore } from "../../hook/MapModalProvider";

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY || "AIzaSyAxo7saU4Ewn8PSMh8Nk4Y_m5vvC1LbrAQ";

const ShowMapModalContent = (props) => {

  const [ selectedPlace, setSelectedPlace ] = useState({})
  const [ activeMarker, setActiveMarker ] = useState({})
  const [ showingInfoWindow, setShowingInfoWindow ] = useState(false)
  const [ mapApiLoaded, setMapApiLoaded ] = useState(false);
  const [ mapInstance, setMapInstance ] = useState(null);
  const [ mapApi, setMapApi ] = useState(null);
  const [ bounds, setBounds ] = useState(null);
  const [ zoom, setZoom ] = useState(10);

  const [ reportLatitude ] = useState(props.latitude)
  const [ reportLongitude ] = useState(props.longitude)
  const [ clientLatitude ] = useState(props.clientLatitude)
  const [ clientLongitude ] = useState(props.clientLongitude)

  const { modalMapStyles } = useContext(MapModalStore)


  const center = {
    lat: parseFloat(reportLatitude) || 8.986984,
    lng: parseFloat(reportLongitude) || -79.518519
  };

  const centCircle = {
    lat: parseFloat(clientLatitude) || 8.986984,
    lng: parseFloat(clientLongitude) || -79.518519
  }


  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.latitude,
        place.longitude
      ));
    });
    return bounds;
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    {
      parseInt(clientLatitude) !== 0 &&
      new maps.Circle({
        strokeColor: "#7F7F7F",
        strokeOpacity: 0.4,
        strokeWeight: 2,
        fillColor: "#7F7F7F",
        fillOpacity: 0.3,
        map,
        center: centCircle,
        radius: 75
      });
    }

    let place = []
    if (parseInt(clientLatitude) !== 0) {
      place = [
        {
          latitude: reportLatitude,
          longitude: reportLongitude
        },
        {
          latitude: clientLatitude,
          longitude: clientLongitude
        }
      ]
    } else {
      place = [
        {
          latitude: reportLatitude,
          longitude: reportLongitude
        }
      ]
    }

    const bounds = getMapBounds(map, maps, place);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  const apiHasLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
    setMapApiLoaded(true);
  };

  const setZoomProvider = (zoom) => {
    setZoom(zoom)
  };

  const setBoundsProvider = (bounds) => {
    setBounds(bounds)
  };

  return (
    <>
      { props.latitude !== null && props.longitude !== null &&
      <GoogleMapReact
        bootstrapURLKeys={ {
          key: API_KEY
        } }
        onGoogleApiLoaded={ ({ map, maps }) => {
          map.setOptions({
            maxZoom: 18,
          });
          apiHasLoaded(map, maps);
          apiIsLoaded(map, maps)
        } }
        defaultCenter={ center }
        defaultZoom={ 18 }
        layerTypes={ [] }
        center={ mapApiLoaded ? mapInstance.getCenter().toJSON() : [ 1, 2 ] }
        options={ { styles: modalMapStyles } }
        onChange={ ({ zoom, bounds }) => {
          setZoomProvider(zoom);
          setBoundsProvider([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        } }
      >
        <MapPoint
          lat={ reportLatitude }
          lng={ reportLongitude }
          name="Captura de actividad"
          isReport={ false }
          color="red"
        />
        { parseInt(clientLatitude) !== 0 &&
        <MapPoint
          lat={ clientLatitude }
          lng={ clientLongitude }
          isReport={ true }
          name="Ubicación del cliente"
          color="green"
        />
        }
      </GoogleMapReact>
      }
      { props.errorCoordinatesCode === 1 && props.latitude === null && props.longitude === null &&
      <div className="map-modal-error">
        <div className="map-error-label">
          La geocoordenada no ha sido capturada (1).
        </div>
        <div className="map-error-label">
          El usuario ha denegado el acceso a la ubicación del equipo. Debe solicitar al usuario que habilite el acceso.
        </div>
      </div>
      }
      { props.errorCoordinatesCode === 2 && props.latitude === null && props.longitude === null &&
      <div className="map-modal-error">
        <div className="map-error-label">
          La geocoordenada no ha sido capturada (2).
        </div>
        <div className="map-error-label">
          Geocoordenadas no disponible. Hubo un fallo en uno, o mas, de los proveedores usados para la captura de posición.
        </div>
      </div>
      }
      { props.errorCoordinatesCode === 0 && props.latitude === null && props.longitude === null &&
      <div className="map-modal-error">
        <div className="map-error-label">
          La geocoordenada no ha sido capturada (0).
        </div>
        <div className="map-error-label">
          Hubo un error en el servicio de captura.
        </div>
      </div>
      }
      { props.errorCoordinatesCode === 3 && props.latitude === null && props.longitude === null &&
      <div className="map-modal-error">
        <div className="map-error-label">
          La geocoordenada no ha sido capturada (3).
        </div>
        <div className="map-error-label">
          Hubo un error en el servicio de captura.
        </div>
      </div>
      }
      { props.errorCoordinatesCode === 4 && props.latitude === null && props.longitude === null &&
      <div className="map-modal-error">
        <div className="map-error-label">
          La geocoordenada no ha sido capturada (4).
        </div>
        <div className="map-error-label">
          Hubo un error en el servicio de captura.
        </div>
      </div>
      }
      { props.latitude === null && props.longitude === null &&
        (props.errorCoordinatesCode === null || props.errorCoordinatesCode === undefined) &&
        <div className="map-modal-error">
          { props.hasAllowedLocation === false &&
            <div className="map-error-label">
              El usuario ha negado el envío de la ubicación de la actividad.
            </div>
          }
          { props.hasAllowedLocation === true &&
            <div className="map-error-label">
              Hubo un error al enviar ubicación de la actividad. Contacte a soporte@ubiqua.me para verificar esta información.
            </div>
          }
        </div>
      }
    </>
  )
}

export default ShowMapModalContent
