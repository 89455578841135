import React from 'react';
import {ClientConsumer} from "../context/ClientContext";
import {GlobalConsumer} from "../context/GlobalContext";

export default class ShowClientTab extends React.Component {

  state = {
    isClientEditing: this.props.isClientEditing,
    isFetching: false
  };

  getFormattedDate = (date) => {
    let d = new Date(date);
    return d.toLocaleString()
  };

  render() {
    if (this.state.isFetching === false) {
      return (
        <ClientConsumer>
          {({clientData, failedCreation, failedCreationInfo, handleClickButton, handleSubmitEditClient, handleChange, clientEditDetailsData}) => (
            <GlobalConsumer>
              {({handleEditingClient, globalEdit}) => (
                <div>
                  {globalEdit === false &&
                  <div>
                    <div className="show-product-header show-header">
                      <h2>Detalles del cliente</h2>
                      <div className="show-buttons">
                        <input type="submit" onClick={(event) => {
                          handleClickButton(event);
                          handleEditingClient(event)
                        }} value='Actualizar cliente'/>
                      </div>
                    </div>
                    <div>
                      <hr/>
                      <div className="pl-20">
                        <div>
                          <div className="show-data">
                            <div>Grupo de Cliente</div>
                            {clientData['client_group'] &&
                            <div>{clientData['client_group']['description']}</div>}
                          </div>
                          <div className="show-data">
                            <div>Canal de Ventas</div>
                            {clientData['sales_channel'] &&
                            <div>{clientData['sales_channel']['description']}</div>}
                          </div>
                          <div className="show-data">
                            <div>Tipo de Pago</div>
                            {clientData['payment_type'] &&
                            <div>{clientData['payment_type']['description']}</div>}
                          </div>
                          <div className="show-data">
                            <div>Plazo de Credito</div>
                            {clientData['credit_term'] &&
                            <div>{clientData['credit_term']['description']}</div>}
                          </div>
                          <div className="show-data">
                            <div>Provincia</div>
                            {clientData['province'] &&
                            <div>{clientData['province']['name']}</div>}
                          </div>
                          <div className="show-data">
                            <div>Distrito</div>
                            {clientData['district'] &&
                            <div>{clientData['district']['name']}</div>}
                          </div>
                          <div className="show-data">
                            <div>Corregimiento</div>
                            {clientData['town'] &&
                            <div>{clientData['town']['name']}</div>}
                          </div>
                          <div className="show-data">
                            <div>Lista de Precio que le aplica</div>
                            {clientData['price_list'] &&
                            <div>{clientData['price_list']['description']}</div>}
                          </div>
                          <div className="show-data">
                            <div>Codigo</div>
                            <div>{clientData['db_ref']}</div>
                          </div>
                          <div className="show-data">
                            <div>Activo</div>
                            {clientData['active'] === true && <div>Si</div>}
                            {clientData['active'] === false && <div>No</div>}
                          </div>
                          <div className="show-data">
                            <div>Ruc</div>
                            <div>{clientData['taxpayer_id']}</div>
                          </div>
                          <div className="show-data">
                            <div>DV</div>
                            <div>{clientData['taxpayer_verificator_digit']}</div>
                          </div>
                          <div className="show-data">
                            <div>Razon Social</div>
                            <div>{clientData['business_name']}</div>
                          </div>
                          <div className="show-data">
                            <div>Nombre Comercial</div>
                            <div>{clientData['commercial_name']}</div>
                          </div>
                          <div className="show-data">
                            <div>Nombre de cliente en sistema</div>
                            <div>{clientData['customer_name_db_ref']}</div>
                          </div>
                          <div className="show-data">
                            <div>Monto de Credito</div>
                            <div>{clientData['credit_limit']}</div>
                          </div>
                          <div className="show-data">
                            <div>Representante Legal</div>
                            <div>{clientData['legal_representative']}</div>
                          </div>
                          <div className="show-data">
                            <div>Persona de contacto para cobro de</div>
                            <div>{clientData['billing_contact_name']}</div>
                          </div>
                          <div className="show-data">
                            <div>Pago: correo electronico</div>
                            <div>{clientData['billing_contact_email']}</div>
                          </div>
                          <div className="show-data">
                            <div>Pago: Telefono</div>
                            <div>{clientData['billing_contact_phone']}</div>
                          </div>
                          <div className="show-data">
                            <div>Persona de contacto</div>
                            <div>{clientData['contact_name']}</div>
                          </div>
                          <div className="show-data">
                            <div>Correo electronico</div>
                            <div>{clientData['contact_email']}</div>
                          </div>
                          <div className="show-data">
                            <div>Telefono</div>
                            <div>{clientData['contact_phone']}</div>
                          </div>
                          <div className="show-data">
                            <div>Ciudad</div>
                            <div>{clientData['city']}</div>
                          </div>
                          <div className="show-data">
                            <div>Calle</div>
                            <div>{clientData['street']}</div>
                          </div>
                          <div className="show-data">
                            <div>Guia de Ubicacion</div>
                            <div>{clientData['location_description']}</div>
                          </div>
                          <div className="show-data">
                            <div>Coordenadas en grados decimales (latitud)</div>
                            <div>{clientData['geographical_latitude']}</div>
                          </div>
                          <div className="show-data">
                            <div>Coordenadas en grados decimales (longitud)</div>
                            <div>{clientData['geographical_longitud']}</div>
                          </div>
                          <div className="show-data hidden">
                            <div>Ruta asignada</div>
                            <div>{clientData['route_id']}</div>
                          </div>
                          <div className="show-data">
                            <div>Fecha de creación</div>
                            <div>{this.getFormattedDate(clientData['created_at'])}</div>
                          </div>
                          <div className="show-data">
                            <div>Fecha de actualización</div>
                            <div>{this.getFormattedDate(clientData['updated_at'])}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  {globalEdit === true &&
                  <div>
                    {failedCreation === true &&
                    <div className="show-product-header">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="alert alert-danger" role="alert">
                            <p>{failedCreationInfo}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    <div className="show-product-header show-header">
                      <h2>Actualizar detalles de
                        cliente: {clientData['db_ref'] + "-" + clientData['customer_name_db_ref']}</h2>
                    </div>
                    <div>
                      <div className='show-area'>
                        <form onSubmit={handleSubmitEditClient}>
                          <div className="show-header-edit">
                            <h2>Detalles del cliente:</h2>
                            <div className="show-buttons">
                              <input type="submit" onClick={handleEditingClient}
                                     className="cancel-edit-button"
                                     value='Cancelar'/>
                              <input type="submit" value='Guardar'/>
                            </div>
                          </div>
                          <hr/>
                          <div>
                            <div className="show-data">
                              <div>Grupo de Cliente</div>
                              <select name="client_group_id" onChange={handleChange}>
                                <option hidden>{clientData.client_group.description}</option>
                                <option value={null}/>
                                {clientEditDetailsData ? clientEditDetailsData.client_group.map((obj) =>
                                  <option key={obj.id} value={obj.id}>{obj['description']}</option>
                                ) : ''}
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Canal de Ventas</div>
                              <select name="sales_channel_id" onChange={handleChange}>
                                <option hidden>{clientData['sales_channel']['description']}</option>
                                <option value={null}/>
                                {clientEditDetailsData ? clientEditDetailsData['sales_channel'].map((obj) =>
                                  <option key={obj.id} value={obj.id}>{obj['description']}</option>
                                ) : ''}
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Tipo de Pago</div>
                              <select name="payment_type_id" onChange={handleChange}>
                                {!clientData['payment_type'] && <option hidden/>}
                                {clientData['payment_type'] &&
                                <option hidden>{clientData['payment_type']['description']}</option>}
                                <option value={null}/>
                                {clientEditDetailsData ? clientEditDetailsData['payment_type'].map((obj) =>
                                  <option key={obj.id} value={obj.id}>{obj['description']}</option>
                                ) : ''}
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Plazo de Credito</div>
                              <select name="credit_term_id" onChange={handleChange}>
                                {!clientData['credit_term'] && <option hidden/>}
                                {clientData['credit_term'] &&
                                <option hidden>{clientData['credit_term']['description']}</option>}
                                <option value={null}/>
                                {clientEditDetailsData ? clientEditDetailsData['credit_term'].map((obj) =>
                                  <option key={obj.id} value={obj.id}>{obj['description']}</option>
                                ) : ''}
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Provincia</div>
                              <select name="province_id" onChange={handleChange}>
                                <option hidden>{clientData['province']['name']}</option>
                                <option value={null}/>
                                {clientEditDetailsData ? clientEditDetailsData['province'].map((obj) =>
                                  <option key={obj.id} value={obj.id}>{obj['name']}</option>
                                ) : ''}
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Distrito</div>
                              <select name="district_id" onChange={handleChange}>
                                <option hidden>{clientData['district']['name']}</option>
                                <option value={null}/>
                                {clientEditDetailsData ? clientEditDetailsData['district'].map((obj) =>
                                  <option key={obj.id} value={obj.id}>{obj['name']}</option>
                                ) : ''}
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Corregimiento</div>
                              <select name="town_id" onChange={handleChange}>
                                <option hidden>{clientData['town']['name']}</option>
                                <option value={null}/>
                                {clientEditDetailsData ? clientEditDetailsData['town'].map((obj) =>
                                  <option key={obj.id} value={obj.id}>{obj['name']}</option>
                                ) : ''}
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Lista de Precio que le aplica<span className="red">*</span></div>
                              <select required name="price_list_id" onChange={handleChange}>
                                <option hidden>{clientData['price_list']['description']}</option>
                                <option value={null}/>
                                {clientEditDetailsData ? clientEditDetailsData['price_list'].map((obj) =>
                                  <option key={obj.id} value={obj.id}>{obj['description']}</option>
                                ) : ''}
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Codigo<span className="red">*</span></div>
                              <div>{clientData['db_ref']}</div>
                            </div>
                            <div className="show-data">
                              <div>Activo</div>
                              <select name="active" onChange={handleChange}>
                                {clientData['active'] === true && <option value={true} hidden>Si</option>}
                                {!clientData['active'] === true &&
                                <option value={false} hidden>No</option>}
                                <option value="true">Si</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                            <div className="show-data">
                              <div>Ruc</div>
                              <input type="text" onChange={handleChange} name="taxpayer_id"
                                     defaultValue={clientData['taxpayer_id']}/>
                            </div>
                            <div className="show-data">
                              <div>DV</div>
                              <input type="text" onChange={handleChange} name="taxpayer_verificator_digit"
                                     defaultValue={clientData['taxpayer_verificator_digit']}/>
                            </div>
                            <div className="show-data">
                              <div>Razon Social</div>
                              <input type="text" onChange={handleChange} name="business_name"
                                     defaultValue={clientData['business_name']}/>
                            </div>
                            <div className="show-data">
                              <div>Nombre Comercial</div>
                              <input type="text" onChange={handleChange} name="commercial_name"
                                     defaultValue={clientData['commercial_name']}/>
                            </div>
                            <div className="show-data">
                              <div>Nombre de cliente en sistema<span className="red">*</span></div>
                              <input type="text" onChange={handleChange} name="customer_name_db_ref"
                                     defaultValue={clientData['customer_name_db_ref']} required/>
                            </div>
                            <div className="show-data">
                              <div>Monto de Credito</div>
                              <input type="number" onChange={handleChange} name="credit_limit" step="0.01"
                                     max="999999" defaultValue={clientData['credit_limit']}/>
                            </div>
                            <div className="show-data">
                              <div>Representante Legal</div>
                              <input type="text" onChange={handleChange} name="legal_representative"
                                     defaultValue={clientData['legal_representative']}/>
                            </div>
                            <div className="show-data">
                              <div>Persona de contacto para cobro de</div>
                              <input type="text" onChange={handleChange} name="billing_contact_name"
                                     defaultValue={clientData['billing_contact_name']}/>
                            </div>
                            <div className="show-data">
                              <div>Pago: correo electronico</div>
                              <input type="text" onChange={handleChange} name="billing_contact_email"
                                     defaultValue={clientData['billing_contact_email']}/>
                            </div>
                            <div className="show-data">
                              <div>Pago: Telefono</div>
                              <input type="text" onChange={handleChange} name="billing_contact_phone"
                                     defaultValue={clientData['billing_contact_phone']}/>
                            </div>
                            <div className="show-data">
                              <div>Persona de contacto</div>
                              <input type="text" onChange={handleChange} name="contact_name"
                                     defaultValue={clientData['contact_name']}/>
                            </div>
                            <div className="show-data">
                              <div>Correo electronico</div>
                              <input type="text" onChange={handleChange} name="contact_email"
                                     defaultValue={clientData['contact_email']}/>
                            </div>
                            <div className="show-data">
                              <div>Telefono</div>
                              <input type="text" onChange={handleChange} name="contact_phone"
                                     defaultValue={clientData['contact_phone']}/>
                            </div>
                            <div className="show-data">
                              <div>Ciudad</div>
                              <input type="text" onChange={handleChange} name="city"
                                     defaultValue={clientData['city']}/>
                            </div>
                            <div className="show-data">
                              <div>Calle</div>
                              <input type="text" onChange={handleChange} name="street"
                                     defaultValue={clientData['street']}/>
                            </div>
                            <div className="show-data">
                              <div>Guia de Ubicacion</div>
                              <input type="text" onChange={handleChange} name="location_description"
                                     defaultValue={clientData['location_description']}/>
                            </div>
                            <div className="show-data">
                              <div>Coordenadas en grados decimales (latitud)</div>
                              <input type="text" onChange={handleChange} name="geographical_latitude"
                                     defaultValue={clientData['geographical_latitude']}/>
                            </div>
                            <div className="show-data">
                              <div>Coordenadas en grados decimales (longitud)</div>
                              <input type="text" onChange={handleChange} name="geographical_longitud"
                                     defaultValue={clientData['geographical_longitud']}/>
                            </div>
                            <div className="show-data hidden">
                              <div>Ruta asignada</div>
                              <input type="text" onChange={handleChange} name="route_id"
                                     defaultValue={clientData['route_id']}/>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  }
                </div>
              )}
            </GlobalConsumer>
          )}
        </ClientConsumer>
      )
    } else {
      return (
        <div className="main-view">
          <div className="show-title">
            <p>Obteniendo datos ...</p>
          </div>
        </div>
      )
    }
  };
}
