import React from 'react';

const { Provider, Consumer } = React.createContext();

class GlobalProvider extends React.Component {
  state = {
    editGlobal: false,
    ecommerceEdit: false,
    isEditing: false,
    activeTab: this.props.location === "detalles" ? "Detalles" : "E-Commerce",
    dbRef: '',
    customName: '',
    id: window.location.href.split('/')[window.location.href.split('/').length - 2],
  };

  // in order to change active state for tabs
  handleTabClick = (event) => {
    if (event.target.tagName === 'A') {
      if (this.state.editGlobal === false) {
        this.setState({ activeTab: event.target.innerText }, this.render);
      } else {
        let val = window.confirm("Esta seguro que quiere continuar? Los cambios editados no seran guardados");
        if (val === true) {
          this.setState({ activeTab: event.target.innerText }, this.render);
          this.setState({ editGlobal: false });
        } else {
          event.preventDefault()
        }
      }
    }
  };

  handleEditingClient = (event) => {
    if (event.target.value === 'Actualizar cliente') {
      this.setState({ editGlobal: true }, this.render);
    } else if (event.target.value === 'Cancelar') {
      let val = window.confirm("Esta seguro que quiere continuar? Los cambios editados no seran guardados");
      if (val === true) {
        this.setState({ editGlobal: false });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.globalEdit !== prevProps.globalEdit) {
      this.setState({ editGlobal: this.props.globalEdit });
    }
    if (this.props.stateEdit !== prevProps.stateEdit) {
      this.setState({ editGlobal: this.props.stateEdit });

    }
  }

  render() {
    return (
      <Provider
        value={ {
          globalEdit: this.state.editGlobal,
          dbRef: this.state.dbRef,
          customName: this.state.customName,
          activeTab: this.state.activeTab,
          handleTabClick: this.handleTabClick,
          handleEditingClient: this.handleEditingClient,
          isEditClient: this.state.isEditing
        } }
      >
        { this.props.children }
      </Provider>
    )
  }
}

export { GlobalProvider, Consumer as GlobalConsumer };
