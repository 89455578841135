import React from 'react';
import cookie from 'react-cookies';
import LiquidacionDetailsTab from './LiquidacionDetailsTab';
import LiquidacionInvoicesTab from './LiquidacionInvoicesTab';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ShowLiquidacionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      detailsData: null,
      id: window.location.href.split('/')[window.location.href.split('/').length - 1],
      activeTab: 'Detalles',
    }
  }

  componentDidMount() {
    this.fetchDetailsFromServer()
  }

  fetchDetailsFromServer = () => {
    this.setState({isFetching: true});
    fetch(BACKEND_URL +  '/closure_summaries/' + this.state.id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response =>
        {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        }
      )
      .then(data => this.setState({ detailsData: data, isFetching: false}))
      .catch(e => console.log(e));
  };

  // in order to change active state for tabs
  handleTabClick = event => {
    // if tab was clicked (tab is an <a> element)
    if (event.target.tagName === 'A') {
      this.setState({activeTab: event.target.innerText}, this.render)
    }
  };

  render() {
    if (this.state.isFetching === false) {
      return(
        <div className="main-view">
          <div className="main-show-header">
            <h2>Liquidación #{this.state.detailsData['closure_uid']}</h2>
          </div>
          <div className="index-table">
            <div className='show-area'>
              <div>
                <div onClick={this.handleTabClick}>
                  <ul className="nav nav-tabs">
                    <li className={`${this.state.activeTab === 'Detalles' ? 'active':''}`}>
                      <a className={this.state.activeTab !== 'Detalles' ? 'inactive-tab cursor-pointer': 'cursor-pointer'}>Detalles</a>
                    </li>
                    <li className={this.state.activeTab === 'Facturas' ? 'active':''}>
                      <a className={this.state.activeTab !== 'Facturas' ? 'inactive-tab cursor-pointer': 'cursor-pointer'}>Facturas</a>
                    </li>
                  </ul>
                </div>
                <div>
                  { this.state.activeTab === 'Detalles' &&
                    <div>
                      <LiquidacionDetailsTab detailsData={this.state.detailsData}/>
                    </div>
                  }
                  { this.state.activeTab === 'Facturas' &&
                    <div>
                      <LiquidacionInvoicesTab id={this.state.id}/>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main-view">
          <div className="show-title">
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    }
  };
}
