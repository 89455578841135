import React, {Fragment} from 'react';
import {DeliveryConsumer} from "../DeliveryUserIndex";
import {withRouter} from 'react-router-dom';

class DeliveryUserIndexView extends React.Component {

  constructor(props) {
    super(props);
  }

  handleShowDeliveryUserDetails = (event) => {
    event.preventDefault();
    let id = event.target.id;
    this.props.history.push('/entregadores/' + id);
  };

  render() {
    return (
      <DeliveryConsumer>
        {({data, isFetching}) => (
          <div>
            {isFetching && data.length === 0 ? (
              <div className="mt-70">
                <div className="text-center">
                  <br/>
                  <p>Obteniendo datos para entregador ....</p>
                </div>
              </div>) : (
              <Fragment>
                <div className="index-table">
                  <table className="table table-hover">
                    <thead>
                    <tr>
                      <th>CÓDIGO</th>
                      <th>NOMBRE</th>
                      <th>CORREO ELECTRONICO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.length > 0 ? (
                      data.map(deliverer =>
                        <tr className="cursor-pointer" key={deliverer.id}>
                          <td id={deliverer['id']} onClick={this.handleShowDeliveryUserDetails}>
                            {deliverer['db_ref']}
                          </td>
                          <td id={deliverer['id']} onClick={this.handleShowDeliveryUserDetails}>
                            {deliverer['name']}
                          </td>
                          <td id={deliverer['id']} onClick={this.handleShowDeliveryUserDetails}>
                            {deliverer['email']}
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td>No hubo resultados</td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </DeliveryConsumer>
    );
  };
}

export default withRouter(DeliveryUserIndexView)
