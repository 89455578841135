import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons'

export default class AddPriceListBlock extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: props.data,
      options: props.options,
      row: props.row,
      totalVisibleRows: props.totalVisibleRows,
      price_list: props.price_list
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ price_list: nextProps.price_list, row: nextProps.row, options: nextProps.options,
                    data: nextProps.data, totalVisibleRows: nextProps.totalVisibleRows});
  }

  render() {
    return(
      <div className="add-price-list-inputs">
        <div className="price-list-select">
          <div>Lista de precio</div>
          { this.state.price_list &&
            <input name="price_list_id" onChange={(e) => {this.props.updatePriceListId(this.state.row, e)}}
              list="all-price-lists" type="text"
              value={this.state.price_list['description'] + ' - ' + this.state.price_list['db_ref']}/>
          }
          { !this.state.price_list &&
            <input name="price_list_id" onChange={(e) => {this.props.updatePriceListId(this.state.row, e)}}
              list="all-price-lists" placeholder="Escribir nombre o codigo" type="text" />
          }
          <span className="icon-search"><FontAwesomeIcon className="icon-search" icon={faSearch} /></span>
          <datalist id="all-price-lists">
            {this.state.options}
          </datalist>
        </div>
        <div className="price-select">
          <div>Precio</div>
          <span className="icon-dollar"><FontAwesomeIcon icon={faDollarSign} /></span>
            <input onChange={(e) => {this.props.updatePriceListPrice(this.state.row, e)}} name="price"
            value={this.state.data['price']} placeholder="0.00" type="number" step="0.000001" autoComplete="off"/>
        </div>
        { this.state.totalVisibleRows > 1 &&
          <div>
            <button className="delete-price-list" onClick={() => this.props.deleteRow(this.state.row)} type="button">
              <FontAwesomeIcon className="delete-price-list-button" icon={faTrash}/>Borrar lista
            </button>
          </div>
        }
      </div>
    )
  }
}
