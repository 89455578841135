import React, { useContext } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import LoginPage from './login/LoginPage';
import ResetPasswordPage from './login/ResetPasswordPage';
import ConfirmNewPasswordPage from './login/ConfirmNewPasswordPage';
import ProductIndexApp from './product/ProductIndexApp';
import PromoIndexApp from './promo/PromoIndexApp';
import ClientIndexApp from './client/ClientIndexApp';
import OrderIndexApp from './order/OrderIndexApp';
import DeliveryEffectivityIndexApp from './report/invoices/DeliveryEffectivityIndexApp';
import VisitEffectivityIndexApp from './report/visits/VisitEffectivityIndexApp';
import PriceListIndexApp from './price_list/PriceListIndexApp';
import LiquidacionIndexApp from './liquidacion/LiquidacionIndexApp';
import SalesmanCreateNewApp from './salesman/SalesmanCreateNewApp';
import CreateNewClientApp from './client/CreateNewClientApp';
import SalesmanIndexApp from './salesman/SalesmanIndexApp';
import ShowProductApp from './product/ShowProductApp';
import ShowClientApp from './client/ShowClientApp';
import ShowPriceListApp from './price_list/ShowPriceListApp';
import ShowPromoApp from './promo/ShowPromoApp';
import ShowLiquidacionApp from './liquidacion/ShowLiquidacionApp';
import ShowOrderApp from './order/ShowOrderApp';
import ShowSalesmanApp from './salesman/ShowSalesmanApp';
import ShowDeliverInvoicesApp from './report/invoices/ShowDeliverInvoicesApp';
import ShowSalesmanVisitsApp from './report/visits/ShowSalesmanVisitsApp';
import ShowInvoiceApp from './report/invoices/ShowInvoiceApp';
import CreatePromoApp from './promo/CreatePromoApp';
import './css/index.css';
import './css/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateDeliveryUser from "./delivery_user/CreateDeliveryUser";
import DeliveryUserApp from "./delivery_user/DeliveryUserIndex";
import DeliveryUserDetail from "./delivery_user/DeliveryUserDetail";
import CreateDeliveryUserForm from "./delivery_user/component/CreateDeliveryUserForm";
import { ClientProvider } from "./client/context/ClientContext";
import history from './helpers/history';
import CreateOrderApp from "./order/CreateOrderApp";
import ProtectedRoute from "./ProtectedRoute";
import { GlobalContext } from "./global_context/GlobalContext";
import NotFoundPage from "./NoMatchPage";
import ChargeEffectivenessIndexApp from "./report/charges/ChargeEffectivenessIndexApp";
import ShowUserChargeReportVisitsApp from "./report/charges/details/ShowUserChargeReportVisitsApp";
import IndexUserConfig from "./config_user/IndexUserConfig";
import TabsUserConfig from "./config_user/TabsUserConfig";
import { UserStepTabsProvider } from "./config_user/context/UserStepTabsProvider";
import { UserInfoProvider } from "./config_user/context/UserInfoProvider";
import { UserConfigProvider } from "./config_user/context/UserConfigProvider";
import CreateUserConfigStep from "./config_user/CreateUserConfigStep";
import { UserConfigCreateProvider } from "./config_user/context/UserConfigCreateProvider";
import ConsolidationIndexApp from "./report/consolidation/ConsolidationIndexApp";
import validateAccessRole from "./helpers/userRole";
import { UserFlagCreateProvider } from "./config_user/context/UserFlagCreateProvider";
import { SubmitCreateUserProvider } from "./config_user/context/SubmitCreateUserProvider";
import ShowSimpleOrderApp from "./order/ShowSimpleOrderApp";

const App = () => {

  const {
    userRole,
    shouldCreateOrder,
    shouldViewCoreInfo,
    shouldViewTransactionInfo,
    configPermission,
    canViewReport,
    useAve,
    redirectPath
  } = useContext(GlobalContext);

  const consolidationPermission = userRole ? validateAccessRole(userRole.can_send_consolidation) : false

  return (
    <div>
      <Router history={history}>
        {shouldViewCoreInfo != null && userRole != null &&
        <Switch>

          <ProtectedRoute visible={configPermission} redirect={redirectPath} exact path="/usuarios"
                          component={IndexUserConfig}/>

          <ProtectedRoute visible={configPermission}
                          path="/usuarios/:id"
                          render={(props) => {
                            let userPosition = props.location.pathname.replace('/usuarios/', '');
                            return (
                              <UserInfoProvider user={userPosition}
                                                createUserParams={props.location.params ? props.location.params : null}>
                                <UserConfigProvider user={userPosition}>
                                  <UserStepTabsProvider>
                                    <TabsUserConfig
                                    />
                                  </UserStepTabsProvider>
                                </UserConfigProvider>
                              </UserInfoProvider>
                            )
                          }}/>

          <ProtectedRoute visible={configPermission}
                          exact path="/crear_usuario_configuracion"
                          render={() => {
                            return (
                              <UserConfigCreateProvider>
                                <UserFlagCreateProvider>
                                  <SubmitCreateUserProvider>
                                    <CreateUserConfigStep/>
                                  </SubmitCreateUserProvider>
                                </UserFlagCreateProvider>
                              </UserConfigCreateProvider>
                            )
                          }}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/productos"
                          component={ProductIndexApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/clientes"
                          component={ClientIndexApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/listas_de_precios"
                          component={PriceListIndexApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/liquidaciones"
                          component={LiquidacionIndexApp}/>

          {shouldViewCoreInfo &&
          <Route exact path="/promociones"
                 render={(props) => {
                   return (
                     <PromoIndexApp
                       successCreateForNewPromo={props.location.params ? props.location.params.successCreateForNewPromo : false}/>
                   )
                 }}/>
          }
          <ProtectedRoute visible={shouldViewTransactionInfo} redirect={redirectPath} exact path="/pedidos"
                          component={OrderIndexApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/vendedores"
                          component={SalesmanIndexApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/entregadores"
                          component={DeliveryUserApp}/>

          {shouldViewCoreInfo &&
          <Route path="/entregadores/:id"
                 render={(props) => {
                   let deliveryPosition = props.location.pathname.replace('/entregadores/', '');
                   return (
                     <DeliveryUserDetail
                       deliver={deliveryPosition}
                       successCreateForNewDeliverer={props.location.params ? props.location.params.successCreateForNewDeliverer : false}/>
                   )
                 }}/>
          }

          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/crear_vendedor"
                          component={SalesmanCreateNewApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/crear_cliente"
                          component={CreateNewClientApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/efectividad_de_entregas"
                          component={DeliveryEffectivityIndexApp}/>

          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/crear_entregadores"
                          component={CreateDeliveryUser}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/crear_ecommerce"
                          component={CreateDeliveryUserForm}/>

          {shouldViewCoreInfo &&
          <Route path="/vendedores/:id"
                 render={(props) => {
                   return (
                     <ShowSalesmanApp
                       variants={useAve}
                       successCreateForNewSalesman={props.location.params ? props.location.params.successCreateForNewSalesman : false}/>
                   )
                 }}/>
          }

          {shouldViewCoreInfo &&
          <Route
            path="/productos/:id"
            render={(props) => {
              return (
                <ShowProductApp
                  successCreateForNewProduct={props.location.params ?
                    props.location.params.successCreateForNewProduct : false}/>
              )
            }}/>
          }

          {shouldViewCoreInfo &&
          <Route exact path="/clientes/:id/edit_ecommerce"
                 render={() => {
                   let clientPosition = window.location.href.split('/')[window.location.href.split('/').length - 2];
                   return (
                     <ClientProvider>
                       <ShowClientApp
                         editForm={true}
                         match={'/clientes/'}
                         client={clientPosition}/>
                     </ClientProvider>
                   )
                 }}/>
          }

          {shouldViewCoreInfo &&
          <Route exact
                 path="/clientes/:id/create_ecommerce" render={(props) => {
            let clientPosition = window.location.href.split('/')[window.location.href.split('/').length - 2];
            return (
              <ClientProvider>
                <ShowClientApp
                  editForm={true}
                  match={'/clientes/'}
                  client={clientPosition}/>
              </ClientProvider>
            )
          }}/>
          }

          {shouldViewCoreInfo &&
          <Route exact path="/clientes/:id/detalles"
                 render={(props) => {
                   let clientPosition = window.location.href.split('/')[window.location.href.split('/').length - 2];
                   return (
                     <ClientProvider>
                       <ShowClientApp
                         successCreateForNewClient={props.location.params ? props.location.params.successCreateForNewClient : false}
                         editForm={false}
                         match={'/clientes/'}
                         client={clientPosition}/>
                     </ClientProvider>
                   )
                 }}/>
          }

          {shouldViewCoreInfo &&
          <Route exact path={"/clientes/:id/ecommerce"}
                 render={(props) => {
                   let clientPosition = window.location.href.split('/')[window.location.href.split('/').length - 2];
                   return (
                     <ClientProvider>
                       <ShowClientApp
                         successCreate={props.location.params ? props.location.params.successCreate : false}
                         editForm={false}
                         match={'/clientes/'}
                         client={clientPosition}/>
                     </ClientProvider>
                   )
                 }}/>
          }
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/listas_de_precios/:id"
                          component={ShowPriceListApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/facturas/:id"
                          component={ShowInvoiceApp}/>

          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/promociones/:id"
                          component={ShowPromoApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/liquidaciones/:id"
                          component={ShowLiquidacionApp}/>
          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/facturas_por_entregador"
                          component={ShowDeliverInvoicesApp}/>

          <ProtectedRoute visible={canViewReport} redirect={redirectPath} path="/visitas_por_vendedor"
                          component={ShowSalesmanVisitsApp}/>

          <ProtectedRoute visible={canViewReport} redirect={redirectPath} path="/visitas_de_cobro_por_usuario"
                          component={ShowUserChargeReportVisitsApp}/>

          <ProtectedRoute visible={canViewReport} redirect={redirectPath} exact path="/efectividad_de_visitas"
                          component={VisitEffectivityIndexApp}/>


          <ProtectedRoute visible={canViewReport} redirect={redirectPath} exact path="/efectividad_de_cobros"
                          component={ChargeEffectivenessIndexApp}/>

          <ProtectedRoute visible={consolidationPermission.can_read} redirect={redirectPath} exact
                          path="/consolidaciones"
                          component={ConsolidationIndexApp}/>

          <ProtectedRoute visible={shouldViewCoreInfo} redirect={redirectPath} exact path="/nueva_promo"
                          component={CreatePromoApp}/>

          <ProtectedRoute visible={shouldCreateOrder} redirect={redirectPath} exact path="/nuevo_pedido"
                          component={CreateOrderApp}/>

          <ProtectedRoute visible={shouldViewTransactionInfo} redirect={redirectPath} exact path="/pedidos/:id/:state"
                          component={ShowOrderApp}/>

          <ProtectedRoute visible={shouldViewTransactionInfo} redirect={redirectPath} exact path="/pedidos/:id"
                          component={ShowOrderApp}/>

          <ProtectedRoute visible={shouldViewTransactionInfo} redirect={redirectPath} exact path="/orden/:id"
                          component={ShowSimpleOrderApp}/>

          <Route exact path="/" component={LoginPage}/>
          <Route exact path="/reset_done" component={LoginPage}/>
          <Route exact path="/cambiar-contrasena" component={ResetPasswordPage}/>
          <Route path="/nueva-contrasena" component={ConfirmNewPasswordPage}/>
          <Route exact path="/logout" component={LoginPage}/>

          <Route render={() => {
            return (
              <NotFoundPage redirect={redirectPath}/>
            )
          }}/>
        </Switch>
        }
      </Router>
    </div>
  )
}
;

export default App;
