import React from 'react';

const Email = (props) => {

  let formControl = "form-control";

  if (props.touched === "true" && props.valid === "false") {
    formControl = formControl + ' valid-control-error';
  }

  return (
    <div>

      <div className="form-group">
        <input type="email" className={formControl} {...props} />
      </div>
    </div>
  );
};

export default Email;