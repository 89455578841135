import React, {Component} from 'react'
import TopBar from "../../layout/TopBar";

class UserHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchPlaceholder: "Buscar entregador por código o nombre",
      isEditing: this.props.isFormEditing
    };
  }

  handleSearch = event => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault();
      let query = event.target.value;
      if (this.props.isFormEditing) {
        let val = window.confirm("Esta seguro que quiere continuar? Los cambios editados no seran guardados");
        if (val === true) {
          window.location = '/entregadores?query=' + query
        }
      } else {
        window.location = '/entregadores?query=' + query
      }
      event.target.value = ''
    }
  };

  render() {
    return (
      <div onKeyDown={this.handleSearch}>
        <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
      </div>
    )
  }
}

export default UserHeader