import React, { useContext } from 'react'
import SelectInput from "../../components/inputs/SelectInput";
import { UserConfigContext } from "../context/UserConfigProvider";
import { UserInfoContext } from "../context/UserInfoProvider"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserConfigInfoDetailTab = () => {
  const {
    businessData,
    isEditingUserConfigTab,
    isFetching,
    handleUpdateConfigFlags,
    formControl,
    changeHandler,
    formSubmitUpdate,
    handleCancelConfigFlags
  } = useContext(UserConfigContext)

  const newConfigRow = (title, salesman_flag_value, description, business_flag = true, isEditing = false) => {
    return (
      <tr className={business_flag ? "cursor-pointer-list" : "font-color-disable cursor-pointer-list"}>
        <td className="col-md-2">
          {title}
        </td>
        <td className="col-md-1">
          {
            isEditing ? configUserConfigTemplate(business_flag, salesman_flag_value) :
              configUserInfoTemplate(business_flag, salesman_flag_value)
          }
        </td>
        <td className="col-md-4">
          {description}
        </td>
      </tr>
    )
  }

  const configUserInfoTemplate = (business_flag, salesman_flag_value) => {
    return (
      business_flag ?
        (
          <>
            {salesman_flag_value ? "Si" : "No"}
          </>
        ) :
        <>
          *No Disponible
        </>
    )
  }

  const configUserConfigTemplate = (business_flag = true, salesman_flag_value) => {
    return (
      business_flag ?
        (
          <>
            {salesman_flag_value}
          </>
        ) :
        <>
          *No Disponible
        </>
    )
  }
  return (
    <>
      <UserInfoContext.Consumer>
        {({
            userInfoData,
          }) => {
          return (
            <>
              <div>
                {isEditingUserConfigTab === false && !isFetching &&
                <div className='show-area-tab'>
                  <div className="show-header">
                    <div className="ml-12">
                      <h2>Tipo de Usuario: App de Vendedor</h2>
                      <div className="show-buttons">
                        <input type="submit" onClick={(e) => handleUpdateConfigFlags(e, userInfoData)}
                               value='Actualizar configuraciones'/>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-70"/>
                  <h5>Actividades</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                        <tr className="th-list-table">
                          <th>NOMBRE</th>
                          <th>ACTIVO</th>
                          <th>DESCRIPCIÓN</th>
                        </tr>
                        </thead>
                        <tbody>
                        {newConfigRow('Crear pedidos', 'Si', 'El usuario puede crear pedidos a los clientes.')}
                        {newConfigRow('Crear cotizaciones', businessData.can_make_quotes, 'El usuario puede crear cotizaciones a los clientes.', businessData.can_make_quotes)}
                        {newConfigRow('Crear devoluciones', userInfoData.can_access_refunds_app, 'El usuario puede crear devoluciones a los clientes.', businessData.can_access_refunds_app)}
                        {newConfigRow('Crear reportes de visita', businessData.can_report_no_sales, 'El usuario puede escoger una incidencia y enviar un reporte de visita.', businessData.can_report_no_sales)}
                        {newConfigRow('Ubicación del cliente', businessData.can_see_client_location, 'El usuario puede dar clic en ubicacion del cliente y utilizar una aplicacion como Google Maps para llegar a la ubicación del cliente.', businessData.can_see_client_location)}
                        </tbody>
                      </table>
                    </div>
                  </>
                  <hr className="mt-25"/>
                  <h5>Permisos de precios</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                        <tr className="th-list-table">
                          <th>NOMBRE</th>
                          <th>ACTIVO</th>
                          <th>DESCRIPCIÓN</th>
                        </tr>
                        </thead>
                        <tbody>
                        {newConfigRow('Editar precios de pedido', userInfoData.can_change_prices, 'El usuario puede editar los precios de todos los productos al momento de crear un pedido.', businessData.can_change_prices)}
                        {newConfigRow('Editar precios de devolución', businessData.can_change_refund_prices, 'Si el usuario tiene permitido crear devoluciones, entonces el usuario puede editar los precios de los productos al crear una devolución.', businessData.can_change_refund_prices)}
                        {newConfigRow('Editar precios de cotización', userInfoData.can_set_prices_on_quote_items, 'El usuario puede editar los precios de todos los productos al momento de crear una cotización.', businessData.can_set_prices_on_quote_items)}
                        </tbody>
                      </table>
                    </div>
                  </>

                  <hr className="mt-25"/>
                  <h5>Propiedades de usuario</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                        <tr className="th-list-table">
                          <th>NOMBRE</th>
                          <th>ACTIVO</th>
                          <th>DESCRIPCIÓN</th>
                        </tr>
                        </thead>
                        <tbody>
                        {newConfigRow('Ruta fija', userInfoData.has_fixed_route, 'El usuario puede ver unicamente los clientes asignados para la ruta del dia de hoy.', businessData.has_fixed_route)}
                        {newConfigRow('Consolida pedidos', userInfoData.can_consolidate_orders, 'El usuario consolida pedidos al final del día.', businessData.can_consolidate_orders)}
                        {newConfigRow('Tipo de cliente e infaltables', userInfoData.can_see_customer_segment, 'El usuario puede ver información del tipo de cliente y el listado de productos infaltables asignados a un cliente.', businessData.can_see_customer_segment)}
                        {newConfigRow('Impresión Fiscal', userInfoData.enable_fiscal_printer, 'El usuario puede imprimir una factura fiscal en base a un pedido.', businessData.enable_fiscal_printer)}
                        {newConfigRow('Descuentos manuales', userInfoData.can_select_discount, 'El usuario puede manualmente aplicar promociones de descuentos porcentuales.', businessData.can_select_discount)}
                        </tbody>
                      </table>
                    </div>
                  </>
                  <h5 className="mt-33">
                    Las configuraciones que están en estado de activo “Si” pero no se pueden editar, están activadas
                    para todos los usuarios por política de empresa.
                  </h5>
                  <h5 className="font-color-disable">*Aviso: Si desea activar alguna de las funcionalidades
                    que
                    dice “No Disponible” por favor comunicarse con el equipo de Soporte Ubiqua a
                    soporte@ubiqua.me</h5>
                </div>
                }
                {isEditingUserConfigTab === true &&
                <div className='show-area-tab'>
                  <form
                    onSubmit={(e) => formSubmitUpdate(e, userInfoData)}>
                    <div className="inline-block full-width">
                      <div className="float-left">
                        <h4>Tipo de Usuario: App de Vendedor</h4>
                      </div>
                      <div className="float-right">
                        <button className="shadow-cancel-button"
                                value='Cancelar'
                                onClick={handleCancelConfigFlags}> Cancelar
                        </button>
                        <input className="save-button" value="Guardar" type="submit"/>
                      </div>
                    </div>
                    <hr/>
                    <span>
                      <FontAwesomeIcon className="information-icon" icon={faInfoCircle} size="lg"/><h5>Importante: Al dar clic al boton de Guardar, cualquier cambio que hizo va a sacar al usuario de
                      la aplicacion inmediatamente.</h5>
                    </span>
                    <hr className="mt-25"/>
                    <h5>Actividades</h5>
                    <>
                      <div className="">
                        <table className="table">
                          <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                          </thead>
                          <tbody>
                          {newConfigRow('Crear pedidos', 'Si', 'El usuario puede crear pedidos a los clientes.', true, isEditingUserConfigTab)}

                          {newConfigRow('Crear cotizaciones', businessData.can_make_quotes,
                            'El usuario puede crear cotizaciones a los clientes.', businessData.can_make_quotes)}

                          {newConfigRow('Crear devoluciones', <SelectInput name="can_access_refunds_app"
                                                                           value={formControl.can_access_refunds_app.value}
                                                                           onChange={changeHandler}
                                                                           options={formControl.can_access_refunds_app.options}
                                                                           touched={formControl.can_access_refunds_app.touched}
                                                                           formClass="-"
                                                                           valid={formControl.can_access_refunds_app.valid}/>,
                            'El usuario puede crear devoluciones a los clientes.', businessData.can_access_refunds_app, isEditingUserConfigTab)}

                          {newConfigRow('Crear reportes de visita', businessData.can_report_no_sales, 'El usuario puede escoger una incidencia y enviar un reporte de visita.', businessData.can_report_no_sales)}


                          {newConfigRow('Ubicación del cliente', businessData.can_see_client_location,
                            'El usuario puede dar clic en ubicacion del cliente y utilizar una aplicacion como Google Maps para llegar a la ubicación del cliente.',
                            businessData.can_see_client_location)}

                          </tbody>
                        </table>
                      </div>
                    </>
                    <hr className="mt-25"/>
                    <h5>Permisos de precios</h5>
                    <>
                      <div className="">
                        <table className="table">
                          <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                          </thead>
                          <tbody>
                          {newConfigRow('Editar precios de pedido', <SelectInput name="can_change_prices"
                                                                                 value={formControl.can_change_prices.value}
                                                                                 onChange={changeHandler}
                                                                                 options={formControl.can_change_prices.options}
                                                                                 touched={formControl.can_change_prices.touched}
                                                                                 formClass="-"
                                                                                 valid={formControl.can_change_prices.valid}/>,
                            'El usuario puede editar los precios de todos los productos al momento de crear un pedido.', businessData.can_change_prices, isEditingUserConfigTab)}

                          {newConfigRow('Editar precios de devolución', businessData.can_change_refund_prices, 'Si el usuario tiene permitido crear devoluciones, entonces el usuario puede editar los precios de los productos al crear una devolución.', businessData.can_change_refund_prices)}


                          {newConfigRow('Editar precios de cotización', <SelectInput
                              name="can_set_prices_on_quote_items"
                              value={formControl.can_set_prices_on_quote_items.value}
                              onChange={changeHandler}
                              options={formControl.can_set_prices_on_quote_items.options}
                              touched={formControl.can_set_prices_on_quote_items.touched}
                              valid={formControl.can_set_prices_on_quote_items.valid}/>,
                            'El usuario puede editar los precios de todos los productos al momento de crear una cotización.',
                            businessData.can_set_prices_on_quote_items, isEditingUserConfigTab)}
                          </tbody>
                        </table>
                      </div>
                    </>

                    <hr className="mt-25"/>
                    <h5>Propiedades de usuario</h5>
                    <>
                      <div className="">
                        <table className="table">
                          <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                          </thead>
                          <tbody>
                          {newConfigRow('Ruta fija', <SelectInput name="has_fixed_route"
                                                                  value={formControl.has_fixed_route.value}
                                                                  onChange={changeHandler}
                                                                  options={formControl.has_fixed_route.options}
                                                                  touched={formControl.has_fixed_route.touched}
                                                                  formClass="-"
                                                                  valid={formControl.has_fixed_route.valid}/>,
                            'El usuario puede ver unicamente los clientes asignados para la ruta del dia de hoy.', businessData.has_fixed_route, isEditingUserConfigTab)}

                          {newConfigRow('Consolida pedidos', <SelectInput name="can_consolidate_orders"
                                                                          value={formControl.can_consolidate_orders.value}
                                                                          onChange={changeHandler}
                                                                          options={formControl.can_consolidate_orders.options}
                                                                          touched={formControl.can_consolidate_orders.touched}
                                                                          formClass="-"
                                                                          valid={formControl.can_consolidate_orders.valid}/>,
                            'El usuario consolida pedidos al final del día.', businessData.can_consolidate_orders, isEditingUserConfigTab)}

                          {newConfigRow('Tipo de cliente e infaltables', <SelectInput name="can_see_customer_segment"
                                                                                      value={formControl.can_see_customer_segment.value}
                                                                                      onChange={changeHandler}
                                                                                      options={formControl.can_see_customer_segment.options}
                                                                                      touched={formControl.can_see_customer_segment.touched}
                                                                                      formClass="-"
                                                                                      valid={formControl.can_see_customer_segment.valid}/>,
                            'El usuario puede ver información del tipo de cliente y el listado de productos infaltables asignados a un cliente.',
                            businessData.can_see_customer_segment, isEditingUserConfigTab)}
                          {newConfigRow('Impresión Fiscal', <SelectInput name="enable_fiscal_printer"
                                                                                      value={formControl.enable_fiscal_printer.value}
                                                                                      onChange={changeHandler}
                                                                                      options={formControl.enable_fiscal_printer.options}
                                                                                      touched={formControl.enable_fiscal_printer.touched}
                                                                                      formClass="-"
                                                                                      valid={formControl.enable_fiscal_printer.valid}/>,
                            'El usuario puede imprimir una factura fiscal en base a un pedido.',
                            businessData.enable_fiscal_printer, isEditingUserConfigTab)}
                          {newConfigRow('Descuentos manuales', <SelectInput name="can_select_discount"
                                                                         value={formControl.can_select_discount.value}
                                                                         onChange={changeHandler}
                                                                         options={formControl.can_select_discount.options}
                                                                         touched={formControl.can_select_discount.touched}
                                                                         formClass="-"
                                                                         valid={formControl.can_select_discount.valid}/>,
                            'El usuario puede manualmente aplicar promociones de descuentos porcentuales.',
                            businessData.can_select_discount, isEditingUserConfigTab)}
                          </tbody>
                        </table>
                      </div>
                    </>
                    <h5 className="mt-33">
                      Las configuraciones que están en estado de activo “Si” pero no se pueden editar, están activadas
                      para todos los usuarios por política de empresa.
                    </h5>
                    <h5 className="font-color-disable">*Aviso: Si desea activar alguna de las funcionalidades
                      que
                      dice “No Disponible” por favor comunicarse con el equipo de Soporte Ubiqua a
                      soporte@ubiqua.me</h5>
                  </form>
                </div>
                } {isFetching === true &&
              <div className="index-table">
                <div className='show-area-tab'>
                  <p style={{textAlign: 'center'}}>Obteniendo datos ...</p>
                </div>
              </div>

              }
              </div>
            </>
          )
        }}
      </UserInfoContext.Consumer>
    </>
  )
}

export default UserConfigInfoDetailTab
