import React from 'react';
import cookie from 'react-cookies';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      photo_name: '',
      imagePreviewUrl: '',
      dimensions: { height: 0, width: 0},
      isInvalidFile: false
    };
  }

  _handleSubmit(e) {
    e.preventDefault();
    if (this.state.isInvalidFile == false) {
      fetch(BACKEND_URL +  '/product_photos', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: this.props.business_id,
          product_id: this.props.product_id,
          photo_name: this.state.photo_name,
          url: this.state.imagePreviewUrl
        })
      })
      .then(r =>  r.json().then(data => ({status: r.status, body: data})))
      .then(obj => this.evaluateResponse(obj))
      .catch(e => console.log(e));
    }
  }

  evaluateResponse = (obj) => {
    if (obj.status === 201) {
      window.location = '/productos/' + this.props.product_id + "/imagenes"
    } else {
      this.props.setFailedCreation()
    }
  };

  // https://github.com/jasny/bootstrap/issues/470
  // issue existing on chrome, when select file from browser but selects cancel, name is erased
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          photo_name: file['name'],
          imagePreviewUrl: reader.result,
          isInvalidFile: false
        });
      }
      reader.readAsDataURL(file)
    }
  }

  onImgLoad = event => {
    this.setState({dimensions: { height: event.target.offsetHeight, width: event.target.offsetWidth}});
  }

  resetImage = () => {
    this.setState({
      file: '',
      imagePreviewUrl: '',
      dimensions: {width: 0, height: 0},
      isInvalidFile: true
    });
  }

  render() {
    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<img onLoad={this.onImgLoad} src={imagePreviewUrl} />);
      if (this.state.dimensions.width > 601 || this.state.dimensions.height > 601 || (!this.state.file['type'].includes('image'))) {
        this.resetImage()
      }
    } else {
      $imagePreview = (<div className="previewText">Por favor escoja una imagen para ver un preliminar</div>);
    }

    const {width, height} = this.state.dimensions;

    return (
      <div>
        <form onSubmit={this._handleSubmit.bind(this)}>
          <div className="show-header show-product-header">
            <h2>Imagenes del producto:</h2>
            <div className="cancel-save-block">
              <input id="cancel-add" onClick={() => this.props.unsetAddingImage()} className="cancel-edit-button" value='Cancelar' type="submit" />
              <input value="Guardar" className="save-button" type="submit"/>
            </div>
          </div>
          <div className="add-price-list-block">
            <h2>Escoga la imagen para asociarlo a su producto:</h2>
            <div className="gray-color">Notas: Imagen debe ser formato PNG o JPG, Solo se puede escoger 1 imagen.</div>
            <div className="add-price-list-inputs">
              <div className="add-image-block">
                <input required className="fileInput" type="file" accept=".png,.jpg" onChange={(e)=>this._handleImageChange(e)} />
                { this.state.isInvalidFile &&
                  <div className="red imgPreview">Verifique que la imagen sea formato .png .jpg y que las dimensiones sean por debajo de 600x600</div>
                }
                {this.state.isInvalidFile == false &&
                  <div className="imgPreview">
                    {$imagePreview}
                  </div>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
