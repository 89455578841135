import React from 'react';
import OrderDetailsTab from './tabs/OrderDetailsTab';
import DeliveryDetailsTab from './tabs/DeliveryDetailsTab';
import InvoiceDetailsTab from '../order/InvoiceDetailsTab';
import TabWithDropdown from '../layout/TabWithDropdown';
import { DispatchConsumer, DispatchProvider } from "./context/OrderContext";
import DispatchOrderTab from "./tabs/DispatchOrderTab";
import CreateDispatchProvider from "./context/CreateDispatchProvider";

export default class ShowOrderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessfulMessage: false,
      alertState: 'alert alert-success',
      createText: 'Guardar',
      isPosting: false,
    };
  }

  componentDidMount() {
    this.showSuccessfulMessageCreation()
  }

  showSuccessfulMessageCreation = () => {
    if (document.referrer.includes('nuevo_pedido')) {
      this.setState({ showSuccessfulMessage: true })
    } else {
      this.setState({ showSuccessfulMessage: false })
    }
  };

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  };

  disableButton = () => {
    this.setState({ createText: 'Guardando...', isPosting: true })
  }

  enableButton = () => {
    this.setState({ createText: 'Guardar', isPosting: false })
  }

  render() {
    return (
      <DispatchConsumer>
        {({
            transactionDetails, orderDetails, deliveryDetails, client90KDeliveries,
            invoiceDetails, client90KOrders, client90KInvoices, dispatchDetailState,
            handleTabClick, activeTab, isDispatchState, loading, componentState
          }) => (
          <div>
            {!loading &&
            <div className="main-view">
              {this.state.showSuccessfulMessage &&
              <div className="alert alert-success" role="alert">
                <strong>Su pedido fue creado exitosamente!</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              }
              {
                componentState.state === 'create' &&
                <div className={this.state.alertState} role="alert">
                  <button type="button" className="close" onClick={this.hiddenAlert}
                          aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <strong>El despacho de su pedido ha sido creado exitosamente!</strong>
                </div>
              }
              <div className="main-show-header">
                {!isDispatchState && dispatchDetailState &&
                <h2>Pedido #{orderDetails.order_db_ref}</h2>
                }
                {
                  isDispatchState &&
                  <h2>
                    Crear Despacho de Pedido #{orderDetails.order_db_ref}
                  </h2>
                }
                {
                  !dispatchDetailState &&
                  <h2>
                    Actualizar Detalles de Despacho
                  </h2>
                }

              </div>
              <div className="index-table">
                <div className='show-area'>
                  <div>
                    <div className="order-status">
                      <b>ESTADO:
                        {transactionDetails['status'] === 'received' &&
                        <span className="order-status-received"> RECIBIDO</span>
                        }
                        {transactionDetails['status'] === 'dispatched' &&
                        <span className="order-status-dispatched"> DESPACHADO</span>
                        }
                        {transactionDetails['status'] === 'invoiced' &&
                        <span className="order-status-invoiced"> FACTURADO</span>
                        }
                      </b>
                    </div>
                    <div>
                      <div>
                        {
                          (transactionDetails['status'] === 'received' && !isDispatchState) &&
                          <ul onClick={handleTabClick} className="nav nav-tabs">
                            <TabWithDropdown name="Pedido"
                                             activeTab={activeTab}
                                             items={client90KOrders}/>
                          </ul>
                        }
                        {isDispatchState &&
                        <ul onClick={handleTabClick} className="nav nav-tabs">
                          <TabWithDropdown name="Pedido"
                                           activeTab={activeTab}
                                           items={client90KOrders}/>
                          <TabWithDropdown name="Despacho"
                                           activeTab={activeTab}
                                           items={client90KOrders}
                          />
                        </ul>
                        }
                        {transactionDetails['status'] === 'dispatched' &&
                        <ul onClick={handleTabClick} className="nav nav-tabs">
                          <TabWithDropdown name="Pedido" activeTab={activeTab}
                                           items={client90KOrders}/>
                          <TabWithDropdown name="Despacho"
                                           activeTab={activeTab}
                                           items={client90KDeliveries}/>
                        </ul>
                        }

                        {transactionDetails['status'] === 'invoiced' && client90KInvoices.length > 0 &&
                        <ul onClick={handleTabClick} className="nav nav-tabs">
                          <TabWithDropdown name="Pedido"
                                           activeTab={activeTab}
                                           items={client90KOrders}/>
                          <TabWithDropdown name="Despacho"
                                           activeTab={activeTab}
                                           items={client90KDeliveries}/>
                          <TabWithDropdown name="Factura"
                                           activeTab={activeTab}
                                           items={client90KInvoices}/>
                        </ul>

                        }
                      </div>

                      <div>
                        {activeTab === 'Pedido' && orderDetails &&
                        <OrderDetailsTab orderDetails={orderDetails} transactionUuid={this.props.details.uuid}/>
                        }

                        {activeTab === 'Despacho' &&
                        <span>
                          {isDispatchState ?
                            <CreateDispatchProvider
                              transactionUuid={this.props.position} disableButton={this.disableButton}
                              orderDetails={orderDetails}>
                              <DispatchOrderTab isPosting={this.state.isPosting} createText={this.state.createText}/>
                            </CreateDispatchProvider> :
                            <DeliveryDetailsTab
                              position={this.props.position}
                              deliveryDetails={deliveryDetails}
                              orderDetails={orderDetails}
                              transactionUuid={this.props.details.uuid}/>
                          }
                          </span>
                        }

                        {activeTab === 'Factura' && invoiceDetails &&
                        <InvoiceDetailsTab invoiceDetails={invoiceDetails}/>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        )}
      </DispatchConsumer>
    )
  };
}
