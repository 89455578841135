import React, {useState} from 'react';

import ChargeEffectivenessIndexView from "./ChargeEffectivenessIndexView";
import TopBar from "../../layout/TopBar";
import NavBar from "../../layout/NavBar";

function ChargeEffectivenessIndexApp() {

    const [userName] = useState('');
    const [searchPlaceholder] = useState('');
    const [title] = useState("Reporte: Efectividad de Cobros",);

    return (
        <div>
            <div>
                <div>
                    <TopBar searchPlaceholder={searchPlaceholder} userName={userName}/>
                </div>
                <div>
                    <ChargeEffectivenessIndexView title={title}/>
                </div>
            </div>
            <div>
                <NavBar activeItem='Reportes' isEditing={false}/>
            </div>
        </div>
    )
}

export default ChargeEffectivenessIndexApp
