import React, {useContext} from "react";
import {GlobalContext} from "../../../global_context/GlobalContext";

const ProductWeightInput = (props) => {
  const {variants} = useContext(GlobalContext);
  const productWeight = (e) => {
    props.handleProductWeight(e);
  };

  const keyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  const minRequired = variants.isUseWeight ? 1 : 0;

  const classWeightInput = variants.isUseWeight ? "goal-quantity-input" : "goal-quantity-input-disable";

  return (
    <span>
      {variants.isUseWeight ?
        <input required
               onChange={productWeight}
               className={classWeightInput}
               name="weight"
               id={props.id}
               step="0.01"
               min="1"
               type="number"
               value={props.weight}
               placeholder="0.00"
               autoComplete="off"
               onKeyPress={keyPress}/>
        :
        <input required
               onChange={productWeight}
               className={classWeightInput}
               name="weight"
               id={props.id}
               step="0.01"
               disabled={!variants.isUseWeight}
               value={variants.isUseWeight ? '' : 0.00}
               min={minRequired}
               type="number"
               placeholder="0.00"
               autoComplete="off"
               onKeyPress={keyPress}/>
      }
           </span>
  )
};

export default ProductWeightInput
