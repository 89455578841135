import React from 'react';
import NavBar from '../../layout/NavBar';
import TopBar from '../../layout/TopBar';
import VisitEffectivityIndexView from './VisitEffectivityIndexView';

export default class VisitEffectivityIndexApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: 'Ana Sofia Lau Hou',
      searchPlaceholder: "",
      title: "Reporte: Efectividad de visitas",
    };
  }

  render() {
    return (
      <div>
        <div>
          <div>
            <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
          </div>
          <div>
            <VisitEffectivityIndexView title={this.state.title}/>
          </div>
        </div>
        <div>
          <NavBar activeItem='Reportes' isEditing={false}/>
        </div>
      </div>
    )
  }
}
