import React, { Component } from 'react';
import cookie from 'react-cookies';
import history from "../helpers/history";
import * as apiStatusCodes from '../constants/apiStatusCodes';
import * as apiService from '../services/apiService';
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages';

export default class SalesmanCreateNewView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      active: true,
      db_ref: '',
      name: '',
      email: '',
      mobile: '',
      showError: true,
      isApiErrorVisible: false,
      apiErrorText: ''
    };
  }

  handleCreateSalesmanSubmit = event => {
    event.preventDefault();
    this.setState({
      isFetching: true,
      isApiErrorVisible: false,
    });

    const params = {
      db_ref: this.state.db_ref,
      business_id: "1",
      email: this.state.email,
      name: this.state.name,
      mobile: this.state.mobile ? this.state.mobile : null,
      active: this.state.active,
      salesman_type: "ave"
    };

    apiService.createNewSalesman(params)
    .then(response => apiService.handleErrors(response))
    .then(response => response.json())
    .then(data => {
      history.push({
          pathname: `/vendedores/${data.id}/detalles`,
          params: {successCreateForNewSalesman: true}
        }
      );
    })
    .catch(error => {
      console.log(error);
      if (error.status === apiStatusCodes.CONFLICT) {
        error.json()
        .then(error => {
          this.setState({
            isApiErrorVisible: true,
            apiErrorText: error.message,
            isFetching: false
          });
        })
      } else if (error.status === apiStatusCodes.UNAUTHORIZED) {
        cookie.remove('token', { path: '/' });
        window.location = '/'
      }
    });
  };

  handleCancelCreate = event => {
    event.preventDefault();
    if (window.confirm(DISCARD_CHANGES_CONFIRMATION)) window.location.href = '/vendedores';
  };

  handleChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="main-view">
        {this.state.isApiErrorVisible &&
          <div className="alert alert-danger" role="alert">
            <strong>{this.state.apiErrorText}</strong>
          </div>
        }
        <div>
          <div className="show-title">
            <h2>{this.props.title}</h2>
          </div>
        </div>
          <div className="index-table">
            {!this.state.isFetching &&
              <div className="show-area">
                <form
                  onSubmit={this.handleCreateSalesmanSubmit}
                  className="create-new-salesman"
                >
                  <div className="inline-block full-width">
                    <div className="float-left create-new-salesman__form-description">
                      <div>Ingrese los siguientes datos para crear un vendedor nuevo</div>
                    </div>
                    <div className="float-right">
                      <button onClick={this.handleCancelCreate} className="cancel-button">Cancelar</button>
                      <input className="save-button" value="Guardar" type="submit"/>
                    </div>
                  </div>
                  <hr/>
                  {this.state.showError &&
                    <div className="red">Por favor llene los campos obligatorios con * para poder proceder</div>
                  }
                  <div className="data-row">
                    <label>
                      <div>Activo</div>
                      <select
                        value={this.state.active}
                        name="active"
                        onChange={this.handleChange}
                        className="create-new-salesman__input"
                      >
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select>
                    </label>
                  </div>
                  <div className="data-row">
                    <label>
                      <div>Nombre de Vendedor<span className="red">*</span></div>
                      <input
                        onChange={this.handleChange}
                        type="text"
                        name="name"
                        value={this.state.name}
                        required
                        className="create-new-salesman__input"
                      />
                    </label>
                  </div>
                  <div className="data-row">
                    <label>
                      <div>Número de rutero/Código de vendedor<span className="red">*</span></div>
                      <input
                        onChange={this.handleChange}
                        type="text"
                        name="db_ref"
                        value={this.state.db_ref}
                        required
                        className="create-new-salesman__input"
                      />
                    </label>
                  </div>
                  <div className="data-row">
                    <label>
                      <div>Correo electrónico<span className="red">*</span></div>
                      <input
                        onChange={this.handleChange}
                        type="email"
                        name="email"
                        value={this.state.email}
                        required
                        className="create-new-salesman__input"
                      />
                    </label>
                  </div>
                  <div className="data-row">
                    <label>
                      <div>Celular (opcional)</div>
                      <input
                        onChange={this.handleChange}
                        type="number"
                        name="mobile"
                        value={this.state.mobile}
                        className="create-new-salesman__input"
                      />
                    </label>
                  </div>
                </form>
              </div>
            }
            {this.state.isFetching === true &&
              <div className="scroll-section">
                <p>Obteniendo datos</p>
              </div>
            }
          </div>
      </div>
    )
  }
}
