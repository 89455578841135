import React from 'react'
import { UserAuditContext } from "../context/UserAuditProvider";
import { getFormattedDate } from "../services/apiUserService";

const UserAuditTab = () => {

  return (
    <>
      <UserAuditContext.Consumer>
        {({
            userAuditData,
            isFetching
          }) => {
          return (
            <div>
              {isFetching && userAuditData.length === 0 ? (
                <div className="mt-70">
                  <div className="text-center">
                    <br/>
                    <p>Obteniendo datos para usuarios ....</p>
                  </div>
                </div>) : (
                <div className='show-area-tab'>
                  <div className="show-header ml-12">
                    <h2>Detalles de historial de los cambios de configuraciones de usuario:</h2>
                  </div>
                  <div className="mt-25">
                    <table className="table table-hover">
                      <thead>
                      <tr>
                        <th>NOMBRE</th>
                        <th>DESCRIPCIÓN</th>
                        <th>FECHA</th>
                      </tr>
                      </thead>
                      <tbody>
                      {userAuditData.length > 0 ? (
                        userAuditData.map(user =>
                          <tr className="cursor-pointer" key={user.id}>
                            <td id={user['id']}>
                              {user.user_email}
                            </td>
                            <td id={user['id']}>
                              {
                                user.description.split('\n').map(c => {
                                  return ( <p> {c} </p>)
                                })
                              }
                            </td>
                            <td id={user['id']}>
                              {getFormattedDate(user.timestamp)}
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td>No hubo resultados</td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )
        }}
      </UserAuditContext.Consumer>
    </>
  )
}

export default UserAuditTab