import React from 'react';
import cookie from "react-cookies";
import validate from "../../helpers/validation";
import history from "../../helpers/history";

const {Provider, Consumer} = React.createContext();

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

class DeliveriesProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      failedCreationInfo: null,
      id: '',
      headerOrder: {
        title: 'Crear entregador nuevo',
        detail: 'Ingrese los siguientes datos para crear un entregador nuevo',
        optional: 'Campos obligatorios *'
      },
      formControls: {
        name: {
          value: '',
          placeholder: '',
          valid: false,
          touched: false,
          validationRules: {
            minLength: 1,
            isRequired: true
          }
        },
        active: {
          value: "true",
          placeholder: '',
          valid: true,
          touched: false,
          options: [
            {value: true, displayValue: 'Si'},
            {value: false, displayValue: 'No'}
          ]
        },
        code: {
          value: '',
          placeholder: '',
          valid: false,
          touched: false,
          validationRules: {
            minLength: 1,
            isRequired: true
          }
        },
        email: {
          value: '',
          placeholder: '',
          valid: false,
          validationRules: {
            isRequired: true,
            isEmail: true
          },
          touched: false
        },
        phone: {
          value: '',
          placeholder: '',
          valid: true,
          touched: false,
        },
      }
    }
  }

  changeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedControls = {
      ...this.state.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };

    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    this.setState({
      formControls: updatedControls,
      formIsValid: formIsValid
    });
  };

  formSubmitHandler = (event) => {
    event.preventDefault();
    if (this.state.formIsValid) {
      const {formControls} = this.state;
      fetch(BACKEND_URL + '/deliveries_users', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: 1,
          db_ref: formControls.code.value,
          name: formControls.name.value,
          active: formControls.active.value,
          email: formControls.email.value,
          mobile: formControls.phone.value
        })
      })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
        .then(obj => this.evaluateResponse(obj, false))
        .catch(e => console.log(e));
    } else {
      this.setState({
        failedCreationInfo: 'Por favor introduzca los campos obligatorios',
      });
    }
  };

  hideFailedCreationMessage = () => {
    this.setState({failedCreationInfo: null})
  };

  evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        this.setState({
          failedCreationInfo:  'Verifique la informacion ingresada.'
        });
        setTimeout(this.hideFailedCreationMessage, 15000);
        break;
      case 409:
        this.setState({
          failedCreationInfo:  'Error al guardar cambios. El codigo ingresado ya existe. Por favor escoger otro codigo.'
        });
        setTimeout(this.hideFailedCreationMessage, 15000);
        break;
      case 404:
        this.setState({
          failedCreationInfo: 'Servidor fuera de servicio'
        });
        setTimeout(this.hideFailedCreationMessage, 15000);
        break;
      case 500:
        this.setState({
          failedCreationInfo: 'Error interno en el sistema'
        });
        setTimeout(this.hideFailedCreationMessage, 15000);
        break;
      default:
        history.push({
            pathname: '/entregadores/' + obj.body['id'],
            params: {successCreateForNewDeliverer: true}
          }
        );
        break;
    }
  };

  render() {
    return (
      <Provider
        value={{
          failedCreation: this.state.failedCreationInfo,
          headerOrder: this.state.headerOrder,
          formControl: this.state.formControls,
          formSubmitHandler: this.formSubmitHandler,
          changeHandler: this.changeHandler,
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export {DeliveriesProvider, Consumer as DeliveriesConsumer};
