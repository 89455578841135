import React from 'react';
import ReactDOM from 'react-dom';
import NavBar from '../../layout/NavBar';
import TopBar from '../../layout/TopBar';
import ShowInvoiceView from '../../report/invoices/ShowInvoiceView';
import cookie from 'react-cookies';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class ShowInvoiceApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: 'Soporte',
      searchPlaceholder: "",
    };
  }

  render() {
    return (
      <div>
        <div>
          <div>
            <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
          </div>
          <div>
            <ShowInvoiceView />
          </div>
        </div>
        <div>
          <NavBar activeItem='Reportes' isEditing={false}/>
        </div>
      </div>
    )
  }
}
