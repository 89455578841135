import React, {Component} from 'react'
import NavBar from "../layout/NavBar";
import UserHeader from "./component/UserHeader";
import CreateDeliveryUserForm from "./component/CreateDeliveryUserForm";
import {DeliveriesProvider} from "./context/DeliveriesCreateContext"

class CreateDeliveryUser extends Component {

  render() {
    return (
      <DeliveriesProvider>
        <div>
          <div>
            <UserHeader isFormEditing={true}/>
            <div>
              <CreateDeliveryUserForm/>
            </div>
          </div>
          <div>
            <NavBar activeItem='Equipo de campo' isEditing={true}/>
          </div>
        </div>
      </DeliveriesProvider>
    )
  }
}

export default CreateDeliveryUser;