const validateAccessRole = (role) => {
  let access_type
  switch (role) {
    case 'READ_WRITE':
      access_type = {
        can_read: true,
        can_write: true
      }
      break;

    case 'READ':
      access_type = {
        can_read: true,
        can_write: false
      }
      break;

    case 'OFF':
      access_type = {
        can_read: false,
        can_write: false
      }
      break;
    default:
      access_type = {
        can_read: false,
        can_write: false
      }
      break;
  }
  return access_type;
}
export default validateAccessRole;
