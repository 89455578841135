import React from 'react';
import NavBar from '../../layout/NavBar';
import TopBar from '../../layout/TopBar';
import ShowDeliverInvoicesView from './ShowDeliverInvoicesView';

export default class ShowDeliverInvoicesApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: 'Soporte',
      searchPlaceholder: "",
    };
  }

  render() {
    return (
      <div>
        <div>
          <div>
            <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
          </div>
          <div>
            <ShowDeliverInvoicesView data={this.state.data}/>
          </div>
        </div>
        <div>
          <NavBar activeItem='Reportes' isEditing={false}/>
        </div>
      </div>
    )
  }
}
