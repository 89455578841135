import React from 'react';
import cookie from 'react-cookies';
import NavBar from '../layout/NavBar';
import TopBar from '../layout/TopBar';
import SalesmanIndexView from './SalesmanIndexView';

import * as apiService from '../services/apiService';
import * as apiStatusCodes from '../constants/apiStatusCodes';
import * as keyboardCodes from '../constants/keyboardCodes';

export default class SalesmanIndexApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: 'Ana Sofia Lau Hou',
      per_page: 25,
      current_page: null,
      total_pages: null,
      isFetching: true,
      data: null,
      searchPlaceholder: "Buscar vendedores por código o nombre",
      title: "Vendedores",
      query: null
    };
  }

  componentDidMount() {
    this.setInitialState()
    this.fetchDataFromServer();
  }

  setInitialState = () => {
    if (window.location.href.indexOf('query') >= 0) {
      let query = decodeURI(window.location.href.split('query=')[window.location.href.split('query=').length -1])
      this.setState({query: query})
    } else {
      this.setState({query: ''})
    }

    if (window.location.href.indexOf('pagina') >= 0) {
      this.setState({current_page: window.location.href.split('pagina=')[window.location.href.split('pagina=').length -1].split('&')[0]}, this.fetchDataFromServer)
    } else {
      this.setState({current_page: 1}, this.fetchDataFromServer)
    }
  }

  fetchDataFromServer = () => {
    this.setState({ isFetching: true });
    if (this.state.current_page != null && this.state.query != null){
      apiService.getAllSalesmen(this.state.query, this.state.current_page, this.state.per_page)
      .then(response => apiService.handleErrors(response))
      .then(response => response.json())
      .then(data => {
        this.setState({
          data: data.table,
          isFetching: false,
          total_pages: Math.ceil(data.count / this.state.per_page),
          title: this.state.query ? `Resultados de la búsqueda: ${this.state.query}` : `Vendedores`
        })
      })
      .catch(error => {
        console.log(error);
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' });
          window.location = '/'
        }
      });
    }
  };

  handlePaginationClick = event => {
    // <a> = when pagination elements were clicked
    // console.log(event.target)
    if (event.target.tagName === 'A') {
      this.handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      this.handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      this.handlePagination(event.target.parentNode.parentNode)
    }
  };

  handlePagination = target => {
    if (target.id === 'first-page') {
      if (parseInt(this.state.current_page) !== 1) {
        window.location = '/vendedores?pagina=1&query=' + this.state.query
      }
    } else if (target.id === 'last-page') {
      if (parseInt(this.state.current_page) !== this.state.total_pages) {
        window.location = '/vendedores?pagina=' + this.state.total_pages + '&query=' + this.state.query
      }
    } else if (target.id === 'previous-page') {
      if (parseInt(this.state.current_page) !== 1) {
        window.location = '/vendedores?pagina=' + (parseInt(this.state.current_page) - 1) + '&query=' + this.state.query
      }
    } else if (target.id === 'next-page') {
      if (parseInt(this.state.current_page) !== this.state.total_pages) {
        window.location = '/vendedores?pagina=' + (parseInt(this.state.current_page) + 1) + '&query=' + this.state.query
      }
    }
  };

  handleSearch = event => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === keyboardCodes.ENTER_KEY) {
      // to disbale submit form
      event.preventDefault();
      let query = event.target.value;
      if (query !== '') {
        window.location = '/vendedores?query=' + query
      }
    }
  };

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
          </div>
          <div onClick={this.handlePaginationClick}>
            <SalesmanIndexView
              data={this.state.data}
              isFetching={this.state.isFetching}
              title={this.state.title}
              total_pages={this.state.total_pages}
              current_page={this.state.current_page}
            />
          </div>
        </div>
        <div>
          <NavBar activeItem='Equipo de campo' isEditing={false}/>
        </div>
      </div>
    )
  }
}
