import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import AsyncSelect from 'react-select/lib/Async';
import cookie from "react-cookies";
import {CreateDispatchContext} from "../context/CreateDispatchContext";
import uuid from "uuid/v4";
import {TYPES} from "../state/Types";
import IconOption from "./IconOption";
import _ from 'lodash'
import moment from "moment";
import {GlobalContext} from "../../global_context/GlobalContext";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ConvertOrderForm = () => {

  const {client, priceList, formControls, failedCreationInfo, changeFormHandler, state, dispatch} = useContext(CreateDispatchContext);
  const {variants} = useContext(GlobalContext);

  const [business_id] = useState(1);
  const [total_weight, setTotalWeight] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const {products} = state;

  useEffect(() => {
    calculateOrderSubtotal();
    return () => {
      calculateOrderSubtotal()
    }
  }, [products]);

  const calculateOrderSubtotal = () => {
    let order_subtotal = 0;
    let total_weight = 0;

    products.map((obj) => {
      return order_subtotal += obj.productSubTotal
    });

    products.map((obj) => {
      const weight = obj.weight ? obj.weight : 0.00;
      return total_weight += parseFloat(weight)
    });
    setSubTotal(order_subtotal);
    setTotal(order_subtotal);
    setTotalWeight(total_weight);
  };


  const searchForDeliverer = (inputValue) => {
    if (inputValue.length >= 1) {
      const uri = BACKEND_URL + '/businesses/' + business_id + '/deliveries_users?q=' + inputValue;
      return fetch(uri, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
        .then(response => response.json())
        .then(data => {
          return data['table'].map((i) => {
              return {
                value: i.name,
                target: {
                  name: "deliverer",
                  value: i.id,
                  id: i.id
                },
                label: i.db_ref.concat(' ' + i.name),
                delivererObject: i
              };
            }
          );
        }).catch(e => console.log(e));
    }
  };

  const searchForProduct = (inputValue) => {
    let query = inputValue;
    if (query.length > 1) {
      return fetch(BACKEND_URL + '/price_lists/' + priceList.id + '/product_pricelist?q=' + query, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
        .then(response => response.json())
        .then(data => {
          // index products by "product_db_ref"
          const lookup = _.keyBy(products, (pr) => {
            return pr.product_db_ref
          });
          /* find all products where "db_ref" exists in index, one loop, quick lookup. no nested loops
            return !lookup[u.db_ref]
           */
          const result = _.filter(data['table'], (data) => {
            return !lookup[data.db_ref]
          });
          return result.map((i) => {
              return {
                value: i.name,
                label: i.db_ref.concat(' ' + i.name),
                productObject: i
              };
            }
          );
        }).catch(e => console.log(e));
    }
  };

  const handleProductQuantity = (event, task) => {
    const quantityValue = event.target.value;
    dispatch({type: TYPES.CHANGE_QUANTITY, task, quantityValue});
  };

  const handleProductWeight = (event, task) => {
    const weightValue = event.target.value;
    dispatch({type: TYPES.CHANGE_WEIGHT, task, weightValue});
  };

  const addTask = (product) => {
    dispatch({
      type: TYPES.ADD_TASK, task: {
        id: uuid(),
        price_unit: product.price_unit.description,
        product_db_ref: product.db_ref,
        product_name: product.name,
        weight: 0.00,
        quantity: 0.00,
        tax_rate: product.tax.rate,
        useWeight: (['KILO', 'LIBRA'].includes(product.price_unit.description)),
        unit_price: product.price,
        extended_price: 0.00,
        calculated_tax_price: 0.00,
        productSubTotal: 0.00,
        validQuantity: false,
        validWeight: false,
        price_unit_id: product.price_unit.id,
        product_id: product.id,
      }
    });
  };

  const deleteTask = task => () => {
    dispatch({type: TYPES.DELETE_TASK, task});
  };

  const changeProductHandler = event => {
    if (event !== null) {
      addTask(event.productObject);
    }
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(searchForDeliverer(inputValue));
      }, 1000);
    });

  const promiseProductOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(searchForProduct(inputValue));
      }, 1000);
    });

  const getMonth = () => {
    const m = new Date().getMonth() + 1;
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  };

  const getDay = () => {
    const d = new Date().getDate();
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  };

  return (
    <div>
      {failedCreationInfo.length !== 0 &&
      <div className="container-fluid">
        <div className="form-group row">
          <div className="col-sm-12">
            <div className="alert alert-danger" role="alert">
              <p>{failedCreationInfo}</p>
            </div>
          </div>
        </div>
      </div>
      }
      <div className="container-fluid">
        <div className="form-group row">
          <label className="pl-form col-sm-4 col-form-label">Nombre y Código del Cliente:</label>
          <div className="col-sm-7">
            <div>{`${client.db_ref} ${client.customer_name_db_ref}`}</div>
          </div>
        </div>

        <div className="form-group row mt-33">
          <label className="pl-form col-sm-4 col-form-label">Lista de Precio:</label>
          <div className="col-sm-7">
            {`${priceList.db_ref} - ${priceList.description}`}
          </div>
        </div>

        <div className="form-group row mt-33">
          <label className="pl-form col-sm-4 col-form-label">Entregador/Repartidor/Conductor<span
            className="red"> *</span>:</label>
          <div className="col-sm-7">
            <AsyncSelect
              placeholder={'Buscar entregador por nombre o código'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando entregadores...'}
              cacheOptions
              defaultOptions
              name="selectOption"
              onChange={changeFormHandler}
              loadOptions={promiseOptions}/>
          </div>
        </div>

        <div className="form-group row mt-33">
          <label className="pl-form col-sm-4 col-form-label">Fecha de Entrega<span
            className="red"> *</span>:</label>
          <div className="col-sm-7">
            <input min={(new Date().getFullYear()) + "-" + getMonth() + "-" + getDay()}
                   type="date"
                   value={moment(formControls.date.value).format('YYYY-MM-DD')}
                   onChange={changeFormHandler}
                   className="form-control"
                   name="date" required/>
          </div>
        </div>

        <div className="form-group row mt-33">
          <label className="pl-form col-sm-4 col-form-label">Productos por Entregar<span
            className="red"> *</span>:</label>
          <div className="col-sm-7">
            <AsyncSelect
              placeholder={'Buscar producto por nombre o código'}
              noOptionsMessage={() => 'Escriba para poder buscar y agregar un producto'}
              loadingMessage={() => 'Cargando productos...'}
              defaultOptions={false}
              value={''}
              name="selectOption"
              onChange={changeProductHandler}
              components={{Option: IconOption}}
              loadOptions={promiseProductOptions}/>
          </div>
        </div>

        <div className="mt-33">
          <table className="table table-hover">
            <thead>
            <tr>
              <th>CODIGO</th>
              <th>NOMBRE DEL PRODUCTO</th>
              <th>CANTIDAD</th>
              <th>PESO NETO</th>
              <th>UNIDAD DE VENTA</th>
              <th>PRECIO</th>
              <th>SUBTOTAL</th>
              <th>ELIMINAR</th>
            </tr>
            </thead>
            <tbody>
            {products.map((obj, index) =>
              <tr key={obj.product_db_ref}>

                <td>{obj.product_db_ref}</td>
                <td>{obj.product_name}</td>

                <td>
                  <div>
                    <div>
                      <input name="quantity"
                             type="number"
                             className={obj.validQuantity ? 'form-control' : 'form-control valid-control-error'}
                             placeholder={0}
                             step="1"
                             min="1"
                             value={obj.quantity}
                             id={index}
                             onChange={(e) => handleProductQuantity(e, obj)}
                      />
                    </div>
                  </div>
                </td>

                <td>
                  {
                    variants.isUseWeight ?
                      <input name="weight"
                             type="number"
                             className={obj.validWeight ? 'form-control' : 'form-control valid-control-error'}
                             placeholder={0}
                             step="0.01"
                             min="1"
                             value={obj.weight}
                             id={index}
                             onChange={(e) => handleProductWeight(e, obj)}
                      /> : <input name="weight"
                                  type="number"
                                  disabled={true}
                                  className='goal-quantity-input-disable'
                                  placeholder={0}
                                  step="0.01"
                                  min="0"
                                  value="0"
                                  id={index}
                      />
                  }

                </td>

                <td>{obj.price_unit}</td>

                <td>${obj.unit_price}</td>
                <td>${parseFloat(obj.productSubTotal).toFixed(2)}</td>
                <td>
                  <button className="unassign-price-list-button" id={index} onClick={deleteTask(obj)}>
                    <FontAwesomeIcon icon={faTrash}/>
                  </button>
                </td>
              </tr>
            )
            }
            <tr>
              <td colSpan="5"/>
              <td><b>PESO TOTAL</b></td>
              <td>{(total_weight).toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="5"/>
              <td><b>SUB-TOTAL</b></td>
              <td>${subTotal.toFixed(2)}</td>
              <td/>
            </tr>
            <tr>
              <td colSpan="5"/>
              <td className="fs-20"><b>TOTAL</b></td>
              <td className="fs-20"><b>${total.toFixed(2)}</b></td>
              <td/>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

export default ConvertOrderForm
