import React from 'react';
import cookie from 'react-cookies';
import ReactDOM from 'react-dom';
import CategoryGoalsSection from './CategoryGoalsSection';
import GlobalGoalSection from './GlobalGoalSection';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class SalesmanGoalsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      cycles: [],
      total: 0,
      page: 1,
      per_page: 1000,
      business_id: 1,
      selected_cycle_id: window.location.href.split('/vendedores/')[1].split('/')[2],
      salesman_id: this.props.id
    }
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  // in order to prevent rerender from parent.isEditing (changeEditMode)
  // once i try to edit child components parent rerenders because of shared props
  shouldComponentUpdate(nextProps) {
    return !nextProps.isEditing;
  }

  fetchDataFromServer = () => {
    this.setState({isFetching: true});
    fetch(BACKEND_URL + '/businesses/' + this.state.business_id + '/cycles', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookie.load('token')
        }
      })
      .then(response =>
        {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        }
      )
      .then(data => this.setState({ cycles: data['table'], isFetching: false,
                                    total: data['count']}, this.setDefaultCycle))
      .catch(e => console.log(e));
  };

  setDefaultCycle = () => {
    if (!this.state.selected_cycle_id) {
      let active_cycle = this.state.cycles.filter((obj) => obj['status'] === 'active')[0]
      if (active_cycle) {
        this.setState({selected_cycle_id: active_cycle['uuid']})
      }
    }
  }

  getFormatDatesFromSelectedCycle = () => {
    let date = ''
    if (this.state.selected_cycle_id) {
      let selected_cycle = this.state.cycles.filter((obj) => obj['uuid'] === this.state.selected_cycle_id)[0]
      if (selected_cycle) {
        date = this.formatDates(selected_cycle['start_time'], selected_cycle['end_time'])
      }
    }
    return date
  }

  handleChange = (event) => {
    event.preventDefault()
    let val = true
    if (this.props.isEditing) {
      val = window.confirm("Esta seguro que quiere continuar? Los cambios editados no seran guardados")
    }
    if (val) {
      const obj = {};
      if (event.target.name === 'selected_cycle_id') {
        this.setState({selected_cycle_id: event.target.value})
      } else {
        obj[event.target.name] = event.target.value;
        this.setState(obj);
      }
      this.props.changeEditMode(false)
    } else {
      // revert
    }
  }

  formatDates = (d1, d2) => {
    return d1.split('T')[0] + " - " + d2.split('T')[0]
  }

  render() {
    return(
      <div>
        <div className="show-header salesman">
          <h2>Ciclos de venta:</h2>
          { !this.state.selected_cycle_id && this.state.isFetching == false &&
            <div>
              <select name="selected_cycle_id" value="dd/mm/aaaa - dd/mm/aaaa" className="select-cycles" onChange={this.handleChange.bind(this)}>
                <option>dd/mm/aaaa - dd/mm/aaaa</option>
                { this.state.cycles.length > 0 && this.state.cycles.map((model, index) =>
                  <option key={index} value={model['uuid']}>{this.formatDates(model['start_time'], model['end_time'])}</option>)
                }
              </select>
              <span className="pl-20 red">No existe ciclo de venta para la fecha actual.</span>
            </div>
          }
          { this.state.selected_cycle_id &&
            <div>
              <select name="selected_cycle_id" className="select-cycles" onChange={this.handleChange.bind(this)}>
                <option hidden>{this.getFormatDatesFromSelectedCycle()}</option>
                { this.state.cycles.length > 0 && this.state.cycles.map((model, index) =>
                  <option key={index} value={model['uuid']}>{this.formatDates(model['start_time'], model['end_time'])}</option>)
                }
              </select>
            </div>
          }
        </div>
        <hr/>
        { this.state.isFetching == false &&
          <div>
            <GlobalGoalSection changeEditMode={(val) => this.props.changeEditMode(val)}
              salesman_id={this.state.salesman_id} selected_cycle_id={this.state.selected_cycle_id}
              isEditing={this.props.isEditing}
              />
            <CategoryGoalsSection changeEditMode={(val) => this.props.changeEditMode(val)}
              salesman_id={this.state.salesman_id} selected_cycle_id={this.state.selected_cycle_id}
              isEditing={this.props.isEditing}
              />
          </div>
        }
        { this.state.isFetching  &&
          <div>
            Obteniendo datos ...
          </div>
        }
      </div>
    )
  };
}
