import React from 'react';
import ReactDOM from 'react-dom';
import Pagination from '../layout/Pagination';

export default class PromoIndexView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertState: 'alert alert-success'
    }
  }

  showModel = event => {
    event.preventDefault();
    let id = event.target.id;
    window.location = '/promociones/' + id
  };

  redirectToCreatePromoPage = () => {
    window.location = '/nueva_promo'
  };

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  };

  render() {
    let data = this.props.data;
    if (this.props.isFetching) {
      return (
        <div className="main-view">
          <div className="index-header">
            <br/>
            <p>Obteniendo datos ....</p>
          </div>
        </div>)
    } else {
      return (
        <div className="main-view">
          {
            this.props.successCreateForNewPromo &&
            <div className={this.state.alertState} role="alert">
              <button type="button" className="close" onClick={this.hiddenAlert}
                      aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <strong>La promoción nueva fue creada exitosamente.</strong>
            </div>
          }
          <div className="index-header">
            <h2>{this.props.title}</h2>
            <div className="index-buttons">
              <button onClick={this.redirectToCreatePromoPage}>Crear promoción nueva</button>
            </div>
          </div>
          <div className="index-table">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>ESTADO</th>
                  <th>CLIENTE / LISTA DE PRECIO</th>
                  <th>PRODUCTO</th>
                  <th>PROMOCION</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((model, index) =>
                    <tr className="cursor-pointer" key={index}>
                      { model['status'] === 'active' &&
                        <td id={model['id']} onClick={this.showModel}>Activa</td>
                      }
                      { model['status'] === 'expired' &&
                        <td id={model['id']} onClick={this.showModel}>Caducada</td>
                      }
                      { model['status'] === 'scheduled' &&
                        <td id={model['id']} onClick={this.showModel}>No Iniciada</td>
                      }
                      { model['status'] === 'suspended' &&
                        <td id={model['id']} onClick={this.showModel}>Suspendida</td>
                      }
                      <td id={model['id']} onClick={this.showModel}>{model['applies_to'][0]['db_ref'] + ' - ' + model['applies_to'][0]['name']}</td>
                      <td id={model['id']} onClick={this.showModel}>{model['products'][0] ? model['products'][0]['db_ref'] : ''}</td>
                      { model['promo_type'] === 'bonus_product' &&
                        <td id={model['id']} onClick={this.showModel}>Bonificación de una referencia</td>
                      }
                      { model['promo_type'] === 'price_reduction' &&
                        <td id={model['id']} onClick={this.showModel}>Precio rebajado</td>
                      }
                      { model['promo_type'] === 'scale_prices_reduction' &&
                        <td id={model['id']} onClick={this.showModel}>Precio rebajado por escalas</td>
                      }
                    </tr>
                  )
                }
                {data.length === 0 &&
                  <tr>
                    <td>No hubo resultados</td>
                  </tr>
                }
              </tbody>
            </table>
              <nav>
                { this.props.total_pages >  1 &&
                  <Pagination current_page={this.props.current_page} total_pages={this.props.total_pages} />
                }
              </nav>
          </div>
        </div>
      );
    }
  };
}
