import React from 'react';
import cookie from 'react-cookies';
import NavBar from '../layout/NavBar';
import TopBar from '../layout/TopBar';
import LiquidacionIndexView from './LiquidacionIndexView';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default class LiquidacionIndexApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: 'Ana Sofia Lau Hou',
      per_page: 25,
      current_page: 1,
      total_pages: 1,
      isFetching: true,
      data: null,
      searchPlaceholder: "Buscar liquidación por código o nombre de entregador",
      title: "Liquidaciones",
      query: ''
    };
  }

  componentDidMount() {
    this.setQueryState()
    this.setPaginationParamsState()
  }

  setQueryState = () => {
    if (window.location.href.indexOf('query') >= 0) {
      let query = decodeURI(window.location.href.split('query=')[window.location.href.split('query=').length -1])
      this.setState({query: query}, this.fetchDataFromServer)
    } else {
      this.setState({query: ''}, this.fetchDataFromServer)
    }
  }

  setPaginationParamsState = () => {
    if (window.location.href.indexOf('pagina') >= 0) {
      this.setState({current_page: window.location.href.split('pagina=')[window.location.href.split('pagina=').length -1].split('&')[0]}, this.fetchDataFromServer)
    } else {
      this.setState({current_page: 1}, this.fetchDataFromServer)
    }
  }

  fetchDataFromServer = () => {
    this.setState({ isFetching: true });
    let per_page = this.state.per_page;
    let page = this.state.current_page;
    if (this.state.query) {
      fetch(BACKEND_URL +  "/businesses/1/closure_summaries" +'?q='+this.state.query+'&page='+page+'&per_page='+per_page, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookie.load('token')
          }
        })
        .then(response =>
          {
            if (response.status === 401) {
              window.location = '/'
            }
            return response.json()
          }
        )
        .then(data => this.setState({ data: data['table'], isFetching: false,
                    total_pages: Math.ceil(data['count'] / this.state.per_page),
                    title: 'Resultados de la búsqueda: ' + this.state.query }))
        .catch(e => console.log(e));
    } else {
      fetch(BACKEND_URL +  "/businesses/1/closure_summaries" +'?page='+page+'&per_page='+per_page, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookie.load('token')
          }
        })
        .then(response =>
          {
            if (response.status === 401) {
              window.location = '/'
            }
            return response.json()
          }
        )
        .then(data => this.setState({ data: data['table'], isFetching: false,
              title: 'Liquidaciones',
              total_pages: Math.ceil(data['count'] / this.state.per_page) }))
        .catch(e => console.log(e));
    }
  };

  handlePaginationClick = event => {
    // <a> = when pagination elements were clicked
    console.log(event.target)
    if (event.target.tagName === 'A') {
      this.handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      this.handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      this.handlePagination(event.target.parentNode.parentNode)
    }
  };

  handlePagination = target => {
    if (target.id === 'first-page') {
      if (parseInt(this.state.current_page) !== 1) {
        window.location = '/liquidaciones?pagina=1'+ '&query=' + this.state.query
      }
    } else if (target.id === 'last-page') {
      if (parseInt(this.state.current_page) !== this.state.total_pages) {
        window.location = '/liquidaciones?pagina=' + this.state.total_pages + '&query=' + this.state.query
      }
    } else if (target.id === 'previous-page') {
      if (parseInt(this.state.current_page) !== 1) {
        window.location = '/liquidaciones?pagina=' + (parseInt(this.state.current_page) - 1) + '&query=' + this.state.query
      }
    } else if (target.id === 'next-page') {
      if (parseInt(this.state.current_page) !== this.state.total_pages) {
        window.location = '/liquidaciones?pagina=' + (parseInt(this.state.current_page) + 1) + '&query=' + this.state.query
      }
    }
  };

  handleSearch = event => {
    // search action occurs after ENTER is pressed
    if(event.keyCode === 13) {
      // to disbale submit form
      event.preventDefault();
      let query = event.target.value;
      if (query !== '') {
        window.location = '/liquidaciones?query=' + query
      }
    }
  };

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar searchPlaceholder={this.state.searchPlaceholder} userName={this.state.userName}/>
          </div>
          <div onClick={this.handlePaginationClick}>
            <LiquidacionIndexView data={this.state.data} isFetching={this.state.isFetching}
                  title={this.state.title} total_pages={this.state.total_pages}
                  current_page={this.state.current_page}/>
          </div>
        </div>
        <div>
          <NavBar activeItem='Liquidaciones' isEditing={false}/>
        </div>
      </div>
    )
  }
}
