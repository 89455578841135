import React from 'react';
import ReactDOM from 'react-dom';
import Pagination from '../layout/Pagination';
import CreateOrderInput from "./component/protected_component/CreateOrderInput";

export default class OrderIndexView extends React.Component {

  showModel = event => {
    event.preventDefault();
    let id = event.target.id
    window.location = '/pedidos/' + id
  }

  getFormattedDate = (createdDate) => {
    let d = new Date(createdDate);
    return d.toLocaleString()
  }

  redirectToCreateOrderPage = () => {
    window.location = '/nuevo_pedido'
  };

  render() {
    let data = this.props.data;
    if (this.props.isFetching) {
      return (
        <div className="main-view">
          <div className="index-header">
            <br/>
            <p>Obteniendo datos ....</p>
          </div>
        </div>)
    } else {
      return (
        <div className="main-view">
          <div className="index-header">
            <h2 className="mb-20">{this.props.title}</h2>
            <CreateOrderInput
              redirectToCreateOrderPage={this.redirectToCreateOrderPage}
            />
          </div>
          <div className="index-table">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>PEDIDO</th>
                  <th>NOMBRE DE CLIENTE</th>
                  <th>CODIGO DE CLIENTE</th>
                  <th>VENDEDOR</th>
                  <th>FECHA DE CREACION</th>
                  <th>ESTADO DEL PEDIDO</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((model, index) =>
                    <tr className="cursor-pointer" key={index}>
                      <td id={model['uuid']} onClick={this.showModel}>{model['order_db_ref']}</td>
                      <td id={model['uuid']} onClick={this.showModel}>{model['client_name']}</td>
                      <td id={model['uuid']} onClick={this.showModel}>{model['client_db_ref']}</td>
                      <td id={model['uuid']} onClick={this.showModel}>{model['salesman_name']}</td>
                      <td id={model['uuid']} onClick={this.showModel}>{this.getFormattedDate(model['created_date'])}</td>
                      { model['status'] == 'received' &&
                        <td id={model['uuid']} onClick={this.showModel}>RECIBIDO</td>
                      }
                      { model['status'] == 'dispatched' &&
                        <td id={model['uuid']} onClick={this.showModel}>DESPACHADO</td>
                      }
                      { model['status'] == 'invoiced' &&
                        <td id={model['uuid']} onClick={this.showModel}>FACTURADO</td>
                      }
                    </tr>
                  )
                }
                {data.length == 0 &&
                  <tr>
                    <td>No hubo resultados</td>
                  </tr>
                }
              </tbody>
            </table>
              <nav>
                { this.props.total_pages >  1 &&
                  <Pagination current_page={this.props.current_page} total_pages={this.props.total_pages} />
                }
              </nav>
          </div>
        </div>
      );
    }
  };
}
