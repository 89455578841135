export const UserInfoInitialStateForm = {
  name: {
    value: '',
    placeholder: '',
    valid: true,
    touched: false,
    validationRules: {
      minLength: 3,
      isRequired: true
    }
  },
  active: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  code: {
    value: '',
    placeholder: '',
    valid: true,
    touched: false,
    validationRules: {
      minLength: 2,
      isRequired: true
    }
  },
  email: {
    value: '',
    placeholder: '',
    valid: true,
    validationRules: {
      isRequired: true,
      isEmail: true
    },
    touched: false
  },
  mobile: {
    value: '',
    placeholder: '',
    valid: true,
    touched: false,
  },
}

export const UserConfigInitialStateForm = {
  can_access_refunds_app: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  can_report_no_sales: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  can_see_client_location: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  can_change_prices: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  can_change_refund_prices: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  can_set_prices_on_quote_items: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  has_fixed_route: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  can_consolidate_orders: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  can_see_customer_segment: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  enable_fiscal_printer: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  can_select_discount: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false,
    options: [
      {value: true, displayValue: 'Si'},
      {value: false, displayValue: 'No'}
    ]
  },
  route: {
    value: "",
    placeholder: '',
    valid: true,
    touched: false,
  },
  name: {
    value: '',
    placeholder: '',
    valid: true,
    touched: false
  },
  active: {
    value: "true",
    placeholder: '',
    valid: true,
    touched: false
  },
  code: {
    value: '',
    placeholder: '',
    valid: true,
    touched: false

  },
  email: {
    value: '',
    placeholder: '',
    valid: true,
    touched: false
  },
  mobile: {
    value: '',
    placeholder: '',
    valid: true,
    touched: false
  }
}
